import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';

import GeneralService from "../../../../services/GeneralService";
import { setGenerateDocument, setListActorsSelected, setListAnswers, setListQuestions, setSelectedActors, setSelectedDocument, setSelectedPerson, updateGenerateAnswer, updateSelectedActors } from "../../../../reducers/documents/reducerGenerateDocument";
import { ALERT_INFO, BAD_REQUEST, HTTP_OK, ID_NOT_DEFINED } from "../../../../constant/constant";
import { MessageError } from "../../../../utils/message";
import { toast } from "../../../../utils/toast";
import "../parametrize/style/parametrize-document.css";

export const useGenerateDocument = () => {
    const { dataDocument, refreshActors, refreshQuestions, selectedPeople, generateDocument, listActorsSelected, listAnswers } = useSelector(state => state.documentsAutoSlice);
    const { list: listTypePeople } = useSelector(state => state.typepeopleSlice);
    const [currentStep, setCurrentStep] = useState(1);
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { search } = useLocation();
    const { expedient = 0, movement = 0, process = 0 } = queryString.parse(search);
    const expedientId = parseInt(expedient);
    const movementId = parseInt(movement);

    const loadDocument = async () => {
        const service = new GeneralService("documents-auto/" + id);
        const document = await service.getRequest();
        if (service.status === BAD_REQUEST) {
            MessageError(document.message);
            setTimeout(() => history.goBack(), 1200);
            return;
        }

        dispatch(setSelectedDocument({ value: document }));
    }

    const loadActors = async () => {
        const service = new GeneralService("actors-documents");
        const res = await service.getList(1000, { documents_auto_id: dataDocument.id });
        const { actors_documents = [] } = res;
        dispatch(setListActorsSelected({ value: actors_documents }));
        await loadParts(actors_documents);
    }

    const loadParts = async (actorsDocuments) => {
        if (movementId !== ID_NOT_DEFINED && expedientId !== ID_NOT_DEFINED) {
            const service = new GeneralService("part");
            const res = await service.getList(1000, { expedients_id: expedientId });
            const { parts = [] } = res;

            toast(dispatch, "Revisa que todas las etiquetas estén correctas", ALERT_INFO, 10000);

            const data = parts.map(part => {
                const person = part.people;
                const actorFound = actorsDocuments.find(actor => actor.type_peoples_id === person.type_peoples_id);

                let newPerson = {
                    ...person,
                    id: 0,
                    people_id: person.id,
                    people: person,
                    actor: actorFound,
                    actors_documents_id: actorFound?.id,
                    labels: part.labels,
                    require_selfie_photo: false,
                    require_document_photo: false,
                    automatic_whatsapp: false,
                    automatic_mailing: false,
                }

                if (!actorFound?.labels?.some(label => label.id === part.labels?.id)) {                    
                    newPerson.labels = {};
                }

                return newPerson;
            });

            dispatch(setSelectedActors({ value: data }));
        }
    }

    const loadQuestions = async () => {
        const service = new GeneralService("questions-documents");
        const res = await service.getList(1000, { documents_auto_id: dataDocument.id });
        const { questions_documents = [] } = res;
        dispatch(setListQuestions({ value: questions_documents }));
    }

    const validateActors = () => {
        const actorsWithLabel = selectedPeople.filter(item => Object.keys(item.labels).length > 0);
        let valid = actorsWithLabel.length === selectedPeople.length;
        if (!valid) {
            MessageError("Uno de los actores no tiene configurada su respectiva etiqueta");
            return false;
        }

        listActorsSelected.forEach(actor => {
            const count = selectedPeople.filter(person => person.actor.id === actor.id)?.length;
            if (count <= 0) {
                MessageError("Debes seleccionar al menos una persona por cada actor");
                valid = false;
                return;
            }
        });

        return valid;
    }

    const uploadDataDocument = async () => {
        const serviceGenerateDocument = new GeneralService("generate-document");

        const actors = selectedPeople.map(person => {
            return {
                ...person,
                actors_documents_id: person.actor.id,
                label_global_id: person.labels.id,
            }
        });
        const data = {
            documents_auto_id: dataDocument.id,
            needs_zapsign: generateDocument.needs_zapsign,
            actors,
        }

        dispatch(setSelectedPerson({ value: {} }));

        if (generateDocument.id === ID_NOT_DEFINED) {
            const res = await serviceGenerateDocument.post(data);
            if (res.is_ok) {
                dispatch(setGenerateDocument({ value: { ...res.generate_document, answers: [] } }));
                res.generate_document.actors.forEach(item => {
                    const person = {
                        ...selectedPeople.find(p => p.people_id === item.people_id),
                        id: item.id,
                        generate_document_id: res.generate_document.id,
                    }
                    dispatch(updateSelectedActors({ value: person }));
                });

                await associateDocumentWithMovement(res.generate_document.id);

                return true;
            }
            MessageError(res.message); // TODO: Change by GeneralService
            return false;
        }

        const serviceActor = new GeneralService("actors-generate-document");
        const filtered = actors.filter(item => item.id === ID_NOT_DEFINED)
        for (let index in filtered) {
            const object = { generate_document_id: generateDocument.id, ...filtered[index] };
            const res = await serviceActor.post(object);
            if (res.is_ok) {
                const person = {
                    ...filtered[index],
                    id: res.actors_generate_document.id,
                }
                dispatch(updateSelectedActors({ value: person }));
                toast(dispatch, res.message);
            } else {
                await MessageError(res.message); // TODO: Change by GeneralService
            }
        }

        return true;
    }

    const associateDocumentWithMovement = async (documentId) => {
        if (movementId !== ID_NOT_DEFINED && expedientId !== ID_NOT_DEFINED) {
            const service = new GeneralService("movement/associate-document");
            const res = await service.post({ id: movementId, generate_document_id: documentId });
            if (res.is_ok) {
                toast(dispatch, "Progreso guardado y asociado");
                return;
            }
            MessageError(res.message); // TODO: Change by GeneralService
        }
    }

    const createAnswers = async () => {
        const notRegistered = listAnswers.filter(item => item.id === ID_NOT_DEFINED);
        if (notRegistered?.length < 1) {
            return true;
        }
        const service = new GeneralService("answers-questinos-generate");
        const data = {
            generate_document_id: generateDocument.id,
            answers: notRegistered,
        };

        const res = await service.post(data);
        if (res.is_ok) {
            toast(dispatch, res.message);
            // When registered answers in API, we update the ID obtained
            res.answers.forEach(item => {
                const answerFound = listAnswers.find(answer => answer.code_questions_id === item.code_questions_id);
                dispatch(updateGenerateAnswer({ value: { ...answerFound, id: item.id } }));
            })
            return true;
        }
        MessageError(res.message, service.status);
        return false;
    }

    const loadGeneratedDocument = async () => {
        if (process !== ID_NOT_DEFINED) {
            const service = new GeneralService("generate-document/" + process);
            const res = await service.getRequest();
            if (service.status === HTTP_OK) {
                dispatch(setGenerateDocument({ value: res }));

                const data = res.actors?.map(itemActor => {
                    const person = itemActor.people;

                    return {
                        ...person,
                        id: itemActor.id,
                        people_id: person.id,
                        people: person,
                        actor: itemActor.actor,
                        actors_documents_id: itemActor.actor?.id,
                        labels: itemActor.label,
                    }
                });
                dispatch(setSelectedActors({ value: data }));
                dispatch(setListAnswers({ value: res.answers ?? [] }));
            }
        }
    }

    useEffect(() => {
        switch (currentStep) {
            case 2:
                if (!validateActors()) setCurrentStep(1);
                break;
            case 3:
                if (!validateActors()) return setCurrentStep(1);
                uploadDataDocument().then(res => {
                    if (!res) setCurrentStep(1);
                })
                break;
            case 4:
                createAnswers().then(res => {
                    if (!res) setCurrentStep(1);
                })
                break;
        }
    }, [currentStep, selectedPeople, listActorsSelected]);

    useEffect(() => {
        loadDocument();
    }, [id, refreshActors]);

    useEffect(() => {
        loadActors();
    }, [dataDocument.id, refreshActors, listTypePeople]);

    useEffect(() => {
        loadQuestions();
    }, [dataDocument.id, refreshQuestions]);

    useEffect(() => {
        loadGeneratedDocument();
    }, [process]);

    return {
        currentStep,
        setCurrentStep,
        dataDocument,
    }
}
