import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import ClusterActor from "./ClusterActor";
import CardTypeActor from './CardTypeActor';

import { addActor } from "../../../reducers/forms/reducerCreateProcessForm";
import { ID_NOT_DEFINED } from '../../../constant/constant';
import { MessageError } from "../../../utils/message";
import ZapsignOptions from "./ZapsignOptions";

const TabActors = ({ setCurrentStep }) => {
    const { selectedForm } = useSelector(state => state.formSlice);
    const { selectedActor, listActors } = useSelector(state => state.createProcessFormSlice);
    const dispatch = useDispatch();
    const refForm = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        for (let k = 0; k < selectedForm.actors.length; k++) {
            let found = false;
            for (let i = 0; i < listActors.length; i++) {
                if (selectedForm.actors[k].id === listActors[i].actor_form_id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                MessageError("Debes crear al menos una persona por cada etiqueta 🏷️");
                return;
            }
        }

        for (let i = 0; i < listActors.length; i++) {
            if (Object.keys(listActors[i].data).length < listActors[i].required_fields) {
                MessageError("No has completado los datos requeridos de una de las personas");
                return;
            }
        }

        setCurrentStep(c => c + 1);
    }

    const handleAddActor =()=> {
        if (refForm.current.checkValidity()) dispatch(addActor())
        else refForm.current.reportValidity();
    }

    return (
        <div className="process-tab" style={{ minHeight: "500px" }}>
            <div className="row">
                <div className="col-12 col-lg-4 border-right-dashed">

                    <div className="row p-1">
                        <div className="col">
                            <h5><b>Gestiona las personas y su verificación</b></h5>
                            <p>Presiona en (+) para crear una nueva persona, configura su etiqueta y sus datos.</p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-10 pt-1 fw-bold">
                            Lista de personas
                        </div>
                        <div className="col-2">
                            <button title="Agregar nueva persona" className="btn btn-success mt-1 rounded-pill py-1 px-2" type="button"
                                onClick={handleAddActor}>
                                <i className="fa-solid fa-plus text-white" style={{ fontSize: '16px' }} />
                            </button>
                        </div>
                    </div>

                    {
                        listActors.map((actor, i) => <CardTypeActor
                            key={actor.id}
                            actor={actor}
                            order={i + 1}
                            active={selectedActor.id === actor.id}
                            refForm={refForm}
                        />)
                    }

                </div>

                <div className="col-12 col-lg-8">
                    <form ref={refForm} className="p-0" onSubmit={handleSubmit}>
                        <ZapsignOptions />

                        <hr className="mt-4 text-green" />

                        {
                            selectedActor.id !== ID_NOT_DEFINED
                                ?
                                <>
                                    <div className="row ms-1">
                                        <div className="col">
                                            <h5 className="text-center">
                                                Completa los datos para esta persona
                                            </h5>
                                            <small style={{ display: 'block', textAlign: "center" }}>
                                                Los campos con asterísco son obligatorios
                                                <i style={{ color: 'red', fontWeight: 'bold' }}>*</i>
                                            </small>
                                        </div>
                                    </div>

                                    <ClusterActor />
                                </>
                                :
                                <div className="alert alert-warning text-center" role="alert" style={{ marginBottom: '0px', margin: '25px 12px' }}>
                                    Agrega una persona y diligencia sus datos
                                </div>
                        }
                        <div className="row">
                            <div className="col mt-5">
                                <button type="submit" className="btn btn-success rounded-3 d-block ms-auto px-3 py-2">
                                    Siguiente paso <i className="fa-solid fa-caret-right"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default TabActors;