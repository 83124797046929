import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../../../app/components/IconButton";

import GeneralService from "../../../../services/GeneralService";
import { cleanData, deleteFromTypeProcess, setList, setSelected } from "../../../../reducers/expedient/reducerTypeProcess";
import confirmDelete from "../../../../utils/confirmDelete";
import { MessageError, MessageSuccess, cutText } from "../../../../utils";
import { useHistory } from "react-router-dom";

export const ListTypeProcess = ({ show, setShow }) => {
    const { listFilter } = useSelector(state => state.typeProcessSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const getTypeProcess = async () => {
        const service = new GeneralService("type-process");
        const res = await service.getList(1000);
        const { type_process = [] } = res;
        dispatch(setList(type_process));
    }

    const deleteTypeProcess = async (id) => {
        const service = new GeneralService("type-process");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess("El tipo de proceso ha sido eliminado exitosamente!");
            dispatch(deleteFromTypeProcess(id));
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        getTypeProcess();
    }, []);

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay tipos de procesos que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr className="font-lato">
                                        <th></th>
                                        <th>#</th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Acciones</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr className="font-rubik" key={item.id} onDoubleClick={()=>history.push('/type-movement/list?typeProcess='+item.id)}>
                                                <td></td>
                                                <td>{i + 1}.</td>
                                                <td style={{ maxWidth: "300px" }}>{cutText(item.name, 50)}</td>
                                                <td style={{ maxWidth: "400px" }}>{cutText(item.description, 65)}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar tipo de proceso"
                                                        onClick={() => {
                                                            dispatch(cleanData());
                                                            dispatch(setSelected(item));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar tipo de proceso"
                                                        onClick={async () => await confirmDelete(() => deleteTypeProcess(item.id))}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-gear text-purple"
                                                        title="Parametrizar movimientos"
                                                        onClick={()=>history.push('/type-movement/list?typeProcess='+item.id)}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    );
}
