import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import BreadcrumbCustom from '../../../../app/components/BreadcrumCustom';
import { SearchCategory } from './searchCategory';
import { ListCategory } from './listCategory';
import ModalCategoryPeople from './ModalCategoryPeople';

import { cleanData, setCategory } from '../../../../reducers/typePeople/reducerCategory';
import { setSelectedTypepeople } from '../../../../reducers/typePeople/reducerTypepeople';
import service from './service/serviceCategory';
import { ID_NOT_DEFINED } from '../../../../constant/constant';

const TypepeopleCategory = () => {
    const { list, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { people = 0 } = queryString.parse(search);

    const loadData = async (id) => {
        if (selectedTypepeople.id === ID_NOT_DEFINED) {
            dispatch(setSelectedTypepeople({ value: list.find(i => i.id === parseInt(people)) }));
            const res = await service.getList(id);
            const { categories = [] } = res;
            dispatch(setCategory({ value: categories }));
            return;
        }
        const res = await service.getList(selectedTypepeople.id);
        const { categories = [] } = res;
        dispatch(setCategory({ value: categories }));
    }

    useEffect(() => {
        loadData(people);
    }, [people, list]);

    return (
        <>
            <div className='mx-4'>
                <BreadcrumbCustom
                    title="Categorías"
                    routes={[
                        { name: "Tipos de persona", link: "/typepeople/list" },
                        { name: selectedTypepeople.name ? selectedTypepeople.name : "Seleccione tipo de persona", link: "/typepeople/list" },
                    ]}
                    activeRoute="Lista categorías"
                />

                <div className="container m-0 p-0">
                    <div className="row">
                        <div className="col-8">
                            <SearchCategory />
                        </div>
                        <div className="col-4">
                            <button title="Crear nueva categoría" className="btn btn-success mt-1" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                                <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                                Crear categoría
                            </button>
                        </div>
                    </div>
                </div>

                <div className="table-data">
                    <ListCategory setShow={setShow} />
                </div>
            </div>
            <ModalCategoryPeople show={show} setShow={setShow} />
        </>
    )
}

export default TypepeopleCategory;