import { useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import ToastNotify from "../app/components/ToastNotify";
import LoadingCustom from "../app/components/LoadingCustom";
import VirtualNotary from "../modules/FillOutForm/components/VirtualNotary";
import FillOutFormView from "../modules/FillOutForm/components/FillOutFormView";


const PublicSortomFormRoutes = () => {
    const { show } = useSelector(state => state.toastSlice);

    return (
        <div>
            {show && <ToastNotify />}
            <LoadingCustom />

            <Router>
                <Switch>
                    <Route exact path="/virtual-notary/"> <VirtualNotary /> </Route>
                    <Route exact path="/virtual-notary/fill-out/:id"> <FillOutFormView /> </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default PublicSortomFormRoutes;