import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GeneralService from "../../../../services/GeneralService";
import { addSelectedActors, setListActorsSelected } from "../../../../reducers/documents/reducerGenerateDocument";
import { ALERT_DANGER } from "../../../../constant/constant";
import { toast } from "../../../../utils/toast";

export const useHandleActors = () => {
    const { dataDocument, selectedPeople, listActorsSelected, totalLabels } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();
    const [activeTypePeople, setActiveTypePeople] = useState([]);

    const loadActorsParam = async () => {
        const service = new GeneralService("actors-documents");
        const res = await service.getList(1000, { documents_auto_id: dataDocument.id });
        const { actors_documents = [] } = res;
        dispatch(setListActorsSelected({ value: actors_documents }));
        actors_documents.forEach(item => setActiveTypePeople(c => [...c, item.type_peoples_id]));
    }

    const handleSelectedPerson = async (person) => {
        if (selectedPeople.some(item => item.people_id === person.id)) {
            toast(dispatch, "Actor ya agregado", ALERT_DANGER);
            return;
        }

        const actorFound = listActorsSelected.find(actor => actor.type_peoples_id === person.type_people.id);
        dispatch(addSelectedActors({
            value: {
                ...person,
                id: 0,
                people_id: person.id,
                people: person,
                actor: actorFound,
                actors_documents_id: actorFound.id,
                labels: {},
                require_selfie_photo: false,
                require_document_photo: false,
                automatic_whatsapp: false,
                automatic_mailing: false,
            }
        }));
    }

    useEffect(() => {
        loadActorsParam();
    }, [dataDocument.id]);  

    return {
        totalLabels,
        activeTypePeople,
        handleSelectedPerson,
        selectedPeople,
        dispatch,
    }
}
