import { useState } from "react";

const CardGlobalCode = ({code, onClick}) => {
    const [pasted, setPasted] = useState(false);

    const copyTo =()=>{
        setPasted(true);
        setTimeout(() => {
            setPasted(false);
        }, 3000);
    }

    return (
        <div
            className="col-11 mt-1 p-2 card-favorite"
            title={code.name}
            onClick={()=>{onClick(); copyTo();}}
        >
            <div className="row">
                <div className="col-2">
                    <i className="fa-solid fa-at text-green ms-2 mt-2 fs-5"></i>
                </div>
                <div className="col">
                    <div className="row fw-bold fs-7">
                        {code.code}
                    </div>
                    <div className="row fs-6">
                        {code.name}
                    </div>
                </div>
                <div className="col-2 pt-1">
                    {
                        pasted  && <i className="fa-solid fa-circle-check text-green mt-2 fs-5 animate__animated animate__fadeIn"></i>
                    }
                </div>
            </div>
        </div>
    )
}

export default CardGlobalCode;