
export const SignatureLegend = () => {
    return (
        <div className="row">
            <div className="col text-end">
                <div className="d-inline-block me-2">
                    <i className="fa-solid fa-file-circle-check text-success"></i> Firmado
                </div>
                <div className="d-inline-block me-2">
                    <i className="fa-solid fa-file-circle-xmark text-orange"></i> No firmado
                </div>
            </div>
        </div>
    )
}
