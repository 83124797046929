import { createSlice } from "@reduxjs/toolkit";

export const typeProcessSlice = createSlice({
    name: "typeProcessSlice",
    initialState: {
        selectedTypeProcess: {
            id: 0,
            name: "",
            description: "",
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, action) => {
            state.selectedTypeProcess[action.payload.key] = action.payload.value;
        },
        reducerFormSearch: (state, action) => {
            if (action.payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromTypeProcess: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload);
        },
        updateFromTypeProcess: (state, action) => {
            const code = action.payload;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromTypeProcess: (state, action) => {
            state.list.push(action.payload);
            state.listFilter.push(action.payload);
        },
        setSelected: (state, action) => {
            state.selectedTypeProcess = action.payload;
        },
        cleanData: (state, action) => {
            state.selectedTypeProcess = {
                id: 0,
                name: "",
                description: "",
            };
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromTypeProcess,
    updateFromTypeProcess,
    insertFromTypeProcess,
    setSelected,
    cleanData,
} = typeProcessSlice.actions;

export default typeProcessSlice.reducer;