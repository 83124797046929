import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import { MessageError, MessageSuccess } from '../../../../utils/message';
import ModalCopyGroup from '../../../CopyClusters/ModalCopyGroup';

import { deleteGroup, setSelectedGroup } from '../../../../reducers/legalCase/reducerClusterLegalCase';
import { setData } from '../../../../reducers/copyClusters/reducerCopyGroup';
import IconButton from '../../../../app/components/IconButton';
import confirmDelete from '../../../../utils/confirmDelete';
import GeneralService from '../../../../services/GeneralService';

const ListClusterLegalCase = ({ setShow }) => {
    const { listFilter } = useSelector(state => state.clusterLegalCaseSlice);
    const [showCopy, setShowCopy] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const deleteInstance = async (id) => {
        const service = new GeneralService("cluster-legal-case");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(deleteGroup({ value: id }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleParametrize = (item) => {
        history.push({
            pathname: "/case-parameterization/fields",
            search: `?legalCase=${item.legal_case_id}&cluster=${item.id}`,
        });
    }

    const handleCopyGroup = ( item ) => {
        setShowCopy(true);
        dispatch(setData({
            value: {
                cluster_id: item.id,
                cluster_is_category: false,
            }
        }));
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            ¡No has seleccionado un grupo! O no hay grupos para este tipo de caso, selecciona otro...
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Nombre del grupo</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td>{i + 1}.</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar grupo"
                                                        onClick={() => {
                                                            dispatch(setSelectedGroup({value: item}));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar tipo de grupo"
                                                        onClick={async () => await confirmDelete(() => deleteInstance(item.id))}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-gear text-green"
                                                        title="Parametrizar grupo"
                                                        onClick={() => handleParametrize(item)}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-copy text-purple"
                                                        title="Copiar grupo a"
                                                        onClick={() => handleCopyGroup(item)}
                                                    />

                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
            <ModalCopyGroup show={showCopy} setShow={setShowCopy} model="CASES" />
        </>
    )

}

export default ListClusterLegalCase;