import { useSelector } from 'react-redux';

import AccordionActorsClusters from './AccordionActorsClusters';
import IconButton from '../../../app/components/IconButton';

import { exportTextPlain } from '../../../utils/exportTextPlain';
import { textFormCodes, textQuestionCode } from '../utils/exportFunctions';

const SidebarCodesForm = ({ insertCode }) => {
    const { selectedForm } = useSelector(state => state.formSlice);
    const { listQuestions } = useSelector(state => state.dialogsFormSlice);

    const handleExportCodes = () => {
        let text = textFormCodes(selectedForm);
        if(listQuestions.length > 0) {
            text += textQuestionCode(listQuestions);
        }        
        exportTextPlain(selectedForm.name, text);
    }

    return (
        <>
            <div className="offcanvas offcanvas-end overflow-scroll" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header bg-green">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-1">
                                <i className="fa-solid fa-clipboard-list fs-4 ms-2"></i>
                            </div>
                            <div className="col-8">
                                <h5 className="ms-4">Códigos</h5>
                            </div>
                            <div className="col-2">
                                <IconButton
                                    title="Exportar códigos"
                                    icon='fa-solid fa-file-export text-purple'
                                    onClick={handleExportCodes}
                                />
                            </div>
                            <button type="button" className="btn-close m-0" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <div className="offcanvas-body">

                    <div className="container-fluid w-100">

                        <div className="row m-0 mt-2">
                            <AccordionActorsClusters
                                form={selectedForm}
                                insertCode={insertCode}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default SidebarCodesForm;