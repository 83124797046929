import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

import { reducerForm, reducerFormSearch, resetDataSearchCase, setListFoundCase } from "../../../reducers/createCase/reducerCreateCase";
import { HTTP_NO_CONTENT, ID_NOT_DEFINED } from "../../../constant/constant";
import { extractFieldsCaseTable } from "../../DocumentsAuto/utils/functions";
import { hideToast, showToast } from "../../../reducers/main/toastReducer";
import Search from "../assets/search.svg";
import Settings from "../assets/settings.svg";
import { MessageInfo } from "../../../utils/message";
import GeneralService from "../../../services/GeneralService";

const SearchBarCase = ({ forParameterization = true }) => {
    const { dataLegalCase, dataSearch, fieldsCase, refreshTable, fieldsTable } = useSelector(state => state.createCaseSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const filtered = fieldsTable.filter(item => item.state);

    const setDataInput = (e) => {
        dispatch(reducerFormSearch({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const setData = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleConfiguration = () => {
        history.push({
            pathname: "/configurations",
            search: `?tabIndex=1`,
        });
    }

    const toast = () => {
        dispatch(showToast({
            type: "alert-primary",
            message: "No se ha seleccionado el tipo de caso",
        }));
        setTimeout(() => {
            dispatch(hideToast());
        }, 3000);
    }

    const searchCases = async () => {
        if (parseInt(dataLegalCase.legal_case_id) === ID_NOT_DEFINED) {
            toast();
            return;
        }

        const keys = Object.keys(dataSearch);
        const list = [];
        const listCategory = [];

        keys.forEach(e => list.push(dataSearch[e]));

        if (list.length > 0 || listCategory.length > 0) {
            const service = new GeneralService("create-case/find");
            const res = await service.post({ parameterizations: list });
            if (res.is_ok) {
                const { create_case = [] } = res;
                const proccess = extractFieldsCaseTable(create_case, filtered);
                dispatch(setListFoundCase({ value: proccess }));
                return;
            }
            if (service.status === HTTP_NO_CONTENT) {
                MessageInfo("No se encontró ningún caso");
                dispatch(setListFoundCase({ value: [] }));
            }
        }
        else {
            await loadAll();
        }
    }

    // Resetea el campo de búsqueda para hacer que el refresh de la tabla cuando se elimine sea llamando este método el cual lista todos los terceros.
    const loadAll = async () => {
        if (parseInt(dataLegalCase.legal_case_id) === ID_NOT_DEFINED) {
            toast();
            return;
        }
        
        dispatch(resetDataSearchCase());
        const service = new GeneralService("create-case");
        const res = await service.getList(1000);
        const { create_case = [] } = res;
        const proccess = extractFieldsCaseTable(create_case, filtered);
        dispatch(setListFoundCase({ value: proccess }));
    }

    useEffect(() => {
        searchCases();
    }, [refreshTable]);

    return (
        <div className='search-bar rounded-3 p-1'>
            <div className='row'>
                <div className='col-1' style={{ maxWidth: "40px" }}>
                    <img src={Search} className="mb-0 mt-2 ms-2 img-button-tercero" alt="Configuraciones" style={{ color: "#158B3D" }} />
                </div>
                <div className='col-4'>
                    <input
                        type="text"
                        name={dataLegalCase.field_favorite_id.split("--")[1] ?? "none"}
                        className='mt-1 ps-1 py-1 input-borderless'
                        placeholder={dataLegalCase.field_favorite_id === "" ? "Selecciona un campo favorito" : 'Digita el dato'}
                        required
                        value={dataSearch[dataLegalCase.field_favorite_id.split("--")[1]]?.value ?? ""}
                        autoComplete='off'
                        onChange={setDataInput}
                        readOnly={dataLegalCase.field_favorite_id === ""}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") searchCases();
                        }}
                    />
                </div>

                <div className='col-3'>
                    <select
                        className="form-select input-borderless"
                        name="field_favorite_id"
                        style={{ backgroundColor: '#fafafa', border: 'none', height: '44px' }}
                        value={dataLegalCase.field_favorite_id}
                        onChange={setData}
                    >
                        <option value="0">
                            Seleccione
                        </option>
                        {
                            fieldsCase.filter(item => item.state).map((item) => (
                                <option value={`${item.id}--${item.name}--${item.category_legal_case_id ? 1 : 0}`} key={uuidv4()}>
                                    {item.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <div className='col'>
                    <Button title="Haz la búsqueda con los parámetros especificados" className='mt-1' variant="success" onClick={searchCases}>
                        Buscar
                    </Button>
                </div>
                {
                    forParameterization && <>
                        <div className='col'>
                            <Button title="Listar en la tabla todos los terceros existentes" className='mt-1' variant="success" onClick={loadAll}>
                                <i className="fa-solid fa-list-ol text-white me-1" style={{ fontSize: '16px' }} />
                                Listar todo
                            </Button>
                        </div>

                        <div className='col'>
                            <img src={Settings} className="mb-0 mt-2 img-button-tercero d-block ms-auto me-3" title="Ir a las configuraciones códigos favoritos y códigos de tablas" alt="Configuraciones" role="button" tabIndex="0" onClick={() => handleConfiguration()} />
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default SearchBarCase;