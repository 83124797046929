
const LegendCard = ({ title = "", icon = "" }) => {
    return (
        <div className="col-3 mt-2">
            <div className="row">

                <div className="col-1">
                    <div className="card-process__circle">
                        <i className={`fa-solid me-2 ${icon}`}></i>
                    </div>
                </div>
                <div className="col fs-7">
                    {title}
                </div>
            </div>
        </div>
    )
}

export default LegendCard;