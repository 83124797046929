import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GeneralService from '../../../services/GeneralService';
import { setFavoriteCodes, setFavoriteCodesTable, setFieldsPeopleTable, setFieldsTypePeople } from '../../../reducers/people/reducerPeople';
import { filterFields } from '../../DocumentsAuto/utils/functions';
import { ID_NOT_DEFINED, MODEL_TYPE_PEOPLE, USE_FOR_SEARCH, USE_FOR_TABLE } from '../../../constant/constant';
import { MessageInfo } from '../../../utils/message';

export const useLoadFavoritePeople = () => {
  const { list } = useSelector(state => state.typepeopleSlice);
  const { dataTypePeole } = useSelector(state => state.peopleSlice);
  const dispatch = useDispatch();

  const loadFavorite = async (id) => {
    if (parseInt(id) !== ID_NOT_DEFINED) {
      const service = new GeneralService("favorite-code");
      const { codes = [] } = await service.getList(1000, { parent_id: id, type_model: MODEL_TYPE_PEOPLE, used_for: USE_FOR_SEARCH });
      if (codes.length === 0) {
        await MessageInfo("No hay campos de búsqueda", "Al tipo de persona seleccionado no se le han configurado aún campos de búsqueda.");
      }
      dispatch(setFavoriteCodes({ value: codes }));

      const typePeople = list.find(e => e.id === parseInt(id));

      const lista = filterFields(typePeople, codes);
      dispatch(setFieldsTypePeople({ value: lista }));

      // Cargar los favoritos de la tabla
      const { codes: codesTable = [] } = await service.getList(1000, { parent_id: id, type_model: MODEL_TYPE_PEOPLE, used_for: USE_FOR_TABLE });
      if (codesTable.length === 0) {
        await MessageInfo("No hay columnas para la tabla", "Al tipo de persona seleccionado no se le han configurado aún las columnas favoritas de la tabla.");
      }
      dispatch(setFavoriteCodesTable({ value: codesTable }));

      const listaTable = filterFields(typePeople, codesTable);
      dispatch(setFieldsPeopleTable({ value: listaTable }));
    } else {
      dispatch(setFavoriteCodes({ value: [] }));
      dispatch(setFieldsTypePeople({ value: [] }));
      dispatch(setFavoriteCodesTable({ value: [] }));
      dispatch(setFieldsPeopleTable({ value: [] }));
    }
  }

  useEffect(() => {
    loadFavorite(dataTypePeole.type_people_id);
  }, [dataTypePeole.type_people_id]);

  return;
}
