import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BreadcrumbCustom from "../../app/components/BreadcrumCustom"
import BarChart from "./components/BarChart"
import PieChart from "./components/PieChart";
import IconButton from "../../app/components/IconButton";

import GeneralService from "../../services/GeneralService";
import { applyFilters, setFilter, setList } from "../../reducers/business/reducerViews";
import { formatDateTimeLayoutISO, getTimeAgo, time, toast } from "../../utils";
import { CardTotalVisits } from "./components/CardTotalVisits";
import { countries } from "../../assets/data/countries";
import { SHOW } from "../../constant/constant";
import { sortByDate } from "./utils/sort";

export const VisitsView = () => {
    const { listFilter, listCountries, selectedCountry, selectedRegion, totalViewsSortom, totalViewsNotary, regions } = useSelector(state => state.viewsSlice);
    const dispatch = useDispatch();
    const [timeSelect, setTimeSelect] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const loadViews = async (showConfirmation = false) => {
        const service = new GeneralService("log-visitor");
        const { content: sortom = [] } = await service.getList(0, { is_sortom: true, start_date: startDate, end_date: endDate });
        dispatch(setList({ sortom: sortByDate(sortom) }));

        const { content: notary = [] } = await service.getList(0, { is_sortom: false, start_date: startDate, end_date: endDate });
        dispatch(setList({ notary: sortByDate(notary) }));

        if(showConfirmation) toast(dispatch, "Información actualizada");
    }

    const setData = (e) => {
        dispatch(setFilter({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    useEffect(() => {
        setEndDate(formatDateTimeLayoutISO(new Date()));
        switch (timeSelect) {
            case "1":
                setEndDate("");
                setStartDate("");
                break;
            case "2":
                setStartDate(formatDateTimeLayoutISO(getTimeAgo(time.Day * 7)));
                break;
            case "3":
                setStartDate(formatDateTimeLayoutISO(getTimeAgo(time.Day * 14)));
                break;
            case "4":
                setStartDate(formatDateTimeLayoutISO(getTimeAgo(time.Day * 21)));
                break;
            case "5":
                setStartDate(formatDateTimeLayoutISO(getTimeAgo(time.Month * 1)));
                break;
            case "6":
                setStartDate(formatDateTimeLayoutISO(getTimeAgo(time.Month * 2)));
                break;
            case "7":
                setStartDate(formatDateTimeLayoutISO(getTimeAgo(time.Month * 3)));
                break;
            default:
                break;
        }

    }, [timeSelect]);

    useEffect(() => {
        dispatch(applyFilters());
    }, [selectedCountry, selectedRegion]);

    useEffect(() => {
        loadViews();
    }, [startDate, endDate]);

    return (
        <div className="py-1 px-3 max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Visitas"
                        activeRoute="/ Detalles"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-2 col-xl-3">
                    <div className="row">
                        <div className="col d-flex flex-row justify-content-center align-items-center">
                            <CardTotalVisits title="Sortom" icon="fa-s" total={totalViewsSortom} />
                            <CardTotalVisits title="Notaría" icon="fa-building-columns" total={totalViewsNotary} />
                            <IconButton
                                icon="fa-solid fa-arrows-rotate text-green"
                                onClick={() => loadViews(SHOW)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col d-flex flex-row align-items-center">
                            <i className="fa-solid fa-filter me-2"></i>
                            <h5 className="font-lato fw-bold">Filtros</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>¿Desde hace cuánto?</label>
                            <select
                                className="form-select green-input"
                                name="timeSelect"
                                value={timeSelect}
                                onChange={(e) => setTimeSelect(e.target.value)}
                            >
                                <option value="1">Todas</option>
                                <option value="2">Última semana</option>
                                <option value="3">Dos semanas atrás</option>
                                <option value="4">Tres semanas atrás</option>
                                <option value="5">Último mes</option>
                                <option value="6">Últimos dos meses</option>
                                <option value="7">Últimos tres meses</option>
                                <option value="8">Personalizado</option>
                            </select>

                            {
                                timeSelect === "8"
                                &&
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label className="font-rubik">Fecha inicial</label>
                                        <input
                                            type="date"
                                            className="form-control green-input"
                                            name="start_date"
                                            value={startDate.substring(0, 10)}
                                            onChange={(e) => setStartDate(e.target.value + "T00:00")}
                                            required
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label className="font-rubik">Fecha final</label>
                                        <input
                                            type="date"
                                            className="form-control green-input"
                                            name="start_date"
                                            value={endDate.substring(0, 10)}
                                            onChange={(e) => setEndDate(e.target.value + "T00:00")}
                                            required
                                        />
                                    </div>
                                </div>
                            }

                            <label className="d-block mt-2 mb-1">¿Desde qué país?</label>
                            <select
                                className="form-select green-input"
                                name="selectedCountry"
                                value={selectedCountry}
                                onChange={setData}
                            >
                                <option value="">Todos</option>
                                {
                                    listCountries.map(country => <option key={country} value={country}>
                                        {countries[country] ?? country}
                                    </option>)
                                }
                            </select>

                            <label className="d-block mt-2 mb-1">Región</label>
                            <select
                                className="form-select green-input"
                                name="selectedRegion"
                                value={selectedRegion}
                                onChange={setData}
                            >
                                <option value="">Todas</option>
                                {
                                    regions[selectedCountry]?.map(region => <option key={region} value={region}>
                                        {region}
                                    </option>)
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-7 col-xl-9">
                    <div className="row">
                        <div className="col">
                            <BarChart data={listFilter.sortom} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <PieChart />
                    </div>
                </div>
            </div>
        </div>
    )
}
