import { Accordion } from "react-bootstrap";

import ItemCardGroup from "./ItemCardGroup";
import CardLabelActor from "./CardLabelActor";

import { useEffect, useState } from "react";
import { ALL_ACTORS } from "../../../../constant/constant";
import Person from '../../assets/person.svg';

const AccordionActor = ({ typePeople, insertCode, actor }) => {
    const [activeLabel, setActiveLabel] = useState("");

    useEffect(() => {
        setActiveLabel(actor.labels ? actor.labels[0].name : "")
    }, [actor.labels]);

    const insertCodeWithLabel = (content) => {
        if (activeLabel === ALL_ACTORS) {
            insertCode(`{{@${content}}}`);
            return;
        }
        insertCode(`@${content}#${activeLabel}`);
    }

    return (
        <Accordion
            className="m-0 p-0"
            defaultActiveKey={['0']}
            alwaysOpen
            flush
        >
            <Accordion.Item
                eventKey="main"
            >
                <Accordion.Header className="p-2 accordion-header">
                    <div className='container-fluid p-0'>
                        <div className='row m-0 px-2'>
                            <div className='col-2 m-0 p-0'>
                                <img src={Person} className="mb-0 personImage" alt="" />
                            </div>
                            <div className='col-10 m-0 p-0'>
                                <small className='smallLetter'>Tipo de persona</small>
                                <br></br>
                                <span className='spanLetter'>{typePeople?.name}</span>
                            </div>
                        </div>
                    </div>
                </Accordion.Header>

                <Accordion.Body className="m-0 p-0 pt-3">
                    <p>Etiquetas</p>
                    {
                        actor.labels ?
                            <>
                                {
                                    actor.labels?.map(e => <CardLabelActor
                                        key={e.id}
                                        name={e.name}
                                        active={activeLabel === e.name}
                                        onClick={() => setActiveLabel(e.name)}
                                    />)
                                }
                                {
                                    actor.labels.length > 1 &&
                                    <CardLabelActor
                                        name="TODOS"
                                        active={activeLabel === "TODOS"}
                                        onClick={() => setActiveLabel("TODOS")}
                                    />
                                }
                            </>
                            :
                            <div className="alert alert-warning text-center mt-2" role="alert">
                                No hay etiquetas para este actor
                            </div>
                    }

                    <div className="pt-3"></div>

                    {/* Item for groups */}
                    <ItemCardGroup
                        list={typePeople}
                        nameParameterization="parameterizations"
                        keyGroups="item-groups-main"
                        keyByGroup="main-group"
                        insertCode={insertCodeWithLabel}
                    />

                    {/* Item for categories */}
                    <Accordion.Item eventKey="item-categories-main">
                        <Accordion.Header className="p-0 accordion-header">
                            <div className="container-fluid p-0 accordion-children-container">
                                <div className='row m-0 px-2'>
                                    <div className='col-2 m-0 p-0'>
                                        <i className="fa-brands fa-cuttlefish text-green" style={{ fontSize: 18 }}></i>
                                    </div>
                                    <div className='col-10 m-0 p-0'>
                                        <span className='spanLetter'>Categorías</span>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="m-0 p-0 ps-2">
                            {
                                typePeople?.category_peoples?.map((category) =>
                                (
                                    <Accordion.Item eventKey={`item-category-${category.id}`} key={category.id}>
                                        <Accordion.Header className="p-0 accordion-header">
                                            <div className="container-fluid p-0 accordion-children-container">
                                                <div className='row m-0 px-2'>
                                                    <div className='col-2 m-0 p-0'>
                                                        <i className="fa-solid fa-hurricane text-black pt-2" style={{ fontSize: 16 }}></i>
                                                    </div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <small className='smallLetter'>Categoría</small>
                                                        <br></br>
                                                        <span className='spanLetter'>{category.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body className="m-0 p-0 ps-2">

                                            {/* Category Groups*/}
                                            <ItemCardGroup
                                                list={category}
                                                nameParameterization="parameterizations"
                                                keyGroups="item-category-groups"
                                                keyByGroup="item-category-group"
                                                insertCode={insertCodeWithLabel}
                                            />

                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                        </Accordion.Body>
                    </Accordion.Item>


                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default AccordionActor;