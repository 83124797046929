

export const aceptFile = ( id ) => {
    let file = '';
    //Documento 
    if ( id === 1 ){
        file = ' application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .txt, .docm, .docx, .odt, .pdf, .rtf, .csv, .xls, .xlsx, .xlsm, .ods, .pps, .ppt, .ppsx, .pptx, .ppsm, .pptm, .potx, .odp ';

    //Comprimido
    } else if (id === 2){
        file = ' .zip, .rar, .rar5, .ace, .r00, .gz ';

    //Imagen
    } else if (id === 3){
        file = 'image/*';

    //Video 
    } else if (id === 4){
        file = 'video/*';

    //Audio
    } else if (id === 5){
        file = 'audio/*'

    } else {
        file = ''
    }

    return file
} 