import { useDispatch } from "react-redux";
import { searchTextUsers, searchNumberUsers } from '../../../reducers/user/reducerUsers'
import { SearchBar } from "../../../app/components/SearchBar";

const SearchUsers = () => {
    const dispatch = useDispatch();

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row m-0 p-0">

                    <div className='col-6'>
                        <SearchBar
                            placeholder="Buscar por nombre"
                            onChange={(e) => dispatch(searchTextUsers(e.target.value))}
                        />
                    </div>

                    <div className='col-6'>
                        <SearchBar
                            placeholder="Buscar por CC"
                            onChange={(e) => dispatch(searchNumberUsers(e.target.value))}
                        />
                    </div>
                </div>
            </div>
        </>
    );

}

export default SearchUsers;