import { createSlice } from '@reduxjs/toolkit';
import { extractFields } from '../../modules/TercerosParam/utils/extract';

export const typepeopleSlice = createSlice({
  name: 'typepeopleSlice',
  initialState: {
    dataTypepeople: {
      name: "",
      description: "",
    },
    list: [],
    listFilter: [],
    isLoading: false,
    selectedTypepeople: {
      id: 0,
      name: "",
      description: "",
    },
    selectedCategory: {},
    listCategories: [],
  },
  reducers: {
    setDataTypepeople: (state, action) => {
      state.selectedTypepeople[action.payload.key] = action.payload.value;
    },
    setTypepeople: (state, action) => {
      state.list = action.payload.value
      state.listFilter = action.payload.value
      state.isLoading = false
    },
    setData: (state, action) => {
      state.dataTypepeople = action.payload.value
    },
    deleteTypepeople: (state, action) => {
      const newList = state.list.filter(e => e.id !== action.payload.value)
      state.list = newList;
      state.listFilter = newList;
    },
    setSelectedTypepeople: (state, action) => {
      state.selectedTypepeople = action.payload.value ?? { id: 0, name: "", description: "" };
    },
    setEditSelectedTypeople: (state, action) => {
      state.selectedTypepeople[action.payload.key] = action.payload.value;
    },
    addNewObjectToList: (state, action) => {
      state.list.push(action.payload.value);
      state.listFilter.push(action.payload.value);
      state.dataTypepeople = {
        name: "",
        description: "",
        business_id: null
      }
    },
    updateNewObjectAfterEdit: (state, action) => {
      const typepeople = action.payload.value;
      const modifiedList = state.list.map((e) => {
        if (e.id === typepeople.id) {
          return typepeople
        }
        return e;
      });
      const modifiedListFilter = state.listFilter.map((e) => {
        if (e.id === typepeople.id) {
          return typepeople;
        }
        return e;
      });
      state.list = modifiedList;
      state.listFilter = modifiedListFilter;
      state.dataTypepeople = {
        name: "",
        description: "",
      }
    },
    searchTypepeople: (state, action) => {
      if (action.payload.value === "") {
        state.listFilter = state.list;
        return;
      }
      const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
      state.listFilter = filtered;
    },
    setSelectedCategory: (state, action) => {
      const selected = parseInt(action.payload.value);
      if (selected !== 0) {
        state.selectedCategory = state.selectedTypepeople.categories.find(c => c.id === selected) ?? {};
      }
    },
    setListCategories: (state) => {
      state.listCategories.push(state.selectedCategory);

      const fields = extractFields(state.selectedCategory.clusters);
      fields.forEach(param => {
        if (param.required === 0 && param.alert_required) {
          state.dataPeople = {
            ...state.dataPeople,
            [param.name]: {
              value: "",
              parameterization_id: param.id,
              ...param
            }
          }
        }
      });

      state.selectedCategory = {};
    },
    cleanData: (state, action) => {
      state.selectedTypepeople = {
        id: 0,
        name: "",
        description: "",
      };
    },
  },
});

export const {
  setDataTypepeople,
  setTypepeople,
  setData,
  deleteTypepeople,
  addNewObjectToList,
  updateNewObjectAfterEdit,
  searchTypepeople,
  setSelectedTypepeople,
  setEditSelectedTypeople,
  setSelectedCategory,
  setListCategories,
  cleanData,
} = typepeopleSlice.actions;

export default typepeopleSlice.reducer;