import { useEffect, useState } from 'react'
import { downloadImageAsBase64 } from '../../firebase/firebaseStorage'

export const useDownloadImageAsBase64 = (url = "") => {
    const [file, setFile] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const convertFile = async () => {
        setIsLoading(true);
        const base64 = await downloadImageAsBase64(url);
        setFile(base64);
        setIsLoading(false);
    }

    useEffect(() => {
        convertFile();
    }, [url]);

    return { file, isLoading };
}
