import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ID_NOT_DEFINED } from '../../../constant/constant';
import { MessageError, MessageSuccess } from '../../../utils/message';
import { cleanData, insertFromCodes, reducerForm, updateFromCodes } from '../../../reducers/configuration/reducerGlobalCodes';
import { aceptFile } from '../../TercerosCreate/utils/aceptFile';
import { encodeFileBase64 } from '../../Usuarios/utils/functions';
import GeneralService from '../../../services/GeneralService';

const ModalGlobalCode = ({ show, setShow }) => {
    const { selectedCode } = useSelector(state => state.globalCodesSlice);
    const dispatch = useDispatch();
    const service = new GeneralService('global-codes');

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...selectedCode,
            type_fields_id: parseInt(selectedCode.type_fields_id),
        };

        if (parseInt(selectedCode.type_fields_id) === 6) data.type_files_id = parseInt(selectedCode.type_files_id);
        if (parseInt(selectedCode.type_fields_id) === 5) data.value = `${data.value}.000Z`;

        if (selectedCode.id === ID_NOT_DEFINED) {
            const res = await service.post(data);
            if (res.is_ok) {
                MessageSuccess(res.message);
                dispatch(insertFromCodes({ value: res.global_codes }));
                setShow(false);
                dispatch(cleanData());
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(data);
        if (res.is_ok) {
            MessageSuccess(res.message)
            dispatch(updateFromCodes({ value: data }));
            setShow(false);
            dispatch(cleanData());
            return;
        }
        MessageError(res.message, service.status);
    }

    const encodeDocument = async (e) => {
        const base64 = await encodeFileBase64(e.target.files[0]);
        const extension = e.target.files[0].name.split('.').pop();

        if (base64) {
            dispatch(reducerForm({
                key: "value",
                value: `${base64}--EXTENSION--${extension}`
            }));
            return;
        }
        MessageError("No es un documento válido");
    }

    const showInput = (type) => {
        const converted = parseInt(type);

        return converted === 6 ?
            <input
                className="form-control"
                type="file"
                name="value"
                onChange={(e) => encodeDocument(e)}
                accept={aceptFile(parseInt(selectedCode.type_files_id))}
                required
            />
            :
            converted === 8 ?
                <textarea
                    className="form-control custom-textarea mt-1"
                    name="value"
                    value={selectedCode.value}
                    maxLength={255}
                    placeholder="Ingrese el valor"
                    onChange={setData}
                    required
                />
                :
                <input
                    type={
                        converted === 1 || converted === 2 ? 'number' :
                            converted === 3 ? 'date' :
                                converted === 4 ? 'time' :
                                    converted === 5 ? 'datetime-local' :
                                        converted === 7 ? 'email' :
                                            null
                    }
                    className="form-control mt-1"
                    name="value"
                    value={selectedCode.value}
                    maxLength={50}
                    minLength={3}
                    step={converted === 5 ? 1: undefined}
                    placeholder="Ingrese un valor"
                    autoComplete="off"
                    onChange={setData}
                    required
                />;
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedCode.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedCode.id === ID_NOT_DEFINED ?
                                            "Crear Código"
                                            :
                                            "Editar Código"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">

                        <div className="row">

                            <div className='col mb-3'>
                                <label>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="name"
                                    value={selectedCode.name}
                                    maxLength={50}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>


                        </div>

                        <div className='row'>
                            <div className='col mb-3'>
                                <label>Tipo de campo<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select mt-1"
                                    name="type_fields_id"
                                    value={selectedCode.type_fields_id}
                                    onChange={setData}
                                    required
                                >
                                    <option value="">
                                        Seleccione
                                    </option>
                                    <option value="8">Texto</option>
                                    <option value="1">Número</option>
                                    <option value="2">Decimal</option>
                                    <option value="7">Correo Electrónico</option>
                                    <option value="3">Fecha</option>
                                    <option value="4">Hora</option>
                                    <option value="5">Fecha-Hora</option>
                                    <option value="6">Archivo</option>
                                </select>
                            </div>
                            {
                                parseInt(selectedCode.type_fields_id) === 6 &&
                                (
                                    <div className="col mb-3">
                                        <label>¿Qué tipo de archivo es?<b><i className="text-red">*</i></b></label>
                                        <select
                                            className="form-select mt-1"
                                            name="type_files_id"
                                            value={selectedCode.type_files_id}
                                            onChange={setData}
                                        >
                                            <option value="">
                                                Seleccione
                                            </option>
                                            <option value="1">Documento</option>
                                            <option value="2">Comprimido</option>
                                            <option value="3">Imagen</option>
                                            <option value="4">Video</option>
                                            <option value="5">Audio</option>
                                        </select>
                                    </div>
                                )
                            }
                        </div>

                        <div className='row'>
                            <div className='col mb-3'>
                                <label>Valor<b><i className="text-red">*</i></b></label>
                                {showInput(selectedCode.type_fields_id)}
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedCode.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalGlobalCode;