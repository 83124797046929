import { useDispatch, useSelector } from 'react-redux';

import FieldType from '../../../app/components/fields/FieldType';

import { FIELD_TABLE, ID_NOT_DEFINED } from '../../../constant/constant';
import { reducerFormSelectedActor } from '../../../reducers/forms/reducerCreateProcessForm';

const ClusterActor = () => {
    const { selectedForm } = useSelector(state => state.formSlice);
    const { selectedActor } = useSelector(state => state.createProcessFormSlice);
    const dispatch = useDispatch();

    return (
        <>
            {
                selectedForm.actors?.length === 0 && selectedActor.actor_form_id !== ID_NOT_DEFINED
                    ?
                    <div className="alert alert-warning text-center" role="alert" style={{ marginBottom: '0px', margin: '25px 12px' }}>
                        ¡No hay actores para mostrar, selecciona un tipo de actor para generar el formulario!
                    </div>
                    :
                    <div className="container-fluid m-0 p-0 rounded-4">
                        {
                            selectedForm.actors.find(actor => actor.id === selectedActor.actor_form_id)?.parameterizations?.length === undefined
                                ?
                                <div className="alert alert-warning text-center" role="alert" style={{ marginBottom: '0px', margin: '20px 12px' }}>
                                    Por favor, establece la <i className='fa-solid fa-user-tag text-purple'></i> etiqueta con la que se identifica la persona
                                </div>
                                :
                                <div className="row m-0 p-0">
                                    {
                                        selectedForm.actors.find(actor => actor.id === selectedActor.actor_form_id).parameterizations?.map((param, index) => (
                                            <FieldType
                                                key={index}
                                                param={param}
                                                location=""
                                                className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3"
                                                value={param.type_fields_id !== FIELD_TABLE ? (selectedActor.data[param.name]?.value ?? "") : (selectedActor.data[param.name]?.table ?? [{}])}
                                                onChange={(value) => {
                                                    dispatch(reducerFormSelectedActor({
                                                        key: param.name,
                                                        value,
                                                    }));
                                                }}
                                            />
                                        ))
                                    }
                                </div>
                        }
                    </div>

            }
        </>
    );
}

export default ClusterActor;
