import { MessageError } from "../../../utils/message";
import { cutName } from "../../DocumentsAuto/utils/functions";

import GeneralService from "../../../services/GeneralService";

const CardFavorite = ({ field, parent_id, used_for, type_model, is_category, onChanged = (value)=>{} }) => {
    const selected = field.state;

    const markFavorite = async () => {
        const service = new GeneralService("favorite-code");
        if (selected) {
            const res = await service.delete(field.favorite_id, { type_model });
            if (res.is_ok) {
                onChanged({ id: field.id, value: false, favorite_id: 0, is_category });
            } else {
                MessageError("No fue posible eliminar el favorito.");
            }
        } else {
            const data = {
                parameterization_id: field.id,
                parent_id,
                is_category,
                type_model,
                used_for,
            };
            const res = await service.post(data);
            if (res.is_ok) {
                onChanged({ id: field.id, value: true, favorite_id: res.code.id, is_category });
            } else {
                MessageError("No fue posible añadir el favorito.");
            }
        }
    }

    return (
        <div
            className={`col m-2 p-2 card-favorite ${selected ? "card-favorite-selected" : ""}`}
            title={field.name}
            onClick={markFavorite}>
            <div className={`row ${!field.category_peoples_id && "mb-2"}`}>
                <div className="col-2">
                    <input
                        className={`form-check-input ${selected ? "bg-success" : ""}`}
                        type="checkbox"
                        checked={selected}
                        onChange={markFavorite}
                    />
                </div>
                <div className="col-10">
                    <div className="row text-capitalize fw-bold fs-7">
                        {cutName(field?.name, 20)}
                    </div>
                    <div className="row">
                        <span className="tiny ps-0 pe-1 text-capitalize">{field.cluster}</span>
                    </div>
                </div>
            </div>
            {
                is_category &&
                <div className="row" style={{ backgroundColor: "orange", padding: "1px", margin: "5px" }}>
                </div>
            }
        </div>
    );
}

export default CardFavorite;