import { createSlice } from "@reduxjs/toolkit";

export const movementsSlice = createSlice({
    name: "movementsSlice",
    initialState: {
        selectedMovement: {
            id: 0,
            name: '',
            description: '',
            wait_time_days: '',
            expedients_id: 0,
            generate_document_id: 0,
            colors_id: 0,
            documents_auto_id: 0,
            user_id: 0,
        },
        list: [],
        listFilter: [],
        listFilesStorage: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, action) => {
            state.selectedMovement[action.payload.key] = action.payload.value;
        },
        reducerFormSearch: (state, action) => {
            if (action.payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromMovement: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload);
        },
        updateFromMovement: (state, action) => {
            const code = action.payload;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromMovement: (state, action) => {
            state.list.push(action.payload);
            state.listFilter.push(action.payload);
        },
        setSelected: (state, action) => {
            state.selectedMovement = action.payload;
        },
        orderMovement: (state, action) => {
            const { id, order } = action.payload.value;
            const currentIndex = state.listFilter.findIndex(e => e.id === id);
            const element = state.listFilter[currentIndex];

            state.listFilter.splice(currentIndex, 1);
            state.listFilter.splice(order, 0, element);
        },
        setListFilesStorage: (state, { payload }) => {
            state.listFilesStorage = payload;
        },
        deleteFileFromStorage: (state, { payload }) => {
            state.listFilesStorage = state.listFilesStorage.filter(e => e.id !== payload);
        },
        cleanData: (state, action) => {
            state.selectedMovement = {
                id: 0,
                people_id: 0,
                character: '',
                observation: '',
                expedients_id: '',
            };
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromMovement,
    updateFromMovement,
    insertFromMovement,
    setSelected,
    orderMovement,
    setListFilesStorage,
    deleteFileFromStorage,
    cleanData,
} = movementsSlice.actions;

export default movementsSlice.reducer;