
import { createSlice } from '@reduxjs/toolkit';

export const rolesSlice = createSlice({
  name: 'rolesSlice',
  initialState: {
    selectedRole: {
      id: 0,
      name: "",
    },
    list: [],
    listFilter: [],
    isLoading: true,
    filterLevel: []
  },
  reducers: {
    reducerForm: (state, { payload }) => {
      const { key, value } = payload;
      state.selectedRole[key] = value;
    },
    setRole: (state, { payload }) => {
      state.list = payload;
      state.listFilter = payload;
    },
    deleteRole: (state, action) => {
      const newList = state.list.filter(e => e.id !== action.payload.value)
      state.list = newList;
      state.listFilter = newList;
    },
    insertFromRole: (state, { payload }) => {
      state.list.push(payload);
      state.listFilter.push(payload);
      state.selectedRole = payload;
    },
    setSelectedRole: (state, { payload }) => {
      state.selectedRole = payload;
    },
    updateFromRole: (state, action) => {
      state.list = state.list.map((e) => {
        if (e.id === state.selectedRole.id) return state.selectedRole;
        return e;
      });
      state.listFilter = state.listFilter.map((e) => {
        if (e.id === state.selectedRole.id) return state.selectedRole;
        return e;
      });
    },
    searchRole: (state, { payload }) => {
      if (payload === "") {
        state.listFilter = state.list;
        return;
      }
      const filtered = state.list.filter(e => e.name.toLowerCase().includes(payload.toLowerCase()))
      state.listFilter = filtered;
    },
    cleanData: (state, action) => {
      state.selectedRole = {
        id: 0,
        name: "",
      }
    }
  }
})


export const {
  setRole,
  deleteRole,
  setData,
  insertFromRole,
  setSelectedRole,
  updateFromRole,
  searchRole,
  reducerForm,
  cleanData,
} = rolesSlice.actions;
export default rolesSlice.reducer;

