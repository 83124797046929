//Importaciones

import Compressor from "compressorjs";


//Validar nombres 
export const validateName = (data) => {
    const nameOrSurnameRegex = /^[a-zA-ZáéíóúáéíóúÁÉÍÓÚ\u00f1\u00d1\s]{3,50}$/;
    return nameOrSurnameRegex.test(data);
}

//Validar apellidos
export const validateSurname = (data) => {
    const nameOrSurnameRegex = /^[a-zA-ZáéíóúáéíóúÁÉÍÓÚ\u00f1\u00d1\s]{3,50}$/;
    return data.match(nameOrSurnameRegex);
}


//Validar email
export const validateEmail = async (data) => {
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/;
    return data.match(emailRegex);
}

//Validar fecha (aaaa/mm/dd)
export const validateDateBirth = async (data) => {
    const dateRegex = /^\d{4}([-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/;
    return data.match(dateRegex);
}

export const encodeFileBase64 = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64 = reader.result.split(',')[1]
            resolve(base64);
        }
        reader.onerror = (error) => {
            resolve(null);
        }
    });
}

export const compressImage = (file) => {
    return new Promise((resolve) => {
        if (!(file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg")) resolve(null);
        if (file.size >= 10000000) resolve(null);

        new Compressor(file, {
            quality: 0.6,
            convertSize: 300000,
            success(result) {
                resolve(result);
            }
        });
    });
}

export const isURL = (url) => {
    try {
        new URL(url);
        return true;
    } catch (err) {
        return false;
    }
}