import { ExpedientGroups } from "./components/ExpedientGroups";
import { NotificationExpedient } from "./components/NotificationExpedient";

export const TabExpedients = () => {
    return (
        <div className="animate__animated animate__fadeIn">
            <NotificationExpedient />
            <hr />
            <ExpedientGroups />
        </div >
    );
}