import axios from "../../../../../../../utils/axios";
const typepeopleCategoryGroupsParameterize = {};
const path = 'parameterization-category-peoples';

typepeopleCategoryGroupsParameterize.save = async (data) => {
    try {
        const response = await axios.post(path, data)
        return response.data;
    } catch (error) {
        return error;
    }
}

typepeopleCategoryGroupsParameterize.update = async (data) => {
    try {
        const urlUpdate = `${path}/${data.id}`;
        const response = await axios.put(urlUpdate, data)
        return response.data;
    } catch (error) {
        return error;
    }
}

typepeopleCategoryGroupsParameterize.delete = async (id) => {
    try {
        const urlDelete = `${path}/${id}`;
        const response = await axios.delete(urlDelete)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

typepeopleCategoryGroupsParameterize.getById = async (id) => {
    try {
        const urlGet = `${path}/${id}`;
        const response = await axios.get(urlGet)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

typepeopleCategoryGroupsParameterize.order = async (data) => {
    try {
        const response = await axios.post(`parameterization-type-peoples/order`, data);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export default typepeopleCategoryGroupsParameterize;