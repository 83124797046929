import { useEffect } from 'react';
import axios from 'axios';
import GeneralService from '../../services/GeneralService';
import { BAD_REQUEST } from '../../constant/constant';

export const LogVisitor = () => {

    const logVisit = async () => {
        const res = await axios.get("https://api.ipify.org?format=json");
        const ip = res.data?.ip;

        const service = new GeneralService("log-visitor");
        const userAgent = navigator.userAgent;
        const url = window.location.href;

        await service.post({ ip, user_agent: userAgent, url, is_sortom: !url.includes("virtual-notary") });
        if (service.status === BAD_REQUEST) {
            console.log("problemas para registrar las visitas");
        }
    };

    useEffect(() => {
        logVisit();
    }, []);

    return null;
};