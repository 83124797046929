import GeneralService from "../../services/GeneralService";
import { addMessageToChat, reducerMessage, setChat, setDocument, setIsLoading, setPrompt } from "./reducerChatGpt";
import { HTTP_CREATED } from "../../constant/constant";
import { MessageError } from "../../utils/message";

export const registerPromptAndCodes = (textCodes, prompt) => {
    return async (dispatch) => {
        // Register Prompt
        const servicePrompt = new GeneralService("gpt-sortom/prompt");
        const resPrompt = await servicePrompt.post({ nombre: "Instrucciones generales " + new Date().toISOString(), texto: prompt });
        if (servicePrompt.status !== HTTP_CREATED) {
            MessageError("Error al subir el prompt");
            return;
        }
        dispatch(setPrompt(resPrompt));

        // Register document
        const serviceDocument = new GeneralService("gpt-sortom/document");
        const resDocument = await serviceDocument.post({ nombre: "Listado de códigos " + new Date().toISOString(), texto: textCodes });
        if (serviceDocument.status !== HTTP_CREATED) {
            MessageError("Error al subir el documento");
            return;
        }
        dispatch(setDocument(resDocument));

        // Register chat
        const serviceChat = new GeneralService("gpt-sortom/chat");
        const res = await serviceChat.post({ prompt_ref: resPrompt._id, document_ref: resDocument._id });
        if (serviceChat.status !== HTTP_CREATED) {
            MessageError("Error al crear el chat");
            return;
        }
        dispatch(setChat(res));
    }
}

export const startSendMessage = (idChat, content) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        dispatch(reducerMessage(""));
        dispatch(addMessageToChat({ content, _id: new Date(), role: "user" }));

        const service = new GeneralService(`gpt-sortom/chat/${idChat}/sendMessage`);
        const res = await service.post({ content });
        if (service.status === HTTP_CREATED) {
            dispatch(setChat(res.chat));
        }

        dispatch(setIsLoading(false));
    }
}