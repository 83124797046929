import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import GeneralService from "../../../../services/GeneralService";
import { setListDocuments } from "../../../../reducers/documents/reducerGenerateDocument";
import ModalFilterByPerson from "../../../TercerosCreate/components/ModalFilterByPerson";
import { clearFilter, reducerForm } from "../../../../reducers/people/reducerPersonDocument";

const ToolFilterDocuments = ({ isPerPerson = false }) => {
    const { dataFilter } = useSelector(state => state.personDocumentSlice);
    const { listDocuments } = useSelector(state => state.documentsAutoSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const loadTemplates = async () => {
        const service = new GeneralService("documents-auto");
        const { documents = [] } = await service.getList(1000);
        dispatch(setListDocuments({ value: documents }));
    }

    useEffect(() => {
        loadTemplates();
    }, []);

    return (
        <>
            <div className="row px-2 py-3 animate__animated animate__fadeInDown">

                <div className="col">
                    <label>Tipo de documento</label>
                    <select
                        className="form-select"
                        name="type_document"
                        value={dataFilter.type_document}
                        onChange={setData}
                    >
                        <option value="0">
                            Selecciona...
                        </option>
                        {
                            listDocuments.map(document => <option value={document.id} key={document.id}>
                                {document.name}
                            </option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>Fecha</label>
                    <input
                        type="date"
                        className="form-control"
                        name="created_at"
                        placeholder="Digita una fecha"
                        value={dataFilter.created_at}
                        onChange={setData}
                    />
                </div>
                <div className="col">
                    <label>Estado</label>
                    <select
                        className="form-select"
                        name="status"
                        value={dataFilter.status}
                        onChange={setData}
                    >
                        <option value="">
                            Selecciona el estado
                        </option>
                        <option value="in_progress">
                            EN PROGRESO
                        </option>
                        <option value="exported">
                            PDF GENERADO
                        </option>
                    </select>
                </div>
                {
                    !isPerPerson && <div className="col">
                        <button title="Filtrar por personas" className="btn btn-success mt-4 ms-auto" type="button" onClick={() => setShow(true)}>
                            <i className="fa-solid fa-person-rays text-white me-2" style={{ fontSize: '16px' }} />
                            Filtrar personas
                        </button>
                        <button title="Quitar filtros" className="btn btn-success rounded-pill px- py-1 mx-1 mt-4" type="button" onClick={() => dispatch(clearFilter())}>
                            <i className="fa-solid fa-filter-circle-xmark text-white"></i>
                        </button>
                    </div>
                }

            </div>
            <ModalFilterByPerson show={show} setShow={setShow} />
        </>
    )
}

export default ToolFilterDocuments;