import axios from "../../../utils/axios"
const people = {};
const path = "people";


people.getList = async () => {
    try {
        const urlList = `${path}?offset=${1000}`;
        const response = await axios.get(urlList);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

people.save = async (data) => {
    try {
        const res = await axios.post(path, data);
        return res.data;
    } catch (e) {
        return e.response.data;
    }
}

people.getByID = async (id) => {
    try {
        const urlGet = `${path}/${id}`;
        const res = await axios.get(urlGet);
        return res.data;
    } catch (e) {
        return e.response.data;
    }
}

people.update = async (data) => {
    try {
        const urlUpdate = `${path}/${data.id}`;
        const res = await axios.put(urlUpdate, data);
        return res.data;
    } catch (e) {
        return e.response.data;
    }
}

people.delete = async (id) => {
    try {
        const urlDelete = `${path}/${id}`;
        const response = await axios.delete(urlDelete);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

people.findPeople = async (data) => {
    try {
        const url = `${path}/find-people`;
        const response = await axios.post(url, data);
        return response;
    } catch (e) {
        return e.response;
    }
}

export default people;