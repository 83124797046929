import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import IconButton from '../../../../app/components/IconButton';
import CardActorViewSignature from '../../../TercerosCreate/components/CardActorViewSignature';

import { formatDateTime } from '../../../../utils/formatDate';
import { isURL } from '../../../../utils/isUrl';
import { toast } from '../../../../utils/toast';
import { ALERT_WARNING } from '../../../../constant/constant';
import { useDownloadFile } from '../../../../app/hooks/useDownloadFile';

const ListGeneratedDocuments = ({ isPerPerson = false }) => {
    const { elatedDocumentsFiltered } = useSelector(state => state.personDocumentSlice);
    
    return (
        <>
            <div className="row">
                {
                    elatedDocumentsFiltered.length === 0
                        ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay documentos que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${elatedDocumentsFiltered.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Tipo de documento</th>
                                        <th>Fecha</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        elatedDocumentsFiltered.map((document, i) => (<RowDocument
                                            key={document.id}
                                            generatedDocument={document}
                                            index={i}
                                            isPerPerson={isPerPerson}
                                        />))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    )
}

const RowDocument = ({ generatedDocument, index }) => {
    const [show, setShow] = useState(false);
    const handleDownload = useDownloadFile();
    const history = useHistory();
    const dispatch = useDispatch();
    const rowRef = useRef(null);
    
    const downloadDocument = () => {
        if (isURL(generatedDocument.url_pdf)) {
            handleDownload(generatedDocument.url_pdf);
            return;
        }
        toast(dispatch, "El documento no ha sido generado", ALERT_WARNING);
    }
    
    const popover = (
        <Popover style={{ minWidth: "25rem", maxHeight: "25rem", overflow: "scroll", overflowX: "hidden" }}>
            <Popover.Header className="bg-purple text-white text-center" as="h3">Actores del documento</Popover.Header>
            <Popover.Body>
                <div className="container">
                    {
                        generatedDocument.actors?.map(actor => <CardActorViewSignature
                            key={actor.id}
                            actor={actor}                            
                            label={actor.label?.name}
                        />)
                    }
                </div>
            </Popover.Body>
        </Popover>
    );

    const handleRowClick = (e) => {
        if (rowRef.current && !rowRef.current.contains(e.target)) {
            setShow(false);
        }
    };

    const handleEditDocument = () => {
        history.push("/documents-auto/generate/" + generatedDocument.documents_auto_id + "?process=" + generatedDocument.id);
    }

    useEffect(() => {
        document.addEventListener("click", handleRowClick);
        return () => {
            document.removeEventListener("click", handleRowClick);
        };
    }, []);

    return (
        <tr ref={rowRef}>
            <td></td>
            <td>{index + 1}.</td>
            <td style={{ maxWidth: "300px" }}>{generatedDocument.documents_auto.name}</td>
            <td>{formatDateTime(generatedDocument.created_at)}</td>
            <td>
                {
                    generatedDocument.state_generated
                        ?
                        <span className='rounded-pill bg-green-dark px-2 py-1 text-white fs-7'>PDF generado</span>
                        :
                        <span className='rounded-pill bg-purple px-2 py-1 text-white fs-7'>En progreso</span>
                }
            </td>
            <td>
                <OverlayTrigger placement="left" overlay={popover} show={show}>
                    <button title="Ver actores del documento" className='bg-icon_button' onClick={() => setShow(!show)} style={{ border: "none" }}>
                        <i className="fa-solid fa-person-rays text-purple"></i>
                    </button>
                </OverlayTrigger>
                <IconButton
                    icon="fa-solid fa-file-arrow-down text-green"
                    title="Descargar documento original"
                    onClick={downloadDocument}
                />
                <IconButton
                    icon="fa-solid fa-file-pen text-orange"
                    title="Continuar editando documento"
                    onClick={handleEditDocument}
                />
            </td>
            <td></td>
        </tr>
    )
}

export default ListGeneratedDocuments;