
import '../styles/folder.css';

export const FolderIcon = ({ height = 22, width = 30, color }) => {
    return (
        <div className="folder-icon" style={{ width, height }}>
            <div className="back" style={{ backgroundColor: "#" + color }}></div>
            <div className="front" style={{ backgroundColor: "#" + color }}></div>
        </div>
    )
}
