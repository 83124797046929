import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import IconButton from "../../../app/components/IconButton";

import { setSelectedCase } from "../../../reducers/legalCase/reducerLegalCase";
import { setRefreshTable } from "../../../reducers/createCase/reducerCreateCase";
import confirmDelete from "../../../utils/confirmDelete";
import { MessageError, MessageSuccess } from "../../../utils/message";
import GeneralService from "../../../services/GeneralService";
import { useLoadFavoriteCase } from "../../Configuration/hooks/useLoadFavoriteCase";

const DynamicTable = ({ actionName = "", forParameterization = true, onSelected }) => {
    const { list, selectedCase } = useSelector(state => state.legalCaseSlice);
    const { dataLegalCase, listFoundCase, fieldsTable } = useSelector(state => state.createCaseSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    useLoadFavoriteCase();

    const filtered = fieldsTable.filter(item => item.state);

    const deleteCase = async (id) => {
        const service = new GeneralService("create-case");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(setRefreshTable());
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        dispatch(setSelectedCase({ value: list.find(item => item.id === parseInt(dataLegalCase.legal_case_id)) }));
    }, [dataLegalCase.legal_case_id]);

    return (
        <div className="col-12 table-data">
            <div className="order">
                {
                    parseInt(dataLegalCase.legal_case_id) === 0 || filtered.length === 0 ?
                        <div className="alert alert-warning text-center" role="alert">
                            No hay casos que mostrar o no hay columnas favoritas de la tabla
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFoundCase.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        {
                                            filtered.map((item, i) => {
                                                if (i === 0) return (
                                                    <Fragment key={item.id}>
                                                        <th>
                                                        </th>
                                                        <th key="zero">
                                                            #
                                                        </th>
                                                        <th>
                                                            {item.name}
                                                        </th>
                                                    </Fragment>)
                                                return (
                                                    <th key={item.id}>
                                                        {item.name}
                                                    </th>
                                                )
                                            })
                                        }
                                        <th>
                                            {actionName}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFoundCase.map((item, i) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td>
                                                    {i + 1}.
                                                </td>
                                                {
                                                    item.filtered.map(data => (<td key={data.id}>
                                                        {data.value}
                                                    </td>))
                                                }
                                                <td>
                                                    {
                                                        forParameterization ?
                                                            <>
                                                                <IconButton
                                                                    icon="fa-solid fa-pen-to-square text-green"
                                                                    title="Editar caso"
                                                                    onClick={() => history.push(`/case/update/${item.id}`)}
                                                                />
                                                                <IconButton
                                                                    icon="fa-solid fa-trash-can text-red"
                                                                    title="Eliminar caso"
                                                                    onClick={async () => await confirmDelete(() => deleteCase(item.id))}
                                                                />
                                                            </>
                                                            :
                                                            <button title="Agregar caso" className="btn btn-success btn-sm m-1 d-block mx-auto"
                                                                type="button"
                                                                onClick={() => onSelected({ ...item, type_people: selectedCase })}
                                                            >
                                                                <i className="fa-solid fa-plug"></i>
                                                            </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </div>
    )
};

export default DynamicTable;