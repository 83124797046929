import { useDownloadImageAsBase64 } from "../../../app/hooks/useDownloadImageAsBase64";
import { isURL } from "../../../utils/isUrl";

const ChatItem = ({ name, urlAvatar, onClick }) => {
    const { file } = useDownloadImageAsBase64(urlAvatar);

    return (
        <div className="recent__chat" onClick={onClick}>
            <div>
                {
                    isURL(urlAvatar)
                        ?
                        <img className="avatar" src={file} alt="Avatar" />
                        :
                        <i className="fa-solid fa-user avatar"></i>
                }
            </div>
            <div className="information__chat">
                <div className="col-8 fw-bold fs-7 text-start">{name}</div>
                <div className="col-4 text-end">

                </div>
            </div>
        </div>
    )
}

export default ChatItem;