
const ItemUtilityCodeCategory = ({ name, icon = "fa-bolt", active, onClick, className }) => {
    return (
        <div title={name} className={`row ${className}`} onClick={onClick} role="button">
            <div className="col-3">
                <div className={`d-block rounded-pill category-code__config ${active ? "bg-purple" : "bg-secondary"}`}>
                    <i className={`fa-solid ${icon} text-white tiny ms-2`}></i>
                </div>
            </div>
            <div className="col fw-bold text-truncate">
                {name}
            </div>
        </div>
    );
}

export default ItemUtilityCodeCategory;