import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import ListParameterizationForm from './ListParameterizationForm';
import SearchParameterizationLegalCase from './SearchParameterizationForm';
import { cleanData, setListParameterization } from "../../../../../reducers/forms/reducerParameterizationForm";
import { setSelectedGroup } from "../../../../../reducers/forms/reducerClusterForm";
import { setSelectedForm } from "../../../../../reducers/forms/reducerFormSlice";

import BreadcrumbCustom from "../../../../../app/components/BreadcrumCustom";
import GeneralService from "../../../../../services/GeneralService";

const ParameterizationForm = () => {
    const { list, selectedForm } = useSelector(state => state.formSlice);
    const { selectedGroup } = useSelector(state => state.clusterFormSlice);
    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { form = 0, cluster = 0 } = queryString.parse(search);

    const loadData = async (id) => {
        dispatch(setSelectedForm({ value: list.find(i => i.id === parseInt(form)) }));
        
        const service = new GeneralService("cluster-form/"+id);
        const res = await service.getRequest();
        if (res.name) {
            dispatch(setSelectedGroup({ value: res }));
            const { parameterizations = [] } = res;
            dispatch(setListParameterization({ value: parameterizations }));
        } else {
            history.push("/form-parameterization/cluster");
        }
    }

    useEffect(() => {
        loadData(cluster);
    }, [cluster, form, list]);

    const handleCreate = () => {
        dispatch(cleanData());
        history.push({
            pathname: "/form-parameterization/fields/create",
            search: `?form=${form}&cluster=${cluster}`,
        });
    }

    return (
        <div className='mx-4'>
            <BreadcrumbCustom
                title="Campos"
                routes={[
                    { name: "Tipos de formulario", link: "/form-parameterization/list" },
                    { name: selectedForm.name ? selectedForm.name : "Seleccione tipo de formulario", link: "/form-parameterization/list" },
                    { name: selectedGroup.name ? selectedGroup.name : "Grupo no seleccionado", link: `/form-parameterization/cluster?form=${selectedForm.id}` },
                ]}
                activeRoute="Listar campos"
            />

            <div className='container m-0 mt-2 p-0'>
                <div className='row'>
                    <div className='col-6 p-0'>
                        <SearchParameterizationLegalCase />
                    </div>
                    <div className='col-6 p-0'>
                        <button title="Crear nuevo campo" className="btn btn-success" type="button" onClick={handleCreate}>
                            <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                            Crear campo
                        </button>
                    </div>
                </div>
            </div>
            <div className="table-data" >
                <ListParameterizationForm />
            </div>
        </div>
    )
}

export default ParameterizationForm;