import { createSlice } from '@reduxjs/toolkit';

export const typepeopleCategoryGroupSlice = createSlice({
  name: 'typepeopleCategoryGroup',
  initialState: {
    dataTypepeopleCategoryGroup: {
      category_peoples_id: null,
      name: ""
    },
    list: [],
    listFilter: [],
    isLoading: true,

    selectedGroup: {
      id: 0,
      name: "",
    },
  },
  reducers: {
    reducerForm: (state, action) => {
      const { key, value } = action.payload;
      state.selectedGroup = {
        ...state.selectedGroup,
        [key]: value
      };
    },
    searchGroup: (state, action) => {
      if (action.payload.value === "") {
        state.listFilter = state.list;
        return;
      }
      const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
      state.listFilter = filtered;
    },
    setGroupCategory: (state, action) => {
      state.list = action.payload.value
      state.isLoading = false
    },
    setListGroups: (state, action) => {
      state.list = action.payload.value;
      state.listFilter = action.payload.value;
      state.isLoading = false
    },
    setData: (state, action) => {
      state.dataTypepeopleCategoryGroup = action.payload.value
    },
    deleteGroupCategory: (state, action) => {
      const newList = state.list.filter(e => e.id !== action.payload.value)
      state.list = newList;
      state.listFilter = newList;
    },
    //Para editar el grupo en Modal
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload.value;
    },
    setEditSelectedGroup: (state, action) => {
      state.selectedGroup[action.payload.key] = action.payload.value;
    },
    //Agregar el objeto creado a la lista
    addNewObjectToList: (state, action) => {
      state.list.push(action.payload.value);
      state.listFilter.push(action.payload.value);
    },
    // Actualizar los campos después de editar
    updateNewObjectAfterEdit: (state, action) => {
      const group = action.payload.value
      const modificadaList = state.list.map((e) => {
        if (e.id === group.id) {
          return group
        }
        return e;
      });
      state.list = modificadaList;
      state.listFilter = modificadaList;
    },
    cleanData: (state, action) => {
      state.selectedGroup = {
        id: 0,
        name: "",
      };
    },
  },
})

export const {
  reducerForm,
  searchGroup,
  cleanData,
  setGroupCategory,
  setListGroups,
  setData,
  deleteGroupCategory,
  setSelectedGroup,
  setEditSelectedGroup,
  addNewObjectToList,
  updateNewObjectAfterEdit,
} = typepeopleCategoryGroupSlice.actions

export default typepeopleCategoryGroupSlice.reducer