import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import IconButton from "../../../app/components/IconButton";

import { setSelectedTypepeople } from "../../../reducers/typePeople/reducerTypepeople";
import { setRefreshTable } from "../../../reducers/people/reducerPeople";
import confirmDelete from "../../../utils/confirmDelete";
import { MessageError, MessageSuccess } from "../../../utils/message";
import GeneralService from "../../../services/GeneralService";
import { useLoadFavoritePeople } from "../../Configuration/hooks/useLoadFavoritePeople";

const DynamicTable = ({ actionName = "", forParameterization = true, onPersonSelected }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { list, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const { dataTypePeole, listFoundPeople, fieldsPeopleTable } = useSelector(state => state.peopleSlice);
    useLoadFavoritePeople();

    const filtered = fieldsPeopleTable.filter(item => item.state);

    const deletePeople = async (id) => {
        const service = new GeneralService("people");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess("La persona ha sido eliminada exitosamente!");
            dispatch(setRefreshTable());
            return;
        }
        MessageError("No fue posible eliminar la persona.");
    }

    useEffect(() => {
        dispatch(setSelectedTypepeople({ value: list.find(item => item.id === parseInt(dataTypePeole.type_people_id)) }));
    }, [dataTypePeole.type_people_id]);

    return (
        <div className="col-12 table-data">
            <div className="order">
                {
                    parseInt(dataTypePeole.type_people_id) === 0 || filtered.length === 0 ?
                        <div className="alert alert-warning text-center" role="alert">
                            No hay tipos de personas que mostrar o no hay columnas favoritas de la tabla
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFoundPeople.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr className="font-lato">
                                        {
                                            filtered.map((item, i) => {
                                                if (i === 0) return (
                                                    <Fragment key={item.id}>
                                                        <th>
                                                        </th>
                                                        <th key="zero">
                                                            #
                                                        </th>
                                                        <th className="fs-6">
                                                            {item.name}
                                                        </th>
                                                    </Fragment>)
                                                return (
                                                    <th className="fs-6" key={item.id}>
                                                        {item.name}
                                                    </th>
                                                )
                                            })
                                        }
                                        <th className="fs-6">
                                            {actionName}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFoundPeople.map((item, i) => (
                                            <tr key={item.id} className="font-lato" onDoubleClick={() => onPersonSelected({ ...item, type_people: selectedTypepeople })}>
                                                <td></td>
                                                <td>
                                                    {i + 1}.
                                                </td>
                                                {
                                                    item.filtered.map(data => (<td key={data.id}>
                                                        {data.value}
                                                    </td>))
                                                }
                                                <td>
                                                    {
                                                        forParameterization ?
                                                            <>
                                                                <IconButton
                                                                    icon="fa-solid fa-pen-to-square text-green"
                                                                    title="Editar persona"
                                                                    onClick={() => history.push(`/people/edit/${item.id}`)}
                                                                />
                                                                <IconButton
                                                                    icon="fa-solid fa-trash-can text-red"
                                                                    title="Eliminar persona"
                                                                    onClick={async () => await confirmDelete(() => deletePeople(item.id))}
                                                                />
                                                            </>
                                                            :
                                                            <button title="Agregar tipo persona" className="btn btn-success btn-sm m-1 d-block mx-auto"
                                                                type="button"
                                                                onClick={() => onPersonSelected({ ...item, type_people: selectedTypepeople })}
                                                            >
                                                                <i className="fa-solid fa-plug"></i>
                                                            </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </div>
    )
};

export default DynamicTable;