import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, push, ref, update } from "firebase/database";
import data from '@emoji-mart/data';
import EmojiPicker from "@emoji-mart/react";

import { setMessage, setSelectedChat } from "../../../reducers/chat/reducerChatSlice";
import { FirebaseDB } from "../../../firebase/config/firebase-config";
import { toast } from "../../../utils/toast";
import { ALERT_DANGER } from "../../../constant/constant";

export const FormSendMessage = () => {
    const { nick_name } = useSelector(state => state.loginSlice.profile);
    const { company } = useSelector(state => state.businessSlice);
    const { selectedChat, message } = useSelector(state => state.businessChatSlice);
    const fileRef = useRef(null);
    const textareaRef = useRef(null);
    const dispatch = useDispatch();
    const [showEmojis, setShowEmojis] = useState(false);
    const buttonEmojiPickerRef = useRef(null);
    const emojiPickerRef = useRef(null);

    const setData = (e) => {
        const textarea = textareaRef.current;
        if (textarea.scrollHeight > 160) return;
        textarea.style.height = '25px';
        textarea.style.height = `${textarea.scrollHeight}px`;

        dispatch(setMessage(e.target.value));
    }

    const onEmojiSelect = ({ native }) => {
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = message.slice(0, start) + native + message.slice(end);

        setTimeout(() => {
            textarea.selectionStart = textarea.selectionEnd = start + native.length;
            textarea.focus();
        }, 0);
        dispatch(setMessage(newText));
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) return;

            event.preventDefault();
            handleMessage();
        }
    };

    const handleMessage = async (e) => {
        if (e) e.preventDefault();
        if (!message.trim()) return;

        const newMessage = {
            content: message.trim(),
            sender: nick_name,
            timestamp: Date.now(),
            read: false,
            received: false,
        };

        if (!selectedChat.messages) {
            const newChat = {
                participants: selectedChat.participants,
                created_at: Date.now(),
                updated_at: Date.now(),
            };

            const newChatRef = push(ref(FirebaseDB, `businesses/business_id_${company.id}/chats`), newChat);

            get(newChatRef)
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const chatData = snapshot.val();
                        const chatId = newChatRef.key;

                        const newChatWithId = { chatId, ...chatData };
                        dispatch(setSelectedChat(newChatWithId));

                        addMessage(newMessage, company.id, chatId);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast(dispatch, "Error al obtener los datos del nuevo chat", ALERT_DANGER);
                });
        } else {
            addMessage(newMessage, company.id, selectedChat.chatId);
        }

        const textarea = textareaRef.current;
        textarea.style.height = '25px';
    }

    const addMessage = async (message, companyId, chatId) => {
        push(ref(FirebaseDB, `businesses/business_id_${companyId}/chats/${chatId}/messages`), message)
            .then(() => {
                dispatch(setMessage(""));
            })
            .catch((error) => {
                console.log(error);
                toast(dispatch, "Error al enviar el mensaje", ALERT_DANGER);
            });

        await update(ref(FirebaseDB, `businesses/business_id_${companyId}/chats/${chatId}`), {
            updated_at: Date.now()
        })
    }

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const handleDocumentClick = (e) => {
        if (buttonEmojiPickerRef.current && emojiPickerRef.current) {
            if (!buttonEmojiPickerRef.current.contains(e.target) && !emojiPickerRef.current.contains(e.target)) {
                setShowEmojis(false);
            }
        }
    };

    return (
        <form className="input-container" onSubmit={handleMessage}>
            {/* <div>
                <button title="" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button"
                    onClick={() => fileRef.current.click()}
                >
                    <i className="fa-solid fa-paperclip text-white"></i>
                </button>
                <input className="d-none" type="file" ref={fileRef} />
            </div> */}

            <div className="text-area__chat">
                <div ref={buttonEmojiPickerRef} className="emoji-picker__chat">
                    <i className="fa-solid fa-face-smile" onClick={() => setShowEmojis(!showEmojis)}></i>
                    {
                        showEmojis
                        &&
                        <div ref={emojiPickerRef} style={{
                            position: 'absolute',
                            top: -450,
                            left: 0,
                            zIndex: 1000,
                            boxShadow: '0 5px 10px rgba(0,0,0,0.2)',
                        }}>
                            <EmojiPicker theme="light" data={data} onEmojiSelect={onEmojiSelect} />
                        </div>
                    }
                </div>
                <textarea
                    ref={textareaRef}
                    className='chat-textarea'
                    placeholder="Escribe un mensaje"
                    value={message}
                    onChange={setData}
                    onKeyDown={handleKeyDown}
                ></textarea>
            </div>
            <div>
                <button title="Enviar" className="btn btn-success rounded-pill px-2 py-1 send" type="submit">
                    <i className="fa-solid fa-paper-plane text-white"></i>
                </button>
            </div>
        </form>
    )
}
