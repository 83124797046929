import { STATUS_SIGNED } from "../../../constant/constant";


const CardActorViewSignature = ({ actor, label = "" }) => {
    return (
        <div className="row p-1 mb-1 rounded-3" style={{ border: "1px solid green" }}>
            <div className="col">
                <div className="row bg-light-purple rounded-top">
                    <div className="col text-purple fs-7">
                        <b>{label}</b>
                    </div>
                    {
                        actor.status === STATUS_SIGNED
                            ?
                            <div className="col text-success fs-7">
                                <i className="fa-solid fa-circle-check"></i> <b>FIRMADO</b>
                            </div>
                            :
                            <div className="col text-danger fs-7">
                                <i className="fa-solid fa-circle-xmark"></i> <b>NO FIRMADO</b>
                            </div>
                    }
                </div>
                <div className="row mt-1">
                    <div className="col fs-7 text-ellipsis">
                        <b>Nombre</b>: {actor.name}
                    </div>
                </div>
                <div className="row">
                    <div className="col fs-7 text-ellipsis">
                        <b>Telefono</b>: {actor.phone}
                    </div>
                </div>
                <div className="row text-ellipsis">
                    <div className="col fs-7 ">
                        <b>Email</b>: {actor.email}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardActorViewSignature;