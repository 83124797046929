import { useState } from "react";

import IconButton from "../../../app/components/IconButton";

const WelcomeMessage = () => {
    const [minimize, setMinimize] = useState(true);

    return (
        <div className="row bg-green rounded-3 mb-2 p-1">
            <div className="col-1 d-none d-sm-flex d-md-flex d-lg-flex abs-center">
                <i className="fa-solid fa-file-pen fs-3 ms-3"></i>
            </div>
            <div className="col-10 p-3">
                <h5 className='font-lilita-one'>
                    {window.location.pathname.includes("/virtual-notary/") ? "Notaría Virtual" : "Formularios empresariales"} <i className="fa-solid fa-arrow-down animate__animated animate__bounce animate__repeat-3 ms-3"></i>
                </h5>
                {
                    minimize &&
                    <p>Diligencia toda la información requerida siguiendo los pasos de forma ordenada. Recueda que tienes cuatro (4) pasos para completar el documento y enviarlo a tus destinatarios <b>¡Que la pases bien!</b> 😊</p>
                }
            </div>
            <div className="col-1 text-end">
                <IconButton
                    title={minimize ? "Minimizar" : "Maximizar"}
                    icon={`fa-solid ${minimize ? "fa-window-minimize" : "fa-maximize"}`}
                    onClick={() => setMinimize(c => !c)}
                />
            </div>
        </div>
    );
}

export default WelcomeMessage;