import { useState } from "react";

import IconButton from "./IconButton";
import "./styles/search.css";

export const SearchBar = ({ value, className = "bg-light-green", placeholder = "Buscar", onChange }) => {
    const [text, setText] = useState("");

    const onSearch = (e) => {
        setText(e.target.value);
        onChange(e);
    }

    const onClean = () => {
        onChange({ target: { value: "" } })
        setText("");
    }

    return (
        <div className={`custom-search p-1 font-lato ${className}`}>
            <i className="fa-solid fa-magnifying-glass ms-2 text-green"></i>
            <input
                type="text"
                name="search"
                value={text}
                className='mt-1 ps-1 input-borderless font-lato'
                placeholder={placeholder}
                autoComplete='off'
                onChange={onSearch}
            />
            {
                text
                &&
                <IconButton
                    icon="fa-solid fa-circle-xmark text-green-light"
                    title="Limpiar búsqueda"
                    onClick={onClean}
                />
            }
        </div>
    )
}
