import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { cleanData, reducerForm } from "../../../reducers/createCase/reducerCreateCase";
import { setSelectedCase } from "../../../reducers/legalCase/reducerLegalCase";

export const SelectAndCreate = ({ showCreateButton = true }) => {
    const { list } = useSelector(state => state.legalCaseSlice);
    const { dataLegalCase } = useSelector(state => state.createCaseSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const setData = async (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleCreate = () => {
        dispatch(cleanData());
        dispatch(setSelectedCase({value: undefined}));
        history.push("/case/create");
    }

    return (
        <div className="container m-0 p-0">
            <div className="row">
                <div className="col-4">
                    <select
                        className="form-select mt-1"
                        name="legal_case_id"
                        value={dataLegalCase.legal_case_id}
                        onChange={setData}
                    >
                        <option value="0">
                            Seleccione tipo de caso
                        </option>
                        {
                            list.map(legalCase => (<option
                                value={legalCase.id}
                                key={legalCase.id}
                            >
                                {legalCase.name}
                            </option>))
                        }
                    </select>
                </div>
                {
                    showCreateButton && <div className="col">
                        <button title="Crear tipo caso" className="btn btn-success mt-1 float-end" type="button" onClick={handleCreate}>
                            <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                            Crear caso
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}
