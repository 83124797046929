import { FIELD_DATETIME, FIELD_FILE } from "../../../constant/constant";
import { MessageConfirm } from "../../../utils/message";

export const validateDataForm = async (objectData) => {
    let listData = [];
    let omit = true;

    for (const key in objectData) {
        if (objectData[key].value === "" && objectData[key].alert_required) {
            omit = await MessageConfirm("Omitir campo", `El campo <b>${objectData[key].name}</b> no es requerido pero puede ser importante para llevar a cabo un buen proceso de identificación y automatización. ¿Estás realmente seguro de omitir este campo?`);
            if (!omit) return { listData: [], omit, isOk: false };
        }

        if (objectData[key].value === "" && !objectData[key].alert_required) {
            return { listData: [], omit: true, isOk: false };
        }

        if (objectData[key].type_fields_id === FIELD_FILE && !objectData[key].doc) {
            continue;
        }

        let value = objectData[key].value;
        switch (objectData[key].type_fields_id) {
            case FIELD_DATETIME:
                value = `${objectData[key].value}:00.000Z`;
                break;
            case FIELD_FILE:
                value = objectData[key].doc;
                break;
            default:
                break;
        }

        listData.push({
            id: objectData[key].id,
            parameterization_id: objectData[key].parameterization_id,
            value, is_category: objectData[key].is_category ?? false });
    }

    return { listData, omit, isOk: true };
}