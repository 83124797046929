import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import GeneralService from "../../../services/GeneralService";
import { setListQuestions } from "../../../reducers/forms/reducerCreateProcessForm";
import { TYPE_ANSWER, TYPE_QUESTION } from "../../../constant/constant";
import Celebration from "../../../assets/celebration.svg";

const DialogsBySteps = ({ onTapCode = (a, t) => { } }) => {
    const { selectedForm } = useSelector(state => state.formSlice);
    const { listQuestions } = useSelector(state => state.createProcessFormSlice);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState({});
    const [currentStep, setCurrentStep] = useState(0);
    const [animate, setAnimate] = useState(true);
    const [showEnd, setShowEnd] = useState(false);
    const wrapperRef = useRef(null);

    const handleAnimate = async () => {
        setAnimate(false);
        setTimeout(() => setAnimate(true), 200);
    }

    const updateStep = (step) => {
        if (step === listQuestions.length) {
            setShowEnd(true);
        }
        if (step >= 0 && step < listQuestions.length) {
            setShowEnd(false);
            setCurrentStep(step);
            setSelected(listQuestions[step]);
            wrapperRef.current.scrollLeft = (wrapperRef.current.scrollWidth / listQuestions.length) * step;
        }
    }

    const loadQuestions = async () => {
        const service = new GeneralService("questions-form");
        const res = await service.getList(1000, { form_id: selectedForm.id });
        const { questions_form = [] } = res;
        dispatch(setListQuestions({ value: questions_form }));
    }

    useEffect(() => {
        loadQuestions();
    }, [selectedForm.id]);

    useEffect(() => {
        if (listQuestions.length > 0) setSelected(listQuestions[0]);
    }, [listQuestions]);

    return (
        <div>
            <div className="container-fluid m-0 p-1 mt-1 rounded-4 people-principal-container">

                <div className="row m-0 stepper">
                    <div style={{ opacity: ".999" }}>
                        <div ref={wrapperRef} className={`stepWrapper ${listQuestions.length > 7 ? "justify-start" : "justify-center"}`}>
                            {
                                listQuestions.map((item, index) =>
                                    <div key={index} className={"stepBlock" + (currentStep === index ? " selected" : "")}>
                                        <div className="circleWrapper" onClick={() => updateStep(index)}>
                                            <div className="circle"><strong>{index + 1}</strong></div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="container-fluid m-0 p-0" style={{ minHeight: "150px" }}>
                        <div className="row m-0 p-0">
                            {
                                listQuestions.length === 0
                                    ?
                                    <div className="alert alert-warning text-center mt-2" role="alert">
                                        No hay preguntas para responder
                                    </div>
                                    :
                                    <div className="col p-0">
                                        {
                                            animate &&
                                            (showEnd ?
                                                <div className={`container mt-1 animate__animated animate__fadeInLeft`}>
                                                    <div className="row">
                                                        <div className="col abs-center">
                                                            <img src={Celebration} alt="Vector festividad" height={250} />
                                                        </div>
                                                    </div>
                                                    <div className="row font-lilita-one text-center">
                                                        <div className="col-">
                                                            ¡Haz llegado al final!
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className={`container mt-1 animate__animated animate__fadeInLeft`}>
                                                    <div className="row">
                                                        <div className="col">
                                                            {
                                                                selected.finish
                                                                    ?
                                                                    <>
                                                                        <p>Pregunta abierta, toca para responder</p>
                                                                        <div role="button" onClick={() => {
                                                                            const question = listQuestions[currentStep];
                                                                            onTapCode({
                                                                                ...selected,
                                                                                question: selected.name,
                                                                                order: currentStep,
                                                                                code_questions: question.code_questions,
                                                                                code_question_id: question.code_questions.id,
                                                                            }, TYPE_QUESTION);
                                                                            updateStep(currentStep + 1);
                                                                        }}>
                                                                            <i className="fa-solid fa-circle-question text-orange me-2"></i>
                                                                            <span className="fs-5 text-bold">{selected.name}</span> <small className="link-underline-primary text-purple" style={{ textDecoration: "underline" }}>Responder</small>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>Para esta pregunta cerrada tienes algunas respuestas</p>
                                                                        <h5 role="button">
                                                                            <i className="fa-solid fa-circle-question text-orange me-2"></i>{selected.name}
                                                                        </h5>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>

                                                    {
                                                        selected.answers?.map((answer, index) => {
                                                            if (!answer.finish && answer.sequence_questions) return (
                                                                <div className="row" key={index}>
                                                                    <div className="col" role="button" onClick={() => {
                                                                        handleAnimate();
                                                                        setSelected({ ...answer.sequence_questions.questions_form })
                                                                    }}>
                                                                        {index + 1}. {answer.name} <small className="link-underline-primary text-green" style={{ textDecoration: "underline" }}>Responder</small>
                                                                    </div>
                                                                </div>
                                                            )

                                                            return (<div className="row" key={index}>
                                                                <div className="col" role="button" onClick={() => {
                                                                    handleAnimate();
                                                                    const question = listQuestions[currentStep];
                                                                    onTapCode({
                                                                        ...answer,
                                                                        question: selected.name,
                                                                        order: currentStep,
                                                                        code_questions: question.code_questions,
                                                                        code_question_id: question.code_questions.id,
                                                                    }, TYPE_ANSWER);
                                                                    updateStep(currentStep + 1);
                                                                }}>
                                                                    {index + 1}. {answer.name} <small className="link-underline-primary text-purple" style={{ textDecoration: "underline" }}>Responder</small>
                                                                </div>
                                                            </div>)
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                            }
                        </div>
                    </div>

                </div>

                {/* <div className="row m-0 p-0 mt-4">
                    <div className="col buttons-stepper">
                        <button type="button" className="btn btn-success mx-1"
                            disabled={currentStep === 0}
                            style={{ opacity: currentStep === 0 && .15 }}
                            onClick={() => updateStep(currentStep - 1)}>
                            <i className="fa-solid fa-caret-left"></i> Atrás
                        </button>
                        <button type="button" className="btn btn-success mx-1"
                            onClick={() => updateStep(currentStep + 1)}
                            disabled={currentStep === listQuestions.length - 1}
                            style={{ opacity: (currentStep === listQuestions.length - 1) && .15 }}
                        >
                            Siguiente <i className="fa-solid fa-caret-right"></i>
                        </button>
                    </div>
                </div> */}
            </div>

        </div>
    )
}

export default DialogsBySteps;