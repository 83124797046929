import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedTypepeople } from '../../../../reducers/typePeople/reducerTypepeople';
import { searchGroup } from '../../../../reducers/typePeople/reducerGroup';
import '../../styles/search-input.css'

export const SearchGroup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { list, selectedTypepeople } = useSelector(state => state.typepeopleSlice);

    const setData = async (e) => {
        const id = parseInt(e.target.value);
        dispatch(setSelectedTypepeople({ value: list.find(i => i.id === id) }));
        history.push({
            search: `?people=${e.target.value}`
        });
    }

    return (

        <div className="d-flex justify-content-start align-items-center principal-div-search-content">
            <div className="second-div-search-content">
                <ul>
                    <li>
                        <button className="primary-item-search-lupa"> <i className="fas fa-search"></i></button>
                    </li>

                    <li className="second-item-search-input-text">
                        <input
                            type="text"
                            className="align-items-center"
                            placeholder="Digite el grupo a buscar"
                            onChange={(v) => dispatch(searchGroup({
                                value: v.target.value
                            }))}
                        />
                    </li>
                    <li className="third-item-search-select">
                        <select
                            className="align-items-left"
                            style={{ outline: "none" }}
                            name="type_people_id"
                            value={selectedTypepeople.id}
                            onChange={setData}
                        >
                            <option value="">
                                Seleccione el tipo de persona
                            </option>
                            {
                                list.map(item => (<option value={item.id} key={item.id}>
                                    {item.name}
                                </option>))
                            }
                        </select>
                    </li>
                </ul>
            </div>
        </div>
    );
}