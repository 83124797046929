import { useSelector } from "react-redux";

import { isURL } from "../../../utils/isUrl";
import { useContextMenu } from "../../../app/hooks/useContextMenu";
import { countRecentUnreadMessages, findLastMessage, getTimeElapsed } from "../utils/processData";
import { useDownloadImageAsBase64 } from "../../../app/hooks/useDownloadImageAsBase64";

const CardRecentChat = ({ participants, messages, isActive = false, onClick = (e) => { } }) => {
    const { list } = useSelector(state => state.userSlice);
    const { nick_name } = useSelector(state => state.loginSlice.profile);
    const { cardRef, contextMenuVisible, handleContextMenu,
        handleHideContextMenu, handleClick, contextMenuPosition } = useContextMenu(onClick);

    const usernameSender = Object.keys(participants).filter(key => key !== nick_name)?.pop();
    const user = list.find(itemUser => itemUser.nick_name === usernameSender);
    const {file} = useDownloadImageAsBase64(user?.url_avatar);

    const lastMessage = findLastMessage(messages);
    const countMessages = countRecentUnreadMessages(messages, nick_name);

    return (
        <div
            ref={cardRef}
            title={lastMessage?.content}
            className={`recent__chat ${isActive ? "active" : ""}`}
            onContextMenu={handleContextMenu}
            onClick={handleClick}
        >
            <div>
                {
                    isURL(user?.url_avatar)
                        ?
                        <img className="avatar" src={file} alt="Avatar" />
                        :
                        <i className="fa-solid fa-user avatar"></i>
                }
            </div>
            <div className="information__chat">
                <div className="name-time__chat">
                    <div className="fw-bold font-lato fs-7 pt-1">
                        {user?.name}
                    </div>
                    <div className="text-end">
                        <span className="fs-8">{getTimeElapsed(lastMessage?.timestamp)}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="last-message__chat">
                        <span className="fs-7">{lastMessage?.content?.substring(0, 40)}</span>
                    </div>
                    {
                        countMessages > 0
                        &&
                        <div className="badge__messages">
                            {countMessages}
                        </div>
                    }
                </div>
            </div>
            <ContextMenu
                visible={contextMenuVisible}
                x={contextMenuPosition.x}
                y={contextMenuPosition.y}
                onHide={handleHideContextMenu}
            />
        </div>
    );
};

const ContextMenu = ({ visible, x, y, onHide }) => {

    if (!visible) return null;

    const style = {
        top: y,
        left: x,
    };

    return (
        <div className="context-menu__chat" style={style}>

            <div role="button">
                <i className="fa-solid fa-trash-can me-2"></i>Eliminar
            </div>
            <div role="button">
                <i className="fa-solid fa-thumbtack me-2"></i>Fijar
            </div>
        </div>
    );
};

export default CardRecentChat;