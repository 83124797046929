import { useState } from 'react';

import BreadcrumbCustom from '../../../app/components/BreadcrumCustom';
import ListLegalCase from './ListLegalCase';
import SearchLegalCase from './SearchLegalCase';
import ModalLegalCase from './ModalLegalCase';
import { useDispatch } from 'react-redux';
import { cleanData } from '../../../reducers/legalCase/reducerLegalCase';

const LegalCase = () => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    return (
        <>
            <div className="mx-4">
                <BreadcrumbCustom
                    title="Casos"
                    activeRoute="/ Lista"
                />

                <div className="container m-0 p-0">
                    <div className="row">
                        <div className="col-6">
                            <SearchLegalCase />
                        </div>
                        <div className="col-6">
                            <button title="Crear nuevo tipo de persona" className="btn btn-success mt-1 mx-2" type="button" onClick={() => { setShow(true); dispatch(cleanData()); }}>
                                <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                                Crear caso
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table-data" >
                    <ListLegalCase setShow={setShow} />
                </div>

            </div>
            <ModalLegalCase show={show} setShow={setShow} />
        </>
    )
}
export default LegalCase;
