import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import { NO_CHECK_CODES } from '../../../../constant/constant';
import { setCheckCodes } from '../../../../reducers/documents/reducerGenerateDocument';

const ModalError = ({ show, setShow, errors }) => {
    const { selectedPeople } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();

    const viewDocument = () => {
        dispatch(setCheckCodes({ value: NO_CHECK_CODES }));
        setShow(false);
    }

    return (
        <Modal show={show} onHide={viewDocument} centered size='lg'>
            <Modal.Header className='bg-green pe-4' closeButton>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h5 className='text-center'>⚠️ ¡No tienes algunos datos! ⚠️</h5>
                        </div>
                    </div>
                </div>

            </Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col'>
                            <p>Te presentamos una lista de errores de datos que no fueron encontrados y el código que sustituye a cada uno para que completes la información manualmente.</p>

                            <ol>
                                {
                                    errors?.map((error, i) => {
                                        if (error.code?.startsWith("QUESTION") || error.code?.startsWith("GLOBAL")) {
                                            return (
                                                <li key={i}>
                                                    <b>{i + 1}. <i>{error.name}: </i></b> @{error.code}
                                                </li>
                                            )
                                        }
                                        const actor = selectedPeople.find(person => person.people_id === error.id)
                                        return <li key={i}>
                                            <b>{i + 1}. <i>@{error.code}: </i></b> {error.name}
                                            <Link
                                                className="link-underline-primary text-red ms-1"
                                                style={{ textDecoration: "underline" }}
                                                to={`/people/edit/${actor?.people_id}?next=${window.location.pathname}?tabIndex=4`}
                                            >
                                                Corregir
                                            </Link>
                                        </li>
                                    })
                                }
                            </ol>

                            <br />

                            <p>Identifica el actor, códigos globales o códigos del diálogo que se indiquen en la lista de errores y regresa para seguir con tu documento. Sino, puedes generar el documento con los datos disponibles al Cancelar.</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='abs-center'>
                <Button variant="success" onClick={viewDocument}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalError;