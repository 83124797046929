import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import SidebarCodes from './Sidebar';
import ModalCodesQuestions from './ModalCodesQuestions';
import ModalAutomateParameterization from '../../../GptAutomation/components/ModalAutomateParameterization';
import ModalChatParameterization from '../../../GptAutomation/components/ModalChatParameterization';

import { reducerForm } from '../../../../reducers/documents/reducerGenerateDocument';
import { MessageError, MessageVerifyCodes } from '../../../../utils/message';
import { isURL } from '../../../../utils/isUrl';
import { pluginsTinyMCE, stylesEditorTinyMCE, toolbarEditorTinyMCE } from '../../../../config/editorConfigurations';
import { validateExistingCodes, setBodyScriptHTML } from '../../utils/functions';
import SidebarCodeInformation from './SidebarCodeInformation';
import { toast } from '../../../../utils/toast';
import { hideLoading, showLoading } from '../../../../reducers/main/loadingReducer';
import GeneralService from '../../../../services/GeneralService';
import { ACTION_ADD_TO_END, ACTION_REPLACE_CONTENT } from '../../../GptAutomation/constant/constant';
import { PROMPT_DOCUMENTS } from '../../../GptAutomation/prompts/prompts';
import { extractAllCodesDocument } from '../../utils/exportFunctions';

const TabText = () => {
    const { list: listTypePeople } = useSelector(state => state.typepeopleSlice);
    const { list: listGlobal } = useSelector(state => state.globalCodesSlice);
    const { dataDocument, codesQuestions, codesGlobal, codesActors, listQuestions, listActorsSelected } = useSelector(state => state.documentsAutoSlice);
    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState({ modal: 1, show: false });
    const editorRef = useRef(null);
    const dispatch = useDispatch();

    const insertCode = (code) => {
        if (editorRef.current) {
            editorRef.current.execCommand('mceInsertContent', false, `${code} `);
        }
        toast(dispatch, `Se insertó ${code}`);
    }

    const verifyCodes = async () => {
        const { content, errors } = await validateExistingCodes(dataDocument.url_template_html, codesGlobal, codesActors, codesQuestions);
        dispatch(reducerForm({ key: "url_template_html", value: content }));
        if (errors > 0) {
            await MessageVerifyCodes(() => parametrizeDocument(content));
            return;
        }
        await parametrizeDocument(content);
    }

    const parametrizeDocument = async (content) => {
        const service = new GeneralService("documents-auto/parameterization");

        dispatch(showLoading());
        const res = await service.update({ id: dataDocument.id, url_template_html: setBodyScriptHTML(content) });
        dispatch(hideLoading());
        if (res.is_ok) {
            toast(dispatch, res.message);
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleEditor = (value, editor) => {
        const symbols = ["&nbsp;", "&ldquo;", "&rdquo;", "&lsquo;", "&rsquo;", "&ndash;", "&mdash;", "&hellip;", "&bull;", "&quot;", "&lt;", "&gt;", "&amp;"];

        symbols.forEach(symbol => {
            const regex = new RegExp(symbol + "@", "g");
            value = value.replace(regex, "@");
        });

        dispatch(reducerForm({ key: "url_template_html", value: value }));
    };


    const handleChangeContentChat = (content = "", action) => {
        content = content.replace("html", "");
        const splittedContent = content.split("```")
        const cleanedContent = splittedContent ? splittedContent[1] : content;

        switch (action) {
            case ACTION_ADD_TO_END:
                return dispatch(reducerForm({ key: "url_template_html", value: dataDocument.url_template_html + cleanedContent }));
            case ACTION_REPLACE_CONTENT:
                return dispatch(reducerForm({ key: "url_template_html", value: cleanedContent }));
            default:
                console.log("opcion no disponible");
        }
    }

    const textCodes = () => {
        return extractAllCodesDocument(dataDocument.name, listActorsSelected, listTypePeople, listQuestions, listGlobal);
    }

    return (
        <>
            <div className="process-tab">
                <div className="row mb-2">
                    <div className="col">
                        <button title="Guardar contenido" className="btn btn-success rounded-pill px-2 py-1 mx-1" type="button" onClick={verifyCodes}>
                            <i className="fa-solid fa-floppy-disk text-white"></i>
                        </button>
                    </div>
                    <div className="col">
                        <button title="Visualizar códigos" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                            <i className="fa-solid fa-angles-left text-white"></i>
                        </button>
                        <button title="Códigos de preguntas" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button"
                            onClick={() => setShow(true)}
                        >
                            <i className="fa-solid fa-sitemap text-white"></i>
                        </button>
                        <button title="Visualizar códigos" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarCodeInformation" aria-controls="sidebarCodeInformation">
                            <i className="fa-solid fa-circle-question text-white"></i>
                        </button>
                        <button title="Parametrización automática" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end" type="button"
                            onClick={() => setShowChat(s => ({ ...s, show: true }))}
                        >
                            <i className="fa-solid fa-robot text-white"></i>
                        </button>
                    </div>

                </div>
                <div className="row">
                    <div className="col">
                        <Editor
                            apiKey={process.env.REACT_APP_TINY_CLOUD_TOKEN}
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                                height: 750,
                                menubar: true,
                                plugins: pluginsTinyMCE(["emoticons"]),
                                toolbar: toolbarEditorTinyMCE(),
                                content_style: stylesEditorTinyMCE(),
                            }}
                            value={isURL(dataDocument.url_template_html) ? "" : dataDocument.url_template_html}
                            onEditorChange={handleEditor}
                        />
                    </div>
                </div>
            </div>

            <ModalCodesQuestions show={show} setShow={setShow} insertCode={insertCode} />
            <SidebarCodes insertCode={insertCode} />
            <SidebarCodeInformation />

            <ModalAutomateParameterization
                show={showChat.modal === 1 && showChat.show}
                setShow={setShowChat}
                textCodes={textCodes()}
                prompt={PROMPT_DOCUMENTS}
            />
            <ModalChatParameterization
                show={showChat.modal === 2 && showChat.show}
                setShow={setShowChat}
                onChangeContent={handleChangeContentChat}
            />
        </>
    );
}

export default TabText;