import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { insertFromTypeProcess, reducerForm, updateFromTypeProcess } from '../../../../reducers/expedient/reducerTypeProcess';
import GeneralService from '../../../../services/GeneralService';
import { toast, MessageError } from '../../../../utils';
import { ID_NOT_DEFINED } from '../../../../constant/constant';

export const ModalTypeProcess = ({ show, setShow }) => {
    const { selectedTypeProcess } = useSelector(state => state.typeProcessSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("type-process");

        if (selectedTypeProcess.id === ID_NOT_DEFINED) {
            const res = await service.post(selectedTypeProcess);
            if (res.is_ok) {
                setShow(false);
                toast(dispatch, res.message);
                dispatch(insertFromTypeProcess(res.type_process));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(selectedTypeProcess);
        if (res.is_ok) {
            setShow(false);
            toast(dispatch, res.message);
            dispatch(updateFromTypeProcess(selectedTypeProcess));
            return;
        }
        MessageError(res.message, service.status);
        return;
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedTypeProcess.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedTypeProcess.id === ID_NOT_DEFINED ?
                                            "Crear Tipo de Proceso"
                                            :
                                            "Editar Tipo de Proceso"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">

                        <div className="row">
                            <div className='col mb-3'>
                                <label>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="name"
                                    value={selectedTypeProcess.name}
                                    maxLength={120}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col mb-3'>
                                <label>Descripción</label>
                                <textarea
                                    className="form-control custom-textarea mt-1"
                                    name="description"
                                    value={selectedTypeProcess.description}
                                    maxLength={300}
                                    placeholder="Ingrese una descripción"
                                    onChange={setData}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2"></i>
                        {
                            selectedTypeProcess.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}