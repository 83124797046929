import IconButton from "../../../app/components/IconButton";
import { FileIcons } from "../fixtures/fileIcons";

export const CardUploadItem = ({ file, onClick }) => {
    return (
        <div className="drop-file-preview__item pending-upload">
            <div className="title">
                <div className="pt-1 pe-1">
                    {FileIcons[file.name.split('.').pop()] || FileIcons['default']}
                </div>
                <div className='fs-6 body pt-1'>
                    <div className="main-title d-block font-rubik">{file.name}</div>
                    <div className='d-block fs-8'>
                        {(file.size / 1024 / 1024).toFixed(2)} MB
                    </div>
                </div>
            </div>
            <IconButton
                icon="fa-solid fa-trash-can text-red"
                title="Eliminar archivo"
                fontSize='20px'
                onClick={onClick}
            />
        </div>
    )
}
