import { useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionBody from "react-bootstrap/esm/AccordionBody";

import PeopleCategoriesClusters from "./peopleCategories";
import IconButton from "../../../../app/components/IconButton";
import confirmDelete from "../../../../utils/confirmDelete";
import { setDeleteItemOfCategoriesList } from "../../../../reducers/people/reducerPeople";

const AccordionCategory = ({ category, id, refForm }) => {
    const dispatch = useDispatch();

    const handleDelete = (id) => {
        confirmDelete(() => dispatch(setDeleteItemOfCategoriesList({ value: id })));
    }

    return (
        <>
            {
                category.clusters?.length === undefined ?
                    <div className="alert alert-warning" role="alert">
                        ¡No hay grupos asociados a la "{category.name}" !
                    </div>
                    :
                    <Accordion
                        className="m-0 p-0 accordion-stepper-form rounded-4"
                        defaultActiveKey={['0']}
                        flush
                    >
                        <AccordionItem
                            className="accordion-item-stepper-form"
                            eventKey={category.id}
                        >
                            <Accordion.Header className="accordion-header-stepper-form">
                                <div className="row">
                                    <div className="col-1">
                                        <IconButton
                                            icon="fa-solid fa-trash-can text-red"
                                            title="Eliminar categoría"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(category.id);
                                            }}
                                        />
                                    </div>
                                    <div className="col fw-bold pt-1 ms-2 text-black">
                                        <i className="fa-solid fa-circle-right text-green me-2"></i>
                                        {category.name}
                                    </div>
                                </div>
                            </Accordion.Header>
                            <AccordionBody className="m-0 px-0 pb-3">
                                <PeopleCategoriesClusters
                                    clusters={category.clusters}
                                    id={id}
                                    refForm={refForm}
                                />
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
            }
        </>
    )
}

export default AccordionCategory;