import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import GeneralService from '../../../services/GeneralService';
import { MessageError, toast } from '../../../utils';
import { insertFromRole, reducerForm, updateFromRole } from "../../../reducers/roles/reducerRoles";
import { ID_NOT_DEFINED } from '../../../constant/constant';

const ModalRole = ({ show, setShow }) => {
    const { selectedRole } = useSelector(state => state.rolesSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("roles");

        if (selectedRole.id === ID_NOT_DEFINED) {
            const res = await service.post(selectedRole);

            if (res.is_ok) {
                dispatch(insertFromRole(res.content));
                toast(dispatch, res.message);
                setShow(false);
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(selectedRole);
        if (res.is_ok) {
            dispatch(updateFromRole());
            toast(dispatch, res.message);
            setShow(false);
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedRole.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedRole.id === ID_NOT_DEFINED ?
                                            "Crear Rol"
                                            :
                                            "Editar Rol"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">

                        <div className="row m-0 p-0">

                            <div className='mb-3'>
                                <label>Nombre del rol:</label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="name"
                                    value={selectedRole.name}
                                    maxLength={50}
                                    minLength={3}
                                    placeholder="Digita un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2"></i>
                        {
                            selectedRole.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalRole;