import { createSlice } from '@reduxjs/toolkit';

export const businessSlice = createSlice({
    name: 'businessSlice',
    initialState: {
        company: {
            id: 0,
            type_document_id: 0,
            plans_id: 0,
            num_document: '',
            name: '',
            email: '',
            plan: {
                "id": 0,
                "name": "",
                "storage_gb": 0,
                "email_quantity": 0,
                "document_quantity": 0,
                "zapsign_quantity": 0,
                "user_limit": 0,
                "price_usd": 0,
            },
        },
        dataBusiness: {
            type_document_id: 0,
            plans_id: 0,
            num_document: '',
            name: '',
            email: '',
            plans: '',
        },
        list: [],
        listFilter: [],
        isLoading: false,
        listTypeDocument: [],
        listPlans: [],
        filterNumDocument: [],
    },
    reducers: {
        setDataBussiness: (state, action) => {
            state.dataBusiness[action.payload.key] = action.payload.value
        },
        setBusiness: (state, action) => {
            state.list = action.payload.value;
            state.listFilter = action.payload.value;
            state.isLoading = false;
        },
        setDataGetById: (state, action) => {
            state.dataBusiness = action.payload.value
        },
        setCompany: (state, action) => {
            state.company = action.payload.value
        },
        deleteBusiness: (state, action) => {
            const newList = state.list.filter(e => e.id !== action.payload.value)
            state.list = newList;
            state.listFilter = newList;
        },
        reducerForm: (state, action) => {
            let { value, key } = action.payload;
            state.dataBusiness = {
                ...state.dataBusiness,
                [key]: value
            }
        },
        cleanData: (state, action) => {
            state.dataBusiness = {
                type_document_id: 0,
                plans_id: 0,
                num_document: '',
                name: '',
                email: '',
                plans: '',
            }
        },
        setListTypeDocument: (state, action) => {
            state.listTypeDocument = action.payload.value
            state.isLoading = false
        },
        setListPlans: (state, action) => {
            state.listPlans = action.payload.value
            state.isLoading = false
        },
        addNewObjectToList: (state, action) => {
            state.list.push(action.payload.value);
            state.listFilter.push(action.payload.value);

            state.dataBusiness = {
                type_document_id: null,
                plans_id: null,
                num_document: '',
                name: '',
                email: '',
            }
        },
        updateNewObjectAfterEdit: (state, action) => {
            const business = action.payload.value
            const modificadaList = state.list.map((e) => {
                if (e.id === business.id) {
                    return business
                }
                return e;
            });
            const modificadaListFilter = state.listFilter.map((e) => {
                if (e.id === business.id) {
                    return business
                }
                return e;
            });
            state.list = modificadaList;
            state.listFilter = modificadaListFilter;
            state.dataBusiness = {
                type_document_id: 0,
                plans_id: 0,
                num_document: '',
                name: '',
                email: '',
            };
        },
        searchTextBusiness: (state, action) => {
            if (action.payload.value === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
            state.listFilter = filtered;
        },
        searchNumberBusiness: (state, action) => {
            state.filterNumDocument = action.payload.value;
            if (action.payload.value === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.num_document.toString().includes(action.payload.value.toString()));
            state.listFilter = filtered;
        },
    },
});

export const plans = { 2: 'BASIC', 3: 'EXPERT', 4: 'GOLD', 5: 'PREMIUM' };

export const {
    setListTypeDocument,
    setDataBussiness,
    setListPlans,
    addNewObjectToList,
    setBusiness,
    setCompany,
    deleteBusiness,
    updateNewObjectAfterEdit,
    searchTextBusiness,
    searchNumberBusiness,
    setDataGetById,
    reducerForm,
    cleanData,
} = businessSlice.actions;

export default businessSlice.reducer;