
import { createSlice } from '@reduxjs/toolkit';
import { MODULE_AUTOMATION_GPT, MODULE_BUSINESS, MODULE_CASES, MODULE_CONFIGURATIONS, MODULE_DOCUMENTS_GENERATION, MODULE_DOCUMENTS_PARAMETERIZATION, MODULE_EXPEDIENTS, MODULE_NOTIFICATIONS, MODULE_ROLES, MODULE_TYPE_PEOPLE, MODULE_USERS, MODULE_VIRTUAL_NOTARY } from '../../constant/constant';

const originalList = [
  { module_id: MODULE_USERS, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_BUSINESS, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_NOTIFICATIONS, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_TYPE_PEOPLE, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_CASES, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_CONFIGURATIONS, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_DOCUMENTS_PARAMETERIZATION, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_DOCUMENTS_GENERATION, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_EXPEDIENTS, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_VIRTUAL_NOTARY, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_AUTOMATION_GPT, can_read: false, can_write: false, can_delete: false },
  { module_id: MODULE_ROLES, can_read: false, can_write: false, can_delete: false, can_action: false },
];

export const permissionsSlice = createSlice({
  name: 'permissionsSlice',
  initialState: {
    list: originalList,
    isLoading: true,
  },
  reducers: {
    setList: (state, { payload }) => {
      state.list = originalList.map(permission => {
        const newPermission = payload.find(itemPermission => itemPermission.module_id === permission.module_id);
        if (newPermission) {
          return newPermission;
        }
        return permission;
      });
    },
    setPermission: (state, { payload }) => {
      const { id, module_id, name, value } = payload;
      state.list = state.list.map(permission => {
        if (permission.module_id === module_id) {
          if (name === "can_action" && value) {
            return { ...permission, id, [name]: value, can_read: false, can_write: false, can_delete: false }
          }

          return { ...permission, id, [name]: value };
        }
        return permission;
      });
    },
  },
});

export const {
  setList,
  setPermission,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;

