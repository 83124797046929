import { useSelector } from 'react-redux';
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const CustomDoughnut = () => {
    const { company } = useSelector(state => state.businessSlice);
    const { metrics } = useSelector(state => state.companyMetricsSlice);
    const { plan } = company;

    const usedSpace = metrics.length > 0 ? (metrics[0].megabytes_used_space / (plan.storage_gb * 1024)) * 100 : 0;
    const usedSpaceInGB = metrics[0]?.megabytes_used_space / 1024
    const emptySpace = 100 - usedSpace;

    const data = {
        labels: ['Usado', 'Disponible'],
        datasets: [
            {
                data: [usedSpace, emptySpace],
                backgroundColor: ['#4ABC87', '#f3be88'],
            },
        ],

    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            doughnutlabel: {
                labels: [
                    {
                        text: 'Total',
                        font: {
                            size: '25',
                            weight: 'bold',
                        },
                    },
                    {
                        text: '450',
                        font: {
                            size: '30',
                            weight: 'bold',
                        },
                    },
                ],
            },
        },
    };

    return (
        <div className="block__custom-doughnut font-lato">
            <span className="d-block text-center fw-bold fs-7">
                Almacenamiento
            </span>
            <div className="fs-7 text-center">
                <span className='text-orange'>{usedSpaceInGB.toFixed(1)}</span>/{plan.storage_gb}GB
            </div>
            <Doughnut data={data} options={options} width={220} />
        </div>
    )
}

export default CustomDoughnut;