import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cleanData, reducerForm } from "../../../reducers/people/reducerRegistrationLink";
import GeneralService from "../../../services/GeneralService";
import { MessageError, MessageSuccess } from "../../../utils/message";
import { HIDE, HTTP_OK } from "../../../constant/constant";

const ModalSendCustomerRegistrationLink = ({ show, setShow }) => {
    const { list } = useSelector(state => state.typepeopleSlice);
    const { registrationLink, durations } = useSelector(state => state.registrationLinkSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            ...registrationLink,
            type_people_id: parseInt(registrationLink.type_people_id),
        }

        const service = new GeneralService("people/registration-temporal-link");
        const res = await service.post(data);
        if (service.status === HTTP_OK) {
            MessageSuccess(res.message);
            setShow(HIDE);
            dispatch(cleanData());
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} size="md">
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                <i className="fa-solid fa-share mt-2 animate__animated animate__backInLeft" style={{ fontSize: 24 }}></i>
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    Enviar link de registro
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">

                        <div className="row m-0 p-0">

                            <div className='col mb-3'>
                                <label>Nombre:</label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="name"
                                    value={registrationLink.name}
                                    maxLength={50}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row m-0 p-0">
                            <div className='col mb-3'>
                                <label>Email:</label>
                                <input
                                    type="email"
                                    className="form-control mt-1"
                                    name="email"
                                    value={registrationLink.email}
                                    maxLength={50}
                                    minLength={3}
                                    placeholder="Ingrese un email"
                                    autoComplete="off"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row m-0 p-0">
                            <div className="col">
                                <label>Tipo de persona:</label>
                                <select
                                    className="form-select mt-1"
                                    name="type_people_id"
                                    value={registrationLink.type_people_id}
                                    onChange={setData}
                                    required
                                >
                                    <option value="">
                                        Seleccione tipo de persona
                                    </option>
                                    {
                                        list.map(item => (<option value={item.id} key={item.id}>
                                            {item.name}
                                        </option>))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="mt-2">
                        </div>

                        <div className="row m-0 p-0">
                            <label className="mb-2">Tiempo de expiración:</label>
                            {
                                durations.map(duration => <ItemExpiration
                                    key={duration.value}
                                    name="expires_at"
                                    duration={duration}
                                    onChange={setData}
                                    active={registrationLink.expires_at}
                                />)
                            }
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        Enviar
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

const ItemExpiration = ({ duration, onChange, active, name }) => {
    return (<div title={duration.tooltip} className="col">
        <div role="button" className={`border-success text-center p-1 rounded-3 fw-bold ${active === duration.value && "bg-success text-white"}`}
            onClick={() => onChange({ target: { name, value: duration.value } })}>
            {duration.name}
        </div>
    </div>)
}

export default ModalSendCustomerRegistrationLink;