import { useState } from 'react';
import { useSelector } from 'react-redux';

import StepNavigationGroups from "./StepNavigation/stepNavigationGroups";
import { aceptFile } from "../../utils/aceptFile";
import FieldType from './FieldType';

const CaseClusters = ({ encodeDocument, id }) => {
    const { selectedCase } = useSelector(state => state.legalCaseSlice);
    const { dataCase } = useSelector(state => state.createCaseSlice);
    const [currentStep, setCurrentStep] = useState(1);

    const updateStep = (step) => {
        setCurrentStep(step);
    }

    return (
        <>
            {
                selectedCase.clusters?.length === 0
                    ?
                    <div className="alert alert-warning" role="alert" style={{ marginBottom: '0px', margin: '25px 12px' }}>
                        ¡No hay grupos para mostrar, selecciona un tipo de caso para generar el formulario!
                    </div>
                    :
                    <div className="container-fluid m-0 p-5 mt-4 rounded-4 people-principal-container">
                        {/* Stepper Form */}
                        <div className="row m-0 stepper">
                            <StepNavigationGroups
                                currentStep={currentStep}
                                updateStep={updateStep}>
                            </StepNavigationGroups>

                            <div className="container-fluid m-0 p-0 stepform-contenedor-campos">
                                <div className="row m-0 p-0">
                                    <div className="col p-0">
                                        {
                                            selectedCase.clusters?.map((cluster, index) => (
                                                <div key={cluster.id} className={`${currentStep === index + 1 ? "show-form" : "hide-form"}`}>
                                                    {/* Titulo del grupo */}
                                                    <h3 style={{ textAlign: "center", margin: "8px 0px 10px 0px" }}>
                                                        {cluster.name}
                                                    </h3>
                                                    <small style={{ display: 'block', textAlign: "center" }}>
                                                        Los campos con asterísco son obligatorios
                                                        <i style={{ color: 'red', fontWeight: 'bold' }}>*</i>
                                                    </small>

                                                    <div className="row m-0 p-0 mt-4">
                                                        {
                                                            cluster.parameterizations?.length === undefined
                                                                ?
                                                                <div className="alert alert-warning" role="alert" style={{ marginBottom: '0px', margin: '20px 12px' }}>
                                                                    ¡No hay parametrizaciones para este grupo, selecciona otro!
                                                                </div>
                                                                :
                                                                cluster.parameterizations?.map((param, index) => (
                                                                    <div key={index} className="col-4 mt-3">
                                                                        <label className="steper-input-label">
                                                                            {param.name}{param.required && <i style={{ color: 'red', fontWeight: 'bold' }}>*</i>}
                                                                        </label>
                                                                        <FieldType
                                                                            param={param}
                                                                            id={id}
                                                                            cluster={cluster}
                                                                            dataCase={dataCase}
                                                                            aceptFile={aceptFile}
                                                                            encodeDocument={encodeDocument}
                                                                        />
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col buttons-stepper">
                                <button type="button" className="btn btn-success rounded-3 mx-2 px-3 py-2" disabled={currentStep === 1}
                                    onClick={() => updateStep(currentStep - 1)}>
                                    <i className="fa-solid fa-caret-left"></i> Atrás
                                </button>

                                <button type="button" className="btn btn-success rounded-3 mx-2 px-3 py-2"
                                    onClick={() => updateStep(currentStep + 1)}
                                    disabled={currentStep === selectedCase.clusters?.length}
                                >
                                    Siguiente <i className="fa-solid fa-caret-right"></i>
                                </button>
                            </div>
                        </div>

                    </div>
            }
        </>
    );
}

export default CaseClusters;
