import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { MessageConfirm, MessageError, MessageSuccess } from '../../utils/message';
import { cleanData } from '../../reducers/copyClusters/reducerCopyGroup';
import GeneralService from '../../services/GeneralService';

const ModalCopyForm = ({ show, setShow, onCopy=()=>{} }) => {
    const { list, selectedForm } = useSelector(state => state.formSlice);
    const { dataCopy } = useSelector(state => state.copyFormSlice);
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);

    const handleCopy = async (form_id) => {
        const data = {
            ...dataCopy,
            form_id,
        };
        
        const service = new GeneralService("copy-form");
        const res = await service.post(data);
        if (res.is_ok) {
            handleClose();
            MessageSuccess(res.message);
            dispatch(cleanData());
            if(selectedForm.id === form_id) {
                onCopy();
            }
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered size='lg'>
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                <i className="fa-solid fa-copy mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                            </div>
                            <div className='col mt-2 p-0'>
                                <h5>¿Dónde copiar el {dataCopy.is_cluster ? "grupo" : "actor"}?</h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='modal-body-copy-group p-4 pt-3 pb-5'>
                    <div className='container-fluid'>
                        {
                            list.length === 0 ?
                                <div className="alert alert-warning text-center mt-2" role="alert">
                                    No hay colecciones disponibles
                                </div>
                                :
                                <>
                                    {
                                        list.map((form) => {
                                            return (
                                                <div className='row mt-1 pt-1 pb-2' key={form.id} style={{borderBottom: "1px dashed #ccc"}}>
                                                    <div className='col-11 pt-2'>
                                                        <i className='fa-solid fa-file-invoice fs-4 me-2 text-green'></i>
                                                        {form.name}
                                                    </div>

                                                    <div className='col'>
                                                        <button className="btn btn-success rounded-3"
                                                            title='Pegar en este formulario'
                                                            type='button'
                                                            onClick={async () => {
                                                                const res = await MessageConfirm('¿Desea pegarlo en este formulario?');
                                                                if (res) {
                                                                    await handleCopy(form.id);
                                                                }
                                                            }}
                                                        >
                                                            <i className="fa-regular fa-clipboard"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalCopyForm;
