
export const FileIcons = {
    default: <i className="fa-solid fa-file text-gray d-inline-block me-2 fs-3"></i>,
    pdf: <i className="fa-solid fa-file-pdf text-red d-inline-block me-2 fs-3"></i>,
    png: <i className="fa-solid fa-file-image text-orange d-inline-block me-2 fs-3"></i>,
    jpg: <i className="fa-solid fa-file-image text-orange d-inline-block me-2 fs-3"></i>,
    jpeg: <i className="fa-solid fa-file-image text-orange d-inline-block me-2 fs-3"></i>,
    mp3: <i className="fa-solid fa-file-audio text-purple d-inline-block me-2 fs-3"></i>,
    mp4: <i className="fa-solid fa-file-video text-redligth d-inline-block me-2 fs-3"></i>,
    docx: <i className="fa-solid fa-file-word text-blue d-inline-block me-2 fs-3"></i>,
    doc: <i className="fa-solid fa-file-word text-blue d-inline-block me-2 fs-3"></i>,
    ppt: <i className="fa-solid fa-file-powerpoint text-orange d-inline-block me-2 fs-3"></i>,
    pptx: <i className="fa-solid fa-file-powerpoint text-orange d-inline-block me-2 fs-3"></i>,
    xls: <i className="fa-solid fa-file-excel text-green d-inline-block me-2 fs-3"></i>,
    xlsx: <i className="fa-solid fa-file-excel text-green d-inline-block me-2 fs-3"></i>,
    zip: <i className="fa-solid fa-file-zipper text-purple d-inline-block me-2 fs-3"></i>,
    rar: <i className="fa-solid fa-file-zipper text-purple d-inline-block me-2 fs-3"></i>
}