import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BreadcrumbCustom from "../../../../app/components/BreadcrumCustom";
import ListGeneratedDocuments from "../../../TercerosCreate/components/ListGeneratedDocuments";
import ToolFilterDocuments from "../../../TercerosCreate/components/ToolFilterDocuments";

import GeneralService from "../../../../services/GeneralService";
import { applyFilters, fillElatedDocuments } from "../../../../reducers/people/reducerPersonDocument";

const SignaturesView = () => {
    const { dataFilter } = useSelector(state => state.personDocumentSlice);
    const dispatch = useDispatch();

    const loadDocuments = async () => {
        const service = new GeneralService("generate-document");
        const res = await service.getList(1000, { state_sent: true });
        const { generate_documents = [] } = res;
        dispatch(fillElatedDocuments({ value: generate_documents }));
    }

    useEffect(() => {
        loadDocuments();
    }, []);

    useEffect(() => {
        dispatch(applyFilters({ value: { isPerPerson: false } }));
    }, [dataFilter]);

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Firmas"
                        activeRoute={"Listado de firmas"}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ToolFilterDocuments />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ListGeneratedDocuments />
                </div>
            </div>
        </div>
    )
}

export default SignaturesView;