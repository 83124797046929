

export const extractFieldsCase = (list) => {
    const fields = [];
    list.clusters?.forEach(cluster => {
        cluster.parameterizations?.forEach(param => {
            fields.push(param);
        });
    });
    list.categories?.forEach(category => {
        category.clusters?.forEach(cluster => {
            cluster.parameterizations?.forEach(param => {
                fields.push(param);
            });
        });
    });

    return fields;
}

export const extractFieldsCategory = (category) => {
    const fields = [];
    category.clusters?.forEach(cluster => {
        cluster.parameterizations_category_people?.forEach(param => {
            fields.push(param);
        });
    });

    return fields;
}

export const extractDataAndCategories = (data_cases, fieldsData, legalCase) => {
    let data = {};
    let categories = [];

    data_cases?.forEach(dataCase => {
        if (!dataCase.is_category) {
            const indexParam = fieldsData.findIndex(p => p.id === dataCase.parameterization_id && p.legal_case_id);
            
            if (indexParam !== -1) {
                const { id, ...other } = fieldsData[indexParam];
                data[fieldsData[indexParam].name] = {
                    ...dataCase,
                    ...other,
                };
            }
        } else {
            const indexParam = fieldsData.findIndex(p => p.id === dataCase.parameterization_id && p.category_legal_case_id);
            
            if (indexParam !== -1) {
                const { id, ...other } = fieldsData[indexParam];
                data[fieldsData[indexParam].name] = {
                    ...dataCase,
                    ...other,
                };

                const find = categories.findIndex(p => p.id === fieldsData[indexParam].category_legal_case_id);
                if (find === -1) {
                    categories.push(legalCase.categories.find(f => f.id === fieldsData[indexParam].category_legal_case_id));
                }
            }
        }
    });

    return { data, categories };
}