import { useHistory } from "react-router-dom";
import { useResize } from "../../app/hooks/useResize";

const CardConfiguration = ({ icon, title, subtitle, selected, order, onClick, icon_style, iconBackground }) => {
    const history = useHistory();
    const { windowSize } = useResize();

    const handleNavigation = () => {
        onClick();
        history.push({
            search: `?tabIndex=${order}`
        });
    }

    return (
        <div
            className={`mb-2 card-custom__config ${selected === order ? iconBackground : ""}`}
            onClick={handleNavigation}
            title={title}
        >
            <i className={`bx ${icon} card-icon__config ${icon_style} ${iconBackground}`} ></i>
            {
                windowSize.width > 1000
                &&
                <div className="card-body__config ms-3">
                    <div className="mt-2 fs-6 font-lato"><b>{title}</b></div>
                    <p className="fs-7">{subtitle}</p>
                </div>
            }

        </div>
    );
}

export default CardConfiguration