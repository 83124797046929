import { useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import ToastNotify from "../app/components/ToastNotify";
import LoadingCustom from "../app/components/LoadingCustom";
import { JoinToBusiness } from "../modules/Usuarios/JoinToBusiness";
import { SuccessRegistrationUserView } from "../modules/Usuarios/SuccessRegistrationUserView";

export const UserRegistrationRoutes = () => {
    const { show } = useSelector(state => state.toastSlice);

    return (
        <div>
            {show && <ToastNotify />}
            <LoadingCustom />

            <Router>
                <Switch>
                    <Route exact path="/user-registration/:token"> <JoinToBusiness /> </Route>
                    <Route exact path="/user-registration/successful/:token"> <SuccessRegistrationUserView /> </Route>
                </Switch>
            </Router>
        </div>
    )
}