

const FieldTypeSelect = ({ param, location, value, onChange, onInformation, className }) => {

    const handleSelection = (e) => {
        const { id, ...other } = param;
        onChange({
            parameterization_id: param.id,
            value: e.target.value,
            ...other,
        });
        if (e.target.value === "") {
            onInformation({ show: true, message: "Selecciona un opción de la lista", isError: true });
            return;
        }
        onInformation({ show: false, message: "", isError: false });
    }

    return (
        <select
            className={`form-select parametrizacion__form-input ${className}`}
            autoComplete="off"
            name={param.name}
            value={value}
            aria-label={location}
            onChange={handleSelection}
            required={param.required}
        >
            <option value="">
                Seleccione
            </option>
            {param.value_select.split("*")?.map((item, i) => {
                if (item !== "") {
                    return (
                        <option value={item} key={item}>{item}</option>
                    );
                }
                return null;
            })}
        </select>
    );
}

export default FieldTypeSelect;