import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from "react-router-dom";
import queryString from 'query-string';

import { setDataCase, setInvalidFields, setCleanFields, cleanData, setDataCaseOnEdit, setListCategoriesOnEdit, setCaseId } from '../../../../reducers/createCase/reducerCreateCase';
import { extractDataAndCategories, extractFieldsCase } from "../../utils/extract";
import { hideLoading, showLoading } from "../../../../reducers/main/loadingReducer";
import { FIELD_DATETIME, FIELD_FILE, ID_NOT_DEFINED } from '../../../../constant/constant';
import { encodeFileBase64 } from '../../utils/encondeBase64';
import { MessageConfirm, MessageError, MessageSuccess } from '../../../../utils/message';
import { InvalidFieldsMessage } from '../../utils/message';
import GeneralService from '../../../../services/GeneralService';
import { setSelectedCase } from '../../../../reducers/legalCase/reducerLegalCase';

const useCreateCase = () => {
    const { selectedCase } = useSelector(state => state.legalCaseSlice);
    const { dataCase, invalidFields, listCategories } = useSelector(state => state.createCaseSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    let { id = 0 } = useParams();
    const { search } = useLocation();
    const { next = "/case/list" } = queryString.parse(search);

    useEffect(() => {
        if (invalidFields.length > 0) {
            InvalidFieldsMessage(invalidFields, setCleanInvalidFields);
        }
    }, [invalidFields]);

    useEffect(() => {
        if (id !== ID_NOT_DEFINED) {
            dispatch(setCaseId({ value: parseInt(id) }));
            extraerParam();
            return;
        }
        return () => {
            dispatch(setCaseId({ value: ID_NOT_DEFINED }));
        }
    }, [id]);

    useEffect(() => {
        if (id === ID_NOT_DEFINED) {
            loadInitialValuesForm();
        }
    }, [selectedCase]);

    const loadInitialValuesForm = () => {
        const data = {};
        const fieldsData = extractFieldsCase(selectedCase);

        fieldsData.map(param => {
            if (!param.required && param.alert_required) {
                data[param.name] = {
                    value: "",
                    parameterization_id: param.id,
                    ...param
                }
            }
        });

        dispatch(setDataCaseOnEdit({ value: data }));
    }

    const extraerParam = async () => {
        const service = new GeneralService("create-case/" + id);
        const { data_cases } = await service.getRequest();
        if (data_cases?.length > 0) {
            const { parameterization_id } = data_cases.filter(data => !data.is_category)[0];
            const serviceParameterization = new GeneralService("parameterization-legal-case/" + parameterization_id);
            const { legal_case_id } = await serviceParameterization.getRequest();
            const serviceLegalCase = new GeneralService("legal-case/" + legal_case_id);
            const legalCase = await serviceLegalCase.getRequest();
            dispatch(setSelectedCase({ value: legalCase }));

            // Aquí están todas las parametrizaciones de los grupos y de las categorías.
            const fieldsData = extractFieldsCase(legalCase);
            const { data, categories } = extractDataAndCategories(data_cases, fieldsData, legalCase);

            fieldsData.map(param => {
                if (!param.required && param.alert_required) {
                    if (param.legal_case_id && !data[param.name]) {
                        data[param.name] = {
                            value: "",
                            parameterization_id: param.id,
                            ...param
                        }
                    }
                    if (param.category_legal_case_id && !data[param.name] && categories.some(c => c.id === param.category_legal_case_id)) {
                        data[param.name] = {
                            value: "",
                            parameterization_id: param.id,
                            ...param
                        }
                    }
                }
            });

            dispatch(setListCategoriesOnEdit({ value: categories }));
            dispatch(setDataCaseOnEdit({ value: data }));
        }
    }

    //Capturar los campos inválidos
    const fieldsInvalid = (e) => {
        const fields = `${e.target.name} - <i>Grupo:</i> <b>${e.target.attributes['aria-label'].nodeValue}</b>`;
        dispatch(setInvalidFields({
            value: fields
        }));
    }

    //Limpiar la lista reducer, de los campos inválidos
    const setCleanInvalidFields = () => {
        dispatch(setCleanFields());
    }

    //Codificar documento Base64
    const encodeDocument = async (e, param, isCategory = false) => {
        const file = e.target.files[0].name.split('.');
        const ext = file[file.length - 1];

        const base64 = await encodeFileBase64(e.target.files[0]);
        if (base64) {
            const archivo = `${base64}--EXTENSION--${ext}`;
            const { id, ...other } = param;
            dispatch(setDataCase({
                key: param.name,
                value: {
                    parameterization_id: param.id,
                    doc: archivo,
                    value: e.target.value,
                    is_category: isCategory,
                    ...other,
                }
            }))
            return;
        }
        MessageError("No es un documento válido");
    }

    const saveData = async (e) => {
        e.preventDefault();
        const data_cases = [];

        for (const key in dataCase) {
            if (dataCase[key].value === "" && dataCase[key].alert_required) {
                const res = await MessageConfirm("Omitir campo", `El campo <b>${dataCase[key].name}</b> no es requerido pero puede ser importante para llevar a cabo un buen proceso de identificación y automatización. ¿Estás realmente seguro de omitir este campo?`);
                if (!res) {
                    return;
                }
            }

            if (dataCase[key].value === "" && !dataCase[key].alert_required) {
                return;
            }

            if (dataCase[key].type_fields_id === FIELD_FILE && !dataCase[key].doc) {
                continue;
            }

            let varTemp = {
                ...dataCase[key],
                is_category: dataCase[key].legal_case_id ? false : true,
            };
            switch (varTemp.type_fields_id) {
                case FIELD_DATETIME:
                    varTemp.value = `${varTemp.value}:00.000Z`;
                    break;
                case FIELD_FILE:
                    varTemp.value = varTemp.doc;
                    break;
                default:
                    break;
            }

            data_cases.push(varTemp);
        }

        dispatch(showLoading());
        const service = new GeneralService("create-case");
        if (id === ID_NOT_DEFINED) {
            const res = await service.post({ data_cases });
            dispatch(hideLoading());
            if (res.is_ok) {
                MessageSuccess(res.message);
                dispatch(cleanData());
                history.push(next);
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update({ id, data_cases });
        dispatch(hideLoading());
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(cleanData());
            history.push(next);
            return;
        }
        MessageError(res.message, service.status);
    }

    return {
        id,
        saveData,
        fieldsInvalid,
        encodeDocument,
        selectedCase,
        listCategories,
    }
}

export default useCreateCase;