import { useRef } from "react";

import ClusterForm from "./ClusterForm";

const TabDataForm = ({ setCurrentStep }) => {
    const refForm = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        setCurrentStep(c => c + 1);
    }

    return (
        <div className="process-tab">
            <div className="row">
                <div className="col-12">
                    <form ref={refForm} className="p-0" onSubmit={handleSubmit}>
                        <ClusterForm refForm={refForm} />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default TabDataForm;