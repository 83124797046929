export const fitText = (texto, longitudMaxima) => {
    if (texto.length <= longitudMaxima) return texto;

    const primeraParteLongitud = 10;
    const segundaParteLongitud = longitudMaxima - primeraParteLongitud - 3;

    const primeraParte = texto.substring(0, primeraParteLongitud);
    const segundaParte = texto.substring(texto.length - segundaParteLongitud);

    return `${primeraParte}...${segundaParte}`;
}

export const cutText = (texto = "", longitudMaxima) => {
    if (texto.length <= longitudMaxima) return texto;

    return texto.substring(0, longitudMaxima)+"...";
}