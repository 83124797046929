import { showNotification } from "./message";

const Axios = require("axios");

const axios = () => {
  const axiosConnect = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,

  });

  axiosConnect.interceptors.request.use(
    function (config) {

      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      if (localStorage.getItem("user")) config.headers["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem("user"))?.token}`
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axiosConnect.interceptors.response.use(
    response => response,
    async (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        setTimeout(() => {
          window.location.pathname = "/auth/login";
          localStorage.removeItem("defaultBusiness");
        }, 3000);
      }
      return Promise.reject(error);
    }
  );

  axiosConnect.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.code === 'ECONNABORTED') {
        window.location.pathname = "/errors/timeout";
        return Promise.reject(new Error('La petición ha expirado. Por favor, inténtalo de nuevo.'));
      }
      return Promise.reject(error);
    }
  );

  return axiosConnect;
};

export default axios();