import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { CardFileUploaded, DropFiles } from '../../../StorageUser/components';

import GeneralService from '../../../../services/GeneralService';
import { MessageError, MessageSuccess, bytesToMegabytes, toast } from '../../../../utils';
import { uploadFilesFirebase } from '../../../../firebase/firebaseStorage';
import { hideLoading, showLoading } from '../../../../reducers/main/loadingReducer';
import confirmDelete from '../../../../utils/confirmDelete';
import { deleteFileFromStorage, setListFilesStorage } from '../../../../reducers/expedient/reducerMovements';

export const ModalFilesMovement = ({ show, setShow }) => {
    const { selectedExpedient } = useSelector(state => state.expedientSlice);
    const { selectedMovement, listFilesStorage } = useSelector(state => state.movementsSlice);
    const { company } = useSelector(state => state.businessSlice);
    const [files, setFiles] = useState([]);
    const [wantUpload, setWantUpload] = useState(false);
    const dispatch = useDispatch();
    const service = new GeneralService("storage-expedient");

    const handleClose = () => {
        setShow(false);
        setWantUpload(false);
    }

    const deleteItemStorage = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFileFromStorage(id));
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleSubmit = async () => {
        dispatch(showLoading());
        const path = `expedients/business_id_${company.id}/expedient_id_${selectedExpedient.id}`;
        const { is_ok, listUpload, message } = await uploadFilesFirebase(files, path);
        if (is_ok) {
            const res = await uploadFilesBD(listUpload);

            if (res.is_ok) MessageSuccess('¡Los archivos se cargaron correctamente :)!');
            else MessageError("Error inesperado", res.message);

            await loadStorage();
        } else {
            await MessageError(message);
        }

        dispatch(hideLoading());
        setFiles([]);
        setWantUpload(false);
    }

    const uploadFilesBD = async (files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const data = {
                url: `https://storage.googleapis.com/${file.metadata.bucket}/${file.metadata.fullPath}`,
                name: file.metadata.name,
                expedients_id: selectedExpedient.id,
                movements_id: selectedMovement.id,
                megabytes_used_space: bytesToMegabytes(file.metadata.size),
            }
            const res = await service.post(data);
            if (!res.is_ok) return res;
        }

        return { is_ok: true };
    }

    const loadStorage = async () => {
        const res = await service.getList(1000, { expedient_id: selectedExpedient.id, movements_id: selectedMovement.id });
        const { storage_expedients = [] } = res;
        let data = storage_expedients.map(item => ({
            ...item,
            path: item.url.replace('https://storage.googleapis.com/npg-storage.appspot.com/', ''),
            type: item.name.split('.').pop(),
        }))
        dispatch(setListFilesStorage(data));
    }

    useEffect(() => {
        loadStorage();

        return () => {
            dispatch(setListFilesStorage([]));
        }
    }, [selectedMovement.id]);

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header className='bg-green pe-4' closeButton>
                <i className="fa-solid fa-cloud-arrow-up animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                <h5 className="d-inline-block mt-2 ms-2 me-1">
                    Archivos -
                </h5>
                {selectedMovement.name}
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    {
                        !wantUpload
                        &&
                        <button
                            className={`btn btn-success d-block mx-auto rounded-3 button-save-document'`}
                            onClick={() => setWantUpload(true)}
                        >
                            ¿Subir nuevos archivos?
                        </button>
                    }
                    {
                        wantUpload
                        &&
                        <div className="row">
                            <div className="col">
                                <DropFiles files={files} setFiles={setFiles} handleSubmit={handleSubmit} />
                            </div>
                        </div>
                    }
                    <div className="row mt-3">
                        <div className="col">
                            <div className="uploaded-files">
                                {
                                    listFilesStorage?.length === 0 ?
                                        <div className="alert bg-light-purple text-center" role="alert">
                                            No hay archivos asociados
                                        </div>
                                        :
                                        listFilesStorage.map((file) => <CardFileUploaded
                                            key={file.id}
                                            file={file}
                                            onDelete={() => confirmDelete(() => deleteItemStorage(file.id))}
                                            showEditAction={false}
                                        />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleClose} className={`btn btn-success ms-auto rounded-3 button-save-document'`}>
                    Listo
                </button>
            </Modal.Footer>
        </Modal>
    )
}