import { useState } from "react";

import FieldTypeDefault from "./FieldTypeDefault";
import FieldTypeText from "./FieldTypeText";
import FieldTypeNumber from "./FieldTypeNumber";
import FieldTypeSelect from "./FieldTypeSelect";
import FieldTypeFile from "./FieldTypeFile";
import FieldTypeTable from "./FieldTypeTable";

const FieldType = ({ param, location, value, onChange, className }) => {
    const [information, setInformation] = useState({ show: false, message: "", isError: false });
    let component;

    switch (param.type_fields_id) {
        case 1:
        case 2:
            component = <FieldTypeNumber
                param={param}
                value={value}
                location={location}
                onChange={onChange}
                onInformation={(e) => setInformation(e)}
                className={`${(information.show && information.isError) && "invalid-field__input"}`}
            />
            break;
        case 6:
            component = <FieldTypeFile
                param={param}
                value={value}
                location={location}
                onChange={onChange}
                onInformation={(e) => setInformation(e)}
                className={`${(information.show && information.isError) && "invalid-field__input"}`} />
            break;
        case 8:
            component = <FieldTypeText
                param={param}
                value={value}
                location={location}
                onChange={onChange}
                onInformation={(e) => setInformation(e)}
                className={`${(information.show && information.isError) && "invalid-field__input"}`} />
            break;
        case 9:
            component = <FieldTypeSelect
                param={param}
                value={value}
                location={location}
                onChange={onChange}
                onInformation={(e) => setInformation(e)}
                className={`${(information.show && information.isError) && "invalid-field__input"}`} />
            break;
        case 10:
            component = <FieldTypeTable
                param={param}
                value={value}
                location={location}
                onChange={onChange}
                onInformation={(e) => setInformation(e)}
                className={`${(information.show && information.isError) && "invalid-field__input"}`} />
            break;
        default:
            component = <FieldTypeDefault
                param={param}
                value={value}
                location={location}
                onChange={onChange}
                onInformation={(e) => setInformation(e)}
                className={`${(information.show && information.isError) && "invalid-field__input"}`} />
            break;
    }

    return (
        <div className={className}>
            <label className="steper-input-label">
                {param.name}
                {param.required && <i style={{ color: 'red', fontWeight: 'bold' }}>*</i>}
            </label>
            {component}
            {
                information.show &&
                <div className="row">
                    <div className={`col invalid-field__form ${information.isError ? "text-red" : ""}`}>
                        {information.message}
                    </div>
                </div>
            }
        </div>
    )
}

export default FieldType;
