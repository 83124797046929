import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { SearchBar } from "../../../../app/components/SearchBar";
import { SignatureLegend } from "../../../TercerosCreate/components/SignatureLegend";
import IconButton from "../../../../app/components/IconButton";
import { ModalMovement } from "./ModalMovement";
import { Color } from "../../../../app/components/Color";
import { ModalAssociateDocument } from "./ModalAssociateDocument";
import { ModalFilesMovement } from "./ModalFilesMovement";

import GeneralService from "../../../../services/GeneralService";
import { cleanData, deleteFromMovement, orderMovement, reducerFormSearch, setList, setSelected, updateFromMovement } from "../../../../reducers/expedient/reducerMovements";
import { setUsers } from "../../../../reducers/user/reducerUsers";
import confirmDelete from "../../../../utils/confirmDelete";
import { MessageError, cutText, formatDate, isURL, showAnimateOrderItem, toast } from "../../../../utils";
import { useAnimateBackground } from "../../../../app/hooks/useAnimateBackground";
import { ALERT_WARNING, ID_NOT_DEFINED } from "../../../../constant/constant";
import { resetCache, setListDocuments } from "../../../../reducers/documents/reducerGenerateDocument";
import { useDownloadFile } from "../../../../app/hooks/useDownloadFile";

export const ContentMovements = () => {
    const { selectedExpedient } = useSelector(state => state.expedientSlice);
    const { listFilter } = useSelector(state => state.movementsSlice);
    const { list } = useSelector(state => state.colorsSlice);
    const [show, setShow] = useState(false);
    const [showAssociateDocument, setShowAssociateDocument] = useState(false);
    const [showAssociateFiles, setShowAssociateFiles] = useState(false);
    const handleDownload = useDownloadFile();
    const dispatch = useDispatch();
    const history = useHistory();
    const [showEffect, setShowEffect] = useAnimateBackground();
    const service = new GeneralService("movement");

    const deleteMovement = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteFromMovement(id));
        }
    }

    const disociateDocument = async (movement) => {
        const service = new GeneralService("movement/associate-document");
        const res = await service.post({ id: movement.id, generate_document_id: ID_NOT_DEFINED });
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(updateFromMovement({ ...movement, generate_document_id: ID_NOT_DEFINED, document: undefined }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    const loadMovements = async () => {
        const res = await service.getList(1000, { expedients_id: selectedExpedient.id });
        const { movements = [] } = res;
        dispatch(setList(movements));
    }

    const loadTypeDocuments = async () => {
        const service = new GeneralService("documents-auto");
        const res = await service.getList(1000);
        const { documents = [] } = res;
        dispatch(setListDocuments({ value: documents }));
    }

    const loadUsers = async () => {
        const service = new GeneralService("user");
        const res = await service.getList(0, {page: 0, page_size: 1000});
        const { profile = [] } = res;
        dispatch(setUsers(profile));
    }

    const handleMove = async (id, order) => {
        const service = new GeneralService("movement/order");
        const res = await service.post({ id, order });
        if (res.is_ok) {
            dispatch(orderMovement({ value: { id, order } }));
            setShowEffect({ show: true, id });
            return;
        }
        MessageError(res.message, service.status);
    }

    const signedFile = (generatedDocument) => {
        if (isURL(generatedDocument.url_signed_file)) {
            window.open(generatedDocument.url_signed_file);
            return;
        }
        toast(dispatch, "Este documento no ha sido firmado por nadie", ALERT_WARNING);
    }

    const generatedFile = (generatedDocument) => {
        if (isURL(generatedDocument.url_pdf)) {
            handleDownload(generatedDocument.url_pdf);
            return;
        }
        toast(dispatch, "Este documento no ha sido generado", ALERT_WARNING);
    }

    const settingStatus = (doc) => {
        const signers = doc.actors?.length;
        let counter = 0;
        doc.actors?.forEach(actor => {
            if (actor.status === "signed") counter++;
        });

        return doc.status === "signed"
            ?
            <><i role="button" title="Firmado" className="fa-solid fa-file-circle-check text-success"></i> {counter}/{signers}</>
            :
            <><i role="button" title="No firmado" className="fa-solid fa-file-circle-xmark text-orange"></i> {counter}/{signers}</>;
    }

    const handleEditDocument = async (document) => {
        history.push("/documents-auto/generate/" + document.documents_auto_id + "?process=" + document.id);
    }

    const onEdit = (movement) => {
        dispatch(cleanData());
        dispatch(setSelected(movement));
        setShow(true);
    }

    useEffect(() => {
        loadMovements();
        loadUsers();
        loadTypeDocuments();
    }, [selectedExpedient.id]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-5">
                    <SearchBar onChange={onSearch} />
                </div>
                <div className="col">
                    <button title="Crear parte"
                        className="btn btn-success d-block ms-auto" type="button"
                        onClick={() => { dispatch(cleanData()); setShow(true); }}
                    >
                        <i className="fa-solid fa-arrows-to-dot text-white me-2" />
                        Añadir
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="table-data">
                        <div className="order">
                            {
                                listFilter.length === 0 ?
                                    <div className="alert alert-warning text-center mt-2" role="alert">
                                        No hay movimientos disponibles
                                    </div>
                                    :
                                    <div className="d-block rounded-3 clip-hide">
                                        <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                            <thead>
                                                <tr className="font-lato">
                                                    <th></th>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Nombre</th>
                                                    <th>Fecha</th>
                                                    <th>Status</th>
                                                    <th className="text-center">Documento</th>
                                                    <th>Acciones</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listFilter.map((movement, i) =>
                                                    (<tr
                                                        key={movement.id}
                                                        className={`font-rubik change-background-animation ${showAnimateOrderItem(movement, showEffect)}`}
                                                        onDoubleClick={() => onEdit(movement)}
                                                    >
                                                        <td></td>
                                                        <td>{i + 1}</td>
                                                        <td>
                                                            <Color color={list.find(itemColor => itemColor.id === movement.colors_id)?.color} />
                                                        </td>
                                                        <td>{cutText(movement.name, 50)}</td>
                                                        <td>{formatDate(movement.wait_time_days)}</td>
                                                        <td className="fs-7">{list.find(itemColor => itemColor.id === movement.colors_id)?.status}</td>
                                                        <td className="text-center">
                                                            {
                                                                movement.documents_auto_id
                                                                    ?
                                                                    (movement.generate_document_id ?
                                                                        <>
                                                                            {
                                                                                movement.document?.needs_zapsign
                                                                                &&
                                                                                settingStatus(movement.document)
                                                                            }
                                                                            <IconButton
                                                                                icon="fa-solid fa-file-arrow-down text-green"
                                                                                title="Descargar documento original"
                                                                                onClick={() => generatedFile(movement.document)}
                                                                            />
                                                                            <IconButton
                                                                                icon="fa-solid fa-file-contract text-purple"
                                                                                title="Descargar documento firmado"
                                                                                onClick={() => signedFile(movement.document)}
                                                                            />
                                                                            <IconButton
                                                                                icon="fa-solid fa-file-pen text-black"
                                                                                title="Editar documento"
                                                                                onClick={() => handleEditDocument(movement.document)}
                                                                            />
                                                                            <IconButton
                                                                                icon="fa-solid fa-circle-xmark text-red"
                                                                                title="Desasociar documento"
                                                                                onClick={async () => await confirmDelete(() => disociateDocument(movement))}
                                                                            />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <IconButton
                                                                                icon="fa-solid fa-file-export text-orange"
                                                                                title="Asociar documento"
                                                                                onClick={() => { dispatch(setSelected(movement)); setShowAssociateDocument(true); }}
                                                                            />
                                                                            <IconButton
                                                                                icon="fa-solid fa-circle-plus text-green"
                                                                                title="Crear y asociar documento"
                                                                                onClick={() => {
                                                                                    dispatch(resetCache());
                                                                                    history.push(`/documents-auto/generate/${movement.documents_auto_id}?expedient=${selectedExpedient.id}&movement=${movement.id}&next=${window.location.pathname + window.location.search}`)
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )
                                                                    :
                                                                    "----------------"
                                                            }
                                                        </td>
                                                        <td>
                                                            <IconButton
                                                                icon="fa-solid fa-cloud-arrow-up text-purple"
                                                                title="Archivos asociados al movimiento"
                                                                onClick={() => { dispatch(setSelected(movement)); setShowAssociateFiles(true) }}
                                                            />
                                                            <IconButton
                                                                icon="fa-solid fa-pen-to-square text-green"
                                                                title="Editar movimiento"
                                                                onClick={() => onEdit(movement)}
                                                            />
                                                            <IconButton
                                                                icon="fa-solid fa-trash-can text-red"
                                                                title="Eliminar movimiento"
                                                                onClick={async () => await confirmDelete(() => deleteMovement(movement.id))}
                                                            />
                                                            <div className="move-parameterization">
                                                                <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(movement.id, (i - 1))}></i>
                                                                <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(movement.id, (i + 1))}></i>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <SignatureLegend />
            <ModalMovement show={show} setShow={setShow} />
            <ModalAssociateDocument show={showAssociateDocument} setShow={setShowAssociateDocument} />
            <ModalFilesMovement show={showAssociateFiles} setShow={setShowAssociateFiles} />
        </div>
    )
}
