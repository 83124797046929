import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../../app/components/IconButton";
import { OverlayTrigger, Popover } from 'react-bootstrap';

import GeneralService from "../../../services/GeneralService";

import "../styles/mailStyle.css"
import { clearNewEmail, deleteEmail, setListReceived, setSelectedEmail } from "../../../reducers/mails/reducerMails";
import CardDraftEmail from "./CardDraftEmail";
import FormEmail from "./FormEmail";
import CardSentEmail from "./CardSentEmail";
import CardReceivedEmail from "./CardReceivedEmail";
import { toast } from "../../../utils/toast";
import { MessageConfirm, MessageError } from "../../../utils/message";
import { formatDate } from "../../../utils/formatDate";

const Mails = () => {
    const { received, selectedEmail } = useSelector(state => state.mailSlice);
    const [showCreate, setShowCreate] = useState(false);
    const [showDetailEmail, setShowDetailEmail] = useState(false);
    const [show, setShow] = useState(false);
    const [typeEmail, setTypeEmail] = useState("received");
    const dispatch = useDispatch();

    const loadMails = async (type) => {
        const service = new GeneralService("email/" + type);
        const res = await service.getList(1000);
        const { emails = [] } = res;
        dispatch(setListReceived({ value: emails }));
    }

    const handleCreateEmail = () => {
        dispatch(clearNewEmail());
        setShowCreate(true);
    }

    const handleDraft = (email) => {
        dispatch(setSelectedEmail({ value: email }));
        setShowCreate(true);
    }

    const handleSent = (email) => {
        dispatch(setSelectedEmail({ value: email }));
        setShowDetailEmail(true);
    }

    const handleDelete = async (email) => {
        const confirm = await MessageConfirm("¿Estás seguro de eliminar este email?");
        if (confirm) {
            const service = new GeneralService("email");
            const res = await service.delete(email.id);
            if (res.is_ok) {
                toast(dispatch, res.message);
                dispatch(deleteEmail({ value: email.id }));
                return;
            }
            MessageError(res.message, service.status);
        }

    }

    const renderCardEmail = (email) => {
        switch (typeEmail) {
            case "drafts":
                return <CardDraftEmail
                    key={email.id}
                    email={email}
                    onClick={() => handleDraft(email)}
                    onDelete={handleDelete}
                />
            case "sent":
                return <CardSentEmail
                    key={email.id}
                    email={email}
                    onClick={() => handleSent(email)}
                    onDelete={handleDelete}
                />
            default:
                return <CardReceivedEmail
                    key={email.id}
                    email={email}
                    onClick={() => handleSent(email)}
                    onDelete={handleDelete}
                />
        }
    }

    const handleRefresh = async () => {
        await loadMails(typeEmail);
        toast(dispatch, "Información actualizada");
    }

    const recipients = () => {
        let sentTo = "";
        selectedEmail.to.forEach(item => {
            sentTo += item.name + ", ";
        });
        return sentTo.slice(0, -2);
    }

    useEffect(() => {
        loadMails(typeEmail);
    }, [typeEmail]);

    return (
        <div className="container-fluid mx-0 max-height-overflow-y" style={{ position: "relative" }}>
            <div className="row">
                <div className="col-3">
                    <IconButton
                        title="Cerrar"
                        icon="fa-solid fa-bars fs-4"
                        className="me-2"
                    />
                    <span className="fs-4 font-lilita-one">Correo</span>
                </div>
                <div className="col-9">

                </div>
            </div>
            <div className="row mt-2">
                <div className="col-2">
                    <div className="row">
                        <div className="col">
                            <button onClick={handleCreateEmail} className="btn btn-success d-block w-100">
                                <i className="fa-solid fa-plus me-1"></i> Redactar correo
                            </button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col px-0">
                            <div className="menu-bar">
                                <div onClick={() => { setShowDetailEmail(false); setTypeEmail("received") }} className={`item-menu ${typeEmail === "received" ? "active" : ""}`}>
                                    <div className="row">
                                        <div className="col-1">
                                            <i className="fa-solid fa-envelope-open"></i>
                                        </div>
                                        <div className="col-6">
                                            Recibidos
                                        </div>
                                        <div className="col-3">
                                            {typeEmail === "received" && received.length}
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => { setShowDetailEmail(false); setTypeEmail("sent") }} className={`item-menu ${typeEmail === "sent" ? "active" : ""}`}>
                                    <div className="row">
                                        <div className="col-1">
                                            <i className="fa-solid fa-paper-plane"></i>
                                        </div>
                                        <div className="col">
                                            Enviados
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => { setShowDetailEmail(false); setTypeEmail("drafts") }} className={`item-menu ${typeEmail === "drafts" ? "active" : ""}`}>
                                    <div className="row">
                                        <div className="col-1">
                                            <i className="fa-solid fa-file"></i>
                                        </div>
                                        <div className="col">
                                            Borradores
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <div className="list-emails">
                        {
                            !showDetailEmail
                                ?
                                <>
                                    <div className="row">
                                        <div className="col">
                                            <IconButton
                                                title="Recargar"
                                                icon="fa-solid fa-rotate-right"
                                                onClick={handleRefresh}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            {
                                                received.length === 0
                                                    ?
                                                    <div className="alert bg-white text-center" role="alert">
                                                        No hay mensajes <i className='fa-solid fa-envelope text-green'></i>
                                                    </div>
                                                    :
                                                    received.map(renderCardEmail)
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="row">
                                        <div className="col-10">
                                            <IconButton
                                                title="Retroceder"
                                                icon="fa-solid fa-arrow-left"
                                                className="me-2"
                                                onClick={() => setShowDetailEmail(false)}
                                            />
                                            <h5 className="d-inline-block">{selectedEmail.subject}</h5>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                    <Popover style={{ minWidth: "25rem", maxHeight: "25rem" }} id={`popover-positioned`}>
                                                        <Popover.Header as="h3" className='bg-light-purple text-center'><span className='text-purple'>Detalles</span></Popover.Header>
                                                        <Popover.Body>
                                                            <div className="row">
                                                                <div className="col-3 fw-bold">
                                                                    De:
                                                                </div>
                                                                <div className="col fw-bold">
                                                                    {selectedEmail.sender_name}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-3 fw-bold">
                                                                    Para:
                                                                </div>
                                                                <div className="col">
                                                                    {recipients()}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-3 fw-bold">
                                                                    Fecha:
                                                                </div>
                                                                <div className="col">
                                                                    {formatDate(selectedEmail.date_sent)}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-3 fw-bold">
                                                                    Asunto:
                                                                </div>
                                                                <div className="col">
                                                                    {selectedEmail.subject}
                                                                </div>
                                                            </div>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                                show={show}
                                            >
                                                <i
                                                    title="Mostrar detalles"
                                                    className="fa-solid fa-circle-chevron-down text-purple ms-3"
                                                    role="button"
                                                    onClick={() => setShow(c => !c)}
                                                ></i>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="col text-end me-1 text-purple">
                                            {formatDate(selectedEmail.date_sent)}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col px-4 pt-2">
                                            <span className="d-block fw-bold pb-2">{selectedEmail.sender_name}</span>
                                            {selectedEmail.content}
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
            <FormEmail
                showCreate={showCreate}
                setShowCreate={setShowCreate}
                onChange={() => loadMails(typeEmail)}
            />

        </div >
    )
}

export default Mails;