
export const stylesEditorTinyMCE = (addStyle = "") => {
    return `
    ::-webkit-scrollbar {
        width: 5px;
    }
    
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #499e6e;
        border-radius: 5px;
    }
    
    ::-moz-scrollbar {
        width: 5px;
        border-radius: 5px;
    }
    
    ::-moz-scrollbar-track {
        background: #f1f1f1;
    }
    
    ::-moz-scrollbar-thumb {
        background: #499e6e;
        border-radius: 5px;
    }
    
    body {
        background: #fff;
    }

    @media (min-width: 840px) {
        html {
            background: #eceef4;
            min-height: 100%;
            padding: 0 .5rem
        }

        body {
            background-color: #fff;
            box-shadow: 0 0 4px rgba(0, 0, 0, .15);
            box-sizing: border-box;
            margin: 1rem auto 0;
            max-width: 820px;
            min-height: calc(100vh - 1rem);
            padding:6rem;
            font-family:Helvetica,Arial,sans-serif; font-size:14px;
        }
    }

    ${addStyle}
    `;
}

export const toolbarEditorTinyMCE = (addItems = "") => {
    return `undo redo | blocks |
    bold italic backcolor | alignleft aligncenter
    alignright alignjustify | bullist numlist outdent indent |
    removeformat | help | ${addItems}`;
}

export const pluginsTinyMCE = (add = []) => [
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'help', 'wordcount', 'quickbars', "emoticons", ...add
];