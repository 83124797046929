import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BreadcrumbCustom from '../../../app/components/BreadcrumCustom';
import { SearchBar } from '../../../app/components/SearchBar';
import ListForm from './ListForm';
import ModalForm from './ModalForm';

import { cleanData, searchForm } from '../../../reducers/forms/reducerFormSlice';

const Form = () => {
    const { company } = useSelector(state => state.businessSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const handleViewForms = () => {
        if (company.is_system_admin) window.location.href = "/virtual-notary/";
        else window.location.href = "/forms/" + company.name;
    }

    const onSearch = (e) => {
        dispatch(searchForm(e.target.value));
    }

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Formularios"
                        activeRoute="/ Lista"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <SearchBar onChange={onSearch} />
                </div>
                <div className="col-6">
                    <button title="Crear nuevo tipo de persona" className="btn btn-success mx-2" type="button" onClick={() => { setShow(true); dispatch(cleanData()); }}>
                        <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                        Crear formulario
                    </button>
                    <button title="Ver los formularios que has publicado" className="btn btn-success mx-2" type="button" onClick={handleViewForms}>
                        <i className="fa-solid fa-link text-white me-2" style={{ fontSize: '16px' }} />
                        Ver publicados
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="table-data" >
                        <ListForm setShow={setShow} />
                    </div>
                </div>
            </div>
            <ModalForm show={show} setShow={setShow} />
        </div>
    )
}
export default Form;
