import { useDispatch } from "react-redux"

import { downloadFileAsBytes } from "../../firebase/firebaseStorage";
import { downloadFileFromArrayBuffer, toast } from "../../utils";
import { ALERT_DANGER } from "../../constant/constant";

export const useDownloadFile = () => {
    const dispatch = useDispatch();

    const handleDownload = async (url, name) => {
        if(!name) name = url.split("/").pop();

        const arrayBuffer = await downloadFileAsBytes(url);
        if (!arrayBuffer) return toast(dispatch, "No fue posible descargar el archivo", ALERT_DANGER);
        downloadFileFromArrayBuffer(arrayBuffer, name);
    }

    return handleDownload;
}
