import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import BreadcrumbCustom from '../../../../app/components/BreadcrumCustom';
import SearchCategoryLegalCase from './SearchCategoryLegalCase';
import ListCategoryLegalCase from './ListCategoryLegalCase';
import ModalCategoryLegalCase from './ModalCategoryLegalCase';

import { cleanData, setListCategory } from '../../../../reducers/legalCase/reducerCategoryLegalCase';
import { setSelectedCase } from '../../../../reducers/legalCase/reducerLegalCase';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import GeneralService from '../../../../services/GeneralService';

const CategoryLegalCase = () => {
    const { list, selectedCase } = useSelector(state => state.legalCaseSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { legalCase = 0 } = queryString.parse(search);

    const loadData = async (id) => {
        const service = new GeneralService("category-legal-case");

        if (selectedCase.id === ID_NOT_DEFINED) {
            dispatch(setSelectedCase({ value: list.find(i => i.id === parseInt(id)) }));
            const res = await service.getList(1000, { legal_case_id: legalCase });
            const { categories = [] } = res;
            dispatch(setListCategory({ value: categories }));
            return;
        }
        const res = await service.getList(1000, { legal_case_id: selectedCase.id });
        const { categories = [] } = res;
        dispatch(setListCategory({ value: categories }));
    }

    useEffect(() => {
        loadData(legalCase);
    }, [legalCase, list]);

    return (
        <>
            <div className='mx-4'>
                <BreadcrumbCustom
                    title="Categorías"
                    routes={[
                        { name: "Tipos de caso", link: "/case-parameterization/list" },
                        { name: selectedCase.name ? selectedCase.name : "Seleccione tipo de caso", link: "/case-parameterization/list" },
                    ]}
                    activeRoute="Lista categorías"
                />

                <div className="container m-0 p-0">
                    <div className="row">
                        <div className="col-8">
                            <SearchCategoryLegalCase />
                        </div>
                        <div className="col-4">
                            <button title="Crear nueva categoría" className="btn btn-success mt-1" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                                <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                                Crear categoría
                            </button>
                        </div>
                    </div>
                </div>

                <div className="table-data">
                    <ListCategoryLegalCase setShow={setShow} />
                </div>
            </div>
            <ModalCategoryLegalCase show={show} setShow={setShow} />
        </>
    )
}

export default CategoryLegalCase;