import { createSlice } from "@reduxjs/toolkit";

export const expedientGroupsSlice = createSlice({
    name: "expedientGroupsSlice",
    initialState: {
        selectedGroup: {
            id: 0,
            name: "",
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, action) => {
            state.selectedGroup[action.payload.key] = action.payload.value;
        },
        reducerFormSearch: (state, action) => {
            if (action.payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromGroup: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload);
        },
        updateFromGroup: (state, action) => {
            const code = action.payload;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromGroup: (state, action) => {
            state.list.push(action.payload);
            state.listFilter.push(action.payload);
        },
        setSelected: (state, action) => {
            state.selectedGroup = action.payload;
        },
        cleanData: (state, action) => {
            state.selectedGroup = {
                id: 0,
                name: "",
            };
        },        
        orderGroup: (state, action) => {
            const { id, order } = action.payload.value;
            const currentIndex = state.listFilter.findIndex(e => e.id === id);
            const element = state.listFilter[currentIndex];

            state.listFilter.splice(currentIndex, 1);
            state.listFilter.splice(order, 0, element);
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromGroup,
    updateFromGroup,
    insertFromGroup,
    setSelected,
    cleanData,
    orderGroup,
} = expedientGroupsSlice.actions;

export default expedientGroupsSlice.reducer;