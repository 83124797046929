import './styles/stepper-form.css';

const StepNavigationGroups = ({ items = [], currentStep, updateStep, ref }) => {

    return (
        <div style={{ opacity: ".999" }}>
            <div ref={ref} className={`stepWrapper ${items.length > 7 ? "justify-start" : "justify-center"}`}>
                {
                    items?.map((item, index) => {
                        return (
                            <div key={index} className={`stepBlock ${currentStep === index ? "selected" : ""}`}>
                                <div className="circleWrapper" onClick={() => updateStep(index)}>
                                    <div className="circle"><strong>{index + 1}</strong></div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default StepNavigationGroups;