export const time = {
    Month: 30,
    Day: 1,
    Year: 365,
};

export const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const getMonthName = (date)=>{
    const month = new Date(date).getMonth();
    return monthNames[month];
}

export const getTimeAgo = (days) => {
    const now = new Date();
    now.setDate(now.getDate() - days);
    return now;
}