import { createSlice } from "@reduxjs/toolkit";

export const copyGroupSlice = createSlice({
    name: 'copyGroup',
    initialState: {
        dataCopyGroup: {
            cluster_id: 0,
            cluster_is_category: false,
        },
    },

    reducers: {
        setData: (state, action) => {
            state.dataCopyGroup = action.payload.value;
        },
        cleanData: (state) => {
            state.dataCopyGroup = {
                cluster_id: 0,
                cluster_is_category: false,
            };
        },
    },
});

export const {
    setData,
    cleanData,
} = copyGroupSlice.actions;

export default copyGroupSlice.reducer;