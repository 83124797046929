import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import StepNavigationCategoryGroups from "./StepNavigation/stepNavigationCategoryGroups";
import { aceptFile } from "../../utils/aceptFile";

import { setDeleteItemOfCategoriesList } from "../../../../reducers/createCase/reducerCreateCase";
import confirmDelete from "../../../../utils/confirmDelete";
import FieldType from "./FieldType";

const CaseCategoriesClusters = ({ category, clusters = [], encodeDocument, id }) => {
    const { dataCase } = useSelector(state => state.createCaseSlice);
    const [stepActual, setStepActual] = useState(1);
    const dispatch = useDispatch();

    const updateStep = (step) => {
        setStepActual(step);
    }

    const handleDelete = async (confirm) => {
        await confirmDelete(confirm);
    }

    const deleteCategoryFromList = (id) => {
        dispatch(setDeleteItemOfCategoriesList({ value: id }));
    }

    return (
        <>
            <div className="container-fluid m-0 p-5 rounded-4 people-principal-container">

                <div className="row m-0 p-0 ">
                    <div className="col p-0">
                        <button type="button" className='button-delete-category-of-list'
                            onClick={() => handleDelete(() => deleteCategoryFromList(category.id))}
                        >
                            <strong>Eliminar</strong>
                        </button>
                    </div>
                </div>

                <div className="row m-0 stepper">
                    <StepNavigationCategoryGroups
                        clusters={clusters}
                        currentStep={stepActual}
                        updateStep={updateStep}>
                    </StepNavigationCategoryGroups>

                    <div className="container-fluid m-0 p-0 stepform-contenedor-campos">
                        <div className="row m-0 p-0">
                            <div className="col p-0">
                                {
                                    clusters?.map((cluster, index) => (
                                        <div key={cluster.id} className={`${stepActual === index + 1 ? "show-form" : "hide-form"}`}>
                                            <h3 style={{ textAlign: "center", margin: "8px 0px 10px 0px" }}>
                                                {cluster.name}
                                            </h3>
                                            <small style={{ display: 'block', textAlign: "center" }}>
                                                Los campos con asterísco son obligatorios
                                                <i style={{ color: 'red', fontWeight: 'bold' }}>*</i>
                                            </small>

                                            <div className="row m-0 p-0 mt-4">
                                                {
                                                    cluster.parameterizations?.length === undefined
                                                        ?
                                                        <div className="alert alert-warning" role="alert" style={{ marginBottom: '0px', margin: '20px 12px' }}>
                                                            ¡No hay parametrizaciones para este grupo, selecciona otro!
                                                        </div>
                                                        :
                                                        cluster.parameterizations?.map((param, index) => (
                                                            <div key={index} className="col-4 mt-3">
                                                                <label className="steper-input-label">
                                                                    {param.name}{param.required && <i style={{ color: 'red', fontWeight: 'bold' }}>*</i>}
                                                                </label>
                                                                <FieldType
                                                                    param={param}
                                                                    id={id}
                                                                    cluster={cluster}
                                                                    dataCase={dataCase}
                                                                    aceptFile={aceptFile}
                                                                    encodeDocument={encodeDocument}
                                                                />
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-0 p-0 mt-5">
                    <div className="col buttons-stepper">
                        <button type="button" className="btn btn-success rounded-3 mx-2 px-3 py-2" disabled={stepActual === 1}
                            onClick={() => updateStep(stepActual - 1)}>
                            <i className="fa-solid fa-caret-left"></i> Atrás
                        </button>
                        <button type="button" className="btn btn-success rounded-3 mx-2 px-3 py-2"
                            onClick={() => updateStep(stepActual + 1)}
                            disabled={stepActual === clusters?.length}
                        >
                            Siguiente <i className="fa-solid fa-caret-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CaseCategoriesClusters; 