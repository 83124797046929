import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Modal, } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';

import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';

import { MessageConfirm, MessageError, MessageSuccess } from '../../utils/message';
import { cleanData } from '../../reducers/copyClusters/reducerCopyGroup';
import GeneralService from '../../services/GeneralService';
import './styles/style-copy-clusters.css';

const ModalCopyGroup = ({ show, setShow, model }) => {
    const { list: listTypepeople } = useSelector(state => state.typepeopleSlice);
    const { list: listCase } = useSelector(state => state.legalCaseSlice);
    const { dataCopyGroup } = useSelector(state => state.copyGroupSlice);
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    let list = [];

    if(model === "TYPE_PEOPLE") {
        list = listTypepeople.map(typepeople=>{
            return {
                ...typepeople,
                categories: typepeople.category_peoples ? typepeople.category_peoples : [],
            };
        })
    } else {
        list = listCase;
    }

    const handleCopy = async (paste_to_id, is_category) => {
        const data = {
            cluster_id: dataCopyGroup.cluster_id,
            cluster_is_category: dataCopyGroup.cluster_is_category,
            paste_to_id,
            is_category,
            model,
        };
        const service = new GeneralService("copy-cluster")
        const res = await service.post(data);
        if (res.is_ok) {
            handleClose();
            MessageSuccess(res.message);
            dispatch(cleanData());
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered size='lg'>
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                <i className="fa-solid fa-copy mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                            </div>
                            <div className='col mt-2 p-0'>
                                <h5>¿Dónde copiar el grupo?</h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='modal-body-copy-group p-4 pt-3 pb-5'>
                    <div className='container-fluid'>
                        {
                            list?.length === 0 ?
                                <div className="alert alert-warning text-center mt-2" role="alert">
                                    No hay colecciones disponibles
                                </div>
                                :
                                <>
                                    {
                                        list?.map((tipo) => {
                                            return (
                                                <Fragment key={tipo.id}>
                                                    <div className='row'>
                                                        <div className='col-10'>
                                                            <Accordion
                                                                className="m-0 p-0 accordion-copy-group"
                                                                defaultActiveKey={['0']}
                                                                alwaysOpen={false}
                                                                flush
                                                            >
                                                                <AccordionItem
                                                                    className="accordion-item-copy-group"
                                                                    eventKey={tipo.id}
                                                                >
                                                                    <AccordionHeader className="accordion-header-copy-group">
                                                                        <div className='container-fluid p-0'>
                                                                            <div className='row m-0 px-3'>
                                                                                <div className='col px-0'>
                                                                                    <span className='spanLetter' style={{ fontSize: '17px' }}>
                                                                                        {tipo.name}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </AccordionHeader>
                                                                    <AccordionBody className='accordion-body-copy-group m-0 p-0'>
                                                                        <div className='container-fluid bg-white text-dark py-2'>
                                                                            {
                                                                                !tipo.categories ?
                                                                                    <div className="alert alert-warning" role="alert" style={{ marginBottom: '0px', margin: '10px 0px' }}>
                                                                                        ¡No hay categorías asociadas!
                                                                                    </div>
                                                                                    :
                                                                                    tipo.categories?.map((category) => {
                                                                                        return (
                                                                                            <Fragment key={category.id}>
                                                                                                <div className='row m-0 my-2' style={{ height: '40px' }}>
                                                                                                    <div className='col-9 px-4 pt-2'>
                                                                                                        <span>{category.name}</span>
                                                                                                    </div>
                                                                                                    <div className='col'>
                                                                                                        <button className="btn btn-success button-paste-category"
                                                                                                            title='Pegar en esta categoría'
                                                                                                            type='button'
                                                                                                            onClick={async () => {
                                                                                                                const res = await MessageConfirm('¿Desea pegar el grupo en esta categoría?');
                                                                                                                if (res) {
                                                                                                                    await handleCopy(category.id, true);
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fa-regular fa-clipboard" style={{ fontSize: '18px' }}></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr style={{ margin: '8px 0px' }}></hr>
                                                                                            </Fragment>
                                                                                        );
                                                                                    })
                                                                            }
                                                                        </div>
                                                                    </AccordionBody>
                                                                </AccordionItem>
                                                            </Accordion>
                                                        </div>

                                                        <div className='col pt-3'>
                                                            <button className="btn btn-success button-paste-persona"
                                                                title='Pegar en esta persona'
                                                                type='button'
                                                                onClick={async () => {
                                                                    const res = await MessageConfirm('¿Desea pegar el grupo en este apartado?');
                                                                    if (res) {
                                                                        await handleCopy(tipo.id, false);
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fa-regular fa-clipboard" style={{ fontSize: '23px' }}></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Fragment>

                                            )
                                        })
                                    }
                                </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalCopyGroup;
