import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import GeneralService from '../../../services/GeneralService';
import { ID_NOT_DEFINED } from '../../../constant/constant';
import { MessageError } from '../../../utils/message';
import { toast } from '../../../utils/toast';
import { insertFromGroup, reducerForm, updateFromGroup } from '../../../reducers/configuration/reducerExpedientGroups';

const ModalExpedientGroup = ({ show, setShow }) => {
    const { selectedGroup } = useSelector(state => state.expedientGroupsSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("expedient-groups");

        if (selectedGroup.id === ID_NOT_DEFINED) {
            const res = await service.post(selectedGroup);
            if (res.is_ok) {
                setShow(false);
                toast(dispatch, res.message);
                dispatch(insertFromGroup(res.group));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(selectedGroup);
        if (res.is_ok) {
            setShow(false);
            toast(dispatch, res.message);
            dispatch(updateFromGroup(selectedGroup));
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedGroup.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedGroup.id === ID_NOT_DEFINED ?
                                            "Crear Grupo"
                                            :
                                            "Editar Grupo"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className='col mb-3'>
                                <label>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control green-input mt-1"
                                    name="name"
                                    value={selectedGroup.name}
                                    maxLength={45}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-layer-group me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedGroup.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalExpedientGroup;