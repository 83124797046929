import { Color } from "../../../../app/components/Color";

export const loadOptionColor = (listColors = [], movement) => {
    const itemColor = listColors.find(color => color.id === movement.colors_id);
    if (!itemColor) return undefined;

    return {
        value: movement.colors_id,
        label: <div className="select-label">
            <span>{itemColor.status}</span>
            <Color color={itemColor.color} />
        </div>
    };
}

export const loadOptionDocument = (listDocuments = [], movement) => {
    const empty = {
        value: undefined,
        label: "Ninguno",
    };

    if (!movement.documents_auto_id) return empty;

    const documentItem = listDocuments.find(document => document.id === movement.documents_auto_id);
    if(!documentItem) return empty;
    
    return {
        value: movement.documents_auto_id,
        label: documentItem.name,
    };
}

export const loadOptionLabel = (listLabels = [], part) => {
    const labelItem = listLabels.find(label => label.id === part.label_global_id);
    if (!labelItem) return undefined;

    return {
        value: part.label_global_id,
        label: labelItem.name,
    };
}

export const loadOptionUser = (listUsers = [], movement) => {
    const empty = {
        value: undefined,
        label: "Ninguno",
    };

    if (!movement.user_id) return empty;

    const user = listUsers.find(user => user.id === movement.user_id);
    if (!user) return empty;
    return {
        value: movement.user_id,
        label: user.name + " " + user.surname,
    };
}