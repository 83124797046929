import { Accordion } from "react-bootstrap";

import ItemCardGroup from "./ItemCardGroup";

const AccordionActorsClusters = ({ form, insertCode }) => {

    const insertCodeWithLabel = (content) => {
        insertCode(`@${content}`);
    }

    return (
        <Accordion
            className="m-0 p-0"
            defaultActiveKey={['0']}
            alwaysOpen
            flush
        >
            <Accordion.Item
                eventKey="main"
            >
                <Accordion.Header className="p-2 accordion-header">
                    <div className='container-fluid p-0'>
                        <div className='row m-0 px-2'>
                            <div className='col-2 m-0 p-0'>
                                <i className="fa-solid fa-rectangle-list fs-4 mt-1 text-black"></i>
                            </div>
                            <div className='col-10 m-0 p-0'>
                                <small className='smallLetter'>Formulario</small>
                                <br></br>
                                <span className='spanLetter'>{form?.name}</span>
                            </div>
                        </div>
                    </div>
                </Accordion.Header>

                <Accordion.Body className="m-0 p-0 pt-3">
                    <ItemCardGroup
                        list={form.clusters}
                        name="Grupos"
                        iconHeader="fa-solid fa-layer-group text-green fs-5"
                        iconItem="fa-solid fa-square-poll-horizontal fs-5 mt-2 text-purple"
                        textItem="Grupo"
                        keyGroups="item-groups-main"
                        keyByGroup="main-group"
                        insertCode={insertCodeWithLabel}
                        />
                    <ItemCardGroup
                        list={form.actors}
                        name="Actores"
                        iconHeader="fa-solid fa-user-tag text-green fs-5"
                        iconItem="fa-solid fa-tag fs-5 mt-2 text-purple"
                        textItem="Actor"
                        keyGroups="item-actors-main"
                        keyByGroup="main-actor"
                        insertCode={insertCodeWithLabel}
                    />
                </Accordion.Body>

            </Accordion.Item>
        </Accordion>
    )
}

export default AccordionActorsClusters;