import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ID_NOT_DEFINED } from '../../../constant/constant';
import { MessageError, MessageSuccess } from '../../../utils/message';
import { insertFromLabels, reducerForm, updateFromLabels } from '../../../reducers/configuration/reducerLabelGlobal';
import GeneralService from '../../../services/GeneralService';
import { toast } from '../../../utils/toast';

const ModalLabelGlobal = ({ show, setShow }) => {
    const { selectedLabel } = useSelector(state => state.labelGlobalSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("label-global");

        if (selectedLabel.id === ID_NOT_DEFINED) {
            const res = await service.post(selectedLabel);
            if (res.is_ok) {
                setShow(false);
                toast(dispatch, res.message);
                dispatch(insertFromLabels(res.label));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(selectedLabel);
        if (res.is_ok) {
            setShow(false);
            toast(dispatch, res.message);
            dispatch(updateFromLabels(selectedLabel));
            return;
        }
        MessageError(res.message, service.status);
        return;
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedLabel.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedLabel.id === ID_NOT_DEFINED ?
                                            "Crear Etiqueta"
                                            :
                                            "Editar Etiqueta"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">

                        <div className="row">
                            <div className='col mb-3'>
                                <label>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="name"
                                    value={selectedLabel.name}
                                    maxLength={45}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col mb-3'>
                                <label>Descripción</label>
                                <textarea
                                    className="form-control custom-textarea mt-1"
                                    name="description"
                                    value={selectedLabel.description}
                                    maxLength={100}
                                    placeholder="Ingrese una descripción"
                                    onChange={setData}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedLabel.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalLabelGlobal;