import { createSlice } from "@reduxjs/toolkit";

export const globalCodesSlice = createSlice({
    name: "globalCodes",
    initialState: {
        selectedCode: {
            id: 0,
            name: "",
            value: "",
            type_fields_id: 0,
            type_files_id: 0,
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, action) => {
            state.list = action.payload.value;
            state.listFilter = action.payload.value;
        },
        reducerForm: (state, action) => {
            state.selectedCode[action.payload.key] = action.payload.value;
        },
        reducerFormSearch: (state, action) => {
            if (action.payload.value === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromCodes: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload.value);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload.value);
        },
        updateFromCodes: (state, action) => {
            const code = action.payload.value;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromCodes: (state, action) => {
            state.list.push(action.payload.value);
            state.listFilter.push(action.payload.value);
        },
        setSelected: (state, action) => {
            state.selectedCode = action.payload.value;
        },
        cleanData: (state, action) => {
            state.selectedCode = {
                id: 0,
                name: "",
                value: "",
                type_fields_id: 0,
                type_files_id: 0,
            };
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromCodes,
    updateFromCodes,
    insertFromCodes,
    setSelected,
    cleanData,
} = globalCodesSlice.actions;

export default globalCodesSlice.reducer;