import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../../app/components/IconButton";
import CardSwitch from "../../../app/components/CardSwitch";

import { addRecipient, clearNewEmail, deleteRecipient, reducerFormEmail, setAreClients } from "../../../reducers/mails/reducerMails";
import GeneralService from "../../../services/GeneralService";
import { toast } from "../../../utils/toast";
import { MessageError } from "../../../utils/message";
import { ID_NOT_DEFINED } from "../../../constant/constant";
import { searchTextUsers, setUsers } from "../../../reducers/user/reducerUsers";

const FormEmail = ({ showCreate, setShowCreate, onChange }) => {
    const { selectedEmail } = useSelector(state => state.mailSlice);
    const { auth } = useSelector(state => state.loginSlice);
    const [showFiltered, setShowFiltered] = useState(false);
    const { listFilter, dataFilter } = useSelector(state => state.userSlice);
    const dispatch = useDispatch();
    const componentRef = useRef();
    const inputRef = useRef();

    const setData = (e) => {
        dispatch(reducerFormEmail({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const saveDraft = async () => {
        const service = new GeneralService("email");
        const res = await service.post(selectedEmail);
        if (res.is_ok) {
            toast(dispatch, "El borrador se ha creado");
            onChange();
            return;
        }
        MessageError("No fue posible crear el borrador");
    }

    const sendMail = async (id) => {
        const service = new GeneralService("email/send/" + id);
        const res = await service.post();
        if (res.is_ok) {
            toast(dispatch, res.message);
            setShowCreate(false);
            dispatch(clearNewEmail());
            onChange();
            return;
        }
        MessageError(res.message, service.status);
    }

    const createSend = async () => {
        if (selectedEmail.id !== ID_NOT_DEFINED) {
            await sendMail(selectedEmail.id);
            return;
        } else {
            const service = new GeneralService("email");
            const res = await service.post(selectedEmail);
            if (res.is_ok) {
                await sendMail(res.email.id);
                return
            }
            MessageError(res.message, service.status);
        }
    }

    const loadUsers = async () => {
        if (selectedEmail.are_clients) {
            const service = new GeneralService("people/info-clients");
            const response = await service.getList(1000);
            const { profile = [] } = response;
            dispatch(setUsers(profile));
            return;
        }

        const service = new GeneralService("user");
        const response = await service.getList(0, { page: 0, page_size: 1000 });
        const { profile = [] } = response;
        dispatch(setUsers(profile));
    }

    useEffect(() => {
        document.addEventListener("click", handleShowFiltered);
        return () => {
            document.removeEventListener("click", handleShowFiltered);
        };
    }, []);

    const handleShowFiltered = (e) => {
        if (inputRef.current === document.activeElement) return;
        if (componentRef.current && !componentRef.current.contains(e.target)) {
            setShowFiltered(false);
        }
    };

    useEffect(() => {
        loadUsers();
    }, [selectedEmail.are_clients]);

    return (
        showCreate
            ?
            <div className="create-mail animate__animated animate__fadeInUp">
                <div className="container-fluid">
                    <div className="row p-1 bg-light-purple">
                        <div className="col">
                            <span className="d-block mt-1">
                                {
                                    selectedEmail.id === ID_NOT_DEFINED
                                        ?
                                        "Mensaje nuevo"
                                        :
                                        "Editar mensaje"
                                }
                            </span>
                        </div>
                        <div className="col-2 text-end">
                            <IconButton
                                title="Descartar"
                                icon="fa-solid fa-xmark"
                                onClick={() => setShowCreate(false)}
                            />
                        </div>
                    </div>
                    <div className="row body">
                        <div className="col">
                            <div className="row mt-1 border-bottom mx-1">
                                <div className="col-4 ps-0">
                                    <span className="params">Destinado a:</span>
                                </div>
                                <div className="col">
                                    <div className="select-background">
                                        <div onClick={() => dispatch(setAreClients({ value: true }))} className={`item ${selectedEmail.are_clients ? "bg-green-dark text-white" : ""}`}>
                                            Clientes
                                        </div>
                                        <div onClick={() => dispatch(setAreClients({ value: false }))} className={`item ${selectedEmail.are_clients ? "" : "bg-green-dark text-white"}`}>
                                            Empleados
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1 border-bottom mx-1">
                                <div className="col-2 ps-0">
                                    <span className="params">Para:</span>
                                </div>
                                {
                                    !selectedEmail.is_general
                                    &&
                                    <div className="col-7">
                                        <div className="search-select">
                                            {
                                                selectedEmail.to.map(recipient => <div className="row card-selected-recipient">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <span className="fw-bold fs-7">{recipient.name}</span>
                                                                <span className="d-block fs-8">{recipient.address}</span>
                                                            </div>
                                                            <div className="col-2">
                                                                <IconButton
                                                                    title="Eliminar"
                                                                    icon="fa-solid fa-xmark"
                                                                    className="mt-1"
                                                                    onClick={() => dispatch(deleteRecipient({ value: recipient.address }))}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                            <input
                                                type="text"
                                                name="to"
                                                value={dataFilter.filterName}
                                                className="input-subject"
                                                placeholder="Enviar a"
                                                onChange={(e) => dispatch(searchTextUsers({ value: e.target.value }))}
                                                onFocus={(e) => setShowFiltered(true)}
                                                ref={inputRef}
                                            />
                                            {
                                                showFiltered &&
                                                <div className="filtered-users" ref={componentRef}>
                                                    {
                                                        listFilter.map(user => {
                                                            if (selectedEmail.to.some(recipient => recipient.address === user.email)) return null;
                                                            if (user.email === auth.email) return null;
                                                            return (<div
                                                                className="row card-select-user"
                                                                onClick={() => {
                                                                    dispatch(addRecipient({ value: { user_id: user.id, name: user.name, address: user.email } }))
                                                                    dispatch(searchTextUsers({ value: "" }))
                                                                }}>
                                                                <div className="col-3 avatar">
                                                                    {
                                                                        user.url_avatar
                                                                            ?
                                                                            <img className="circle" src={user.url_avatar} />
                                                                            :
                                                                            <div className="circle">
                                                                                {user.name[0]}
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className="col-9 identifier">
                                                                    <span className="fw-bold fs-7">{user.name}</span>
                                                                    <span className="fs-8">{user.email}</span>
                                                                </div>
                                                            </div>)
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="col">
                                    <CardSwitch
                                        checked={selectedEmail.is_general}
                                        tooltip="¿Es masivo?"
                                        title={selectedEmail.is_general && "¿Es masivo?"}
                                        onChange={() => dispatch(reducerFormEmail({
                                            key: "is_general",
                                            value: !selectedEmail.is_general
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="row mt-1 border-bottom mx-1">
                                <div className="col px-0">
                                    <input
                                        type="text"
                                        name="subject"
                                        placeholder="Asunto:"
                                        value={selectedEmail.subject}
                                        className="input-subject"
                                        onChange={setData}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <textarea
                                        placeholder="Digita el contenido"
                                        name="content"
                                        className="content"
                                        onChange={setData}
                                        value={selectedEmail.content}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row w-100" style={{ position: "fixed", bottom: 5 }}>
                        <div className="col" style={{ display: "flex", justifyContent: "space-between" }}>
                            <button
                                title="Guardar borrador"
                                className="btn btn-success btn-sm rounded-pill px-2"
                                onClick={saveDraft}
                            >
                                <i className="fa-solid fa-floppy-disk"></i>
                            </button>
                            <button
                                title="Enviar email a su destino"
                                className="btn btn-success btn-sm rounded-pill ms-auto px-2 py-1"
                                onClick={createSend}
                            >
                                <i className="fa-solid fa-paper-plane me-1"></i> Enviar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            :
            null
    )
}

export default FormEmail;