import GeneralService from "../../services/GeneralService";
import { loginWithEmailPassword, logoutFirebase } from "../../firebase/firebaseAuth";
import { MessageError, toast } from "../../utils";
import { loginUser, logout } from "./reducerLogin";
import { HTTP_OK } from "../../constant/constant";

export const startLoginWithEmailPassword = ({ email, password, history }) => {
    return async (dispatch) => {
        const service = new GeneralService("user/auth");
        const res = await service.post({ email, password });
        if (service.status === HTTP_OK) {
            await loginWithEmailPassword({ email, password });

            dispatch(loginUser(res));
            history.push('/home');
            toast(dispatch, "Bienvenido :)");
            return;
        }
        MessageError("Usuario o contraseña inválidos");
    }
}

export const startLogout = () => {
    return async (dispatch) => {
        dispatch(logout());
        await logoutFirebase();
        localStorage.removeItem("defaultBusiness");
    }
}