import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";

import CardForm from './CardForm';
import { SearchBar } from '../../../app/components/SearchBar';

import GeneralService from '../../../services/GeneralService';
import { searchForm, setListForm } from '../../../reducers/forms/reducerFormSlice';
import FillForm from "../../../assets/fill-form.svg";
import "../style/modelForm.css";

const CompanyForms = () => {
    const { listFilter } = useSelector(state => state.formSlice);
    const dispatch = useDispatch();
    const { name = "" } = useParams();
    const history = useHistory();

    const loadForms = async (e) => {
        const service = new GeneralService("form/company/" + name);
        const response = await service.getList(1000);
        const { form = [] } = response;
        dispatch(setListForm({ value: form }));
    }

    const onSearch = (e) => {
        dispatch(searchForm(e.target.value));
    }

    useEffect(() => {
        loadForms();
    }, [name]);

    return (
        <div className="pt-4 bg-light-purple pb-5" style={{ display: "flex", minHeight: "100vh" }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-10 mx-auto">

                        <div className="row bg-green rounded-3 p-2">
                            <div className="col-md-12 col-lg-2 abs-center">
                                <img className="ms-2" src={FillForm} alt="Sortom Forms" />
                            </div>
                            <div className="col-md-12 col-lg-10 p-3">
                                <h4 className='font-lilita-one'>Formularios Empresariales <i className="fa-solid fa-arrow-down animate__animated animate__bounce animate__repeat-3 ms-3"></i></h4>
                                <p><b>¡Hola!</b> Bienvenido. Aquí puedes encontrar una amplia variedad de formularios con los cuales puedes acelerar tus contratos y comprobaciones de forma legítima. Tus datos estarán protegidos y no los compartiremos con nadie sin tu consentimiento. Al registrar tus datos, podemos ejecutar los procesos siguientes. Además, podrás recibir información detallada sobre tu proceso y no tendrás de qué preocuparte. <b>¡Que la pases bien!</b> 😊</p>
                            </div>
                        </div>

                        <div className='row pt-3'>
                            <div className="col-md-12 col-lg-4 mx-auto">
                                <SearchBar onChange={onSearch} className='bg-white' />
                            </div>
                        </div>

                        <div className='row row-cols-auto abs-center pt-3'>
                            {
                                listFilter.length == 0
                                    ?
                                    <div className="alert alert-warning text-center mt-4" role="alert">
                                        No hay formularios disponibles según tu búsqueda
                                    </div>
                                    :
                                    listFilter.map(form => <CardForm
                                        key={form.id}
                                        form={form}
                                        onClick={() => history.push(`/forms/${name}/fill-out/${form.id}`)}
                                    />)
                            }

                        </div>
                        <div className='mt-3' />

                    </div>
                </div>
            </div>
        </div>
    );

}
export default CompanyForms;