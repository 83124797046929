import axios from "../../../../../../utils/axios"
const groupCategory = {};
const path = 'cluster-category-people';

groupCategory.getList = async (id) => {
    try {
        const urlList = `${path}?offset=${1000}&category_peoples_id=${id}`;
        const response = await axios.get(urlList);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

groupCategory.save = async (data) => {
    try {
        const response = await axios.post(path, data);
        return response.data
    } catch (e) {
        return e.response.data;
    }
}

groupCategory.update = async (data) => {
    try {
        const urlUpdate = `${path}/${data.id}`;
        const response = await axios.put(urlUpdate, data)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

groupCategory.getById = async (id) => {
    try {
        const urlGet = `${path}/${id}`;
        const response = await axios.get(urlGet)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

groupCategory.delete = async (id) => {
    try {
        const urlDelete = `${path}/${id}`;
        const response = await axios.delete(urlDelete);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export default groupCategory;