import { useDispatch } from "react-redux";
import { searchByNameParameterize } from "../../../../../reducers/forms/reducerParameterizationActorForm";

const SearchParameterizationActorForm = () => {

    const dispatch = useDispatch();

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row m-0 p-0">
                    <div className='col-12'>
                        <input type="text" className="form-control"
                            placeholder="Buscar por nombre"
                            onChange={(v) => dispatch(searchByNameParameterize({
                                value: v.target.value
                            }))}
                        />
                    </div>
                </div>
            </div>
        </>
    );

}

export default SearchParameterizationActorForm;