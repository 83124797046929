import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import CardFavorite from './CardFavorite';
import { MODEL_CASES, USE_FOR_TABLE } from '../../../constant/constant';
import { setStateFavoriteCodesTable } from '../../../reducers/createCase/reducerCreateCase';

const ModalFavoriteCodeTableCase = ({ show, setShow }) => {
  const { fieldsTable, dataLegalCase } = useSelector(state => state.createCaseSlice);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  return (
    <Modal show={show} onHide={handleClose} centered size='lg' dialogClassName='width-80'>
      <Modal.Header className='bg-green pe-4' closeButton>
        <div className='container'>
          <div className='row'>
            <div className='col-1'>
              <i className="fa-solid fa-table mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
            </div>
            <div className='col-11 mt-2'>
              <h5>Selecciona los campos que deseas ver en la tabla</h5>
            </div>
          </div>
        </div>

      </Modal.Header>
      <Modal.Body>
        <div className='container-fluid'>
          {
            fieldsTable.length === 0 ?
              <div className="alert alert-warning text-center mt-2" role="alert">
                No hay campos que mostrar
              </div>
              :
              <div className='row row-cols-4 abs-center'>
                {
                  fieldsTable.map(field => (<CardFavorite
                    key={uuidv4()}
                    field={field}
                    is_category={field.is_category}
                    parent_id={parseInt(dataLegalCase.legal_case_id)}
                    type_model={MODEL_CASES}
                    used_for={USE_FOR_TABLE}
                    onChanged={(value) => dispatch(setStateFavoriteCodesTable(value))}
                  />))
                }
              </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Listo
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFavoriteCodeTableCase;