import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { MessageError, MessageSuccess } from "../../../utils/message";
import GeneralService from "../../../services/GeneralService";

import RecoverPassword from "../../../assets/recoverPassword.jpg";
import { toast } from "../../../utils/toast";
import { ALERT_DANGER } from "../../../constant/constant";
import { BackgroundResetPassword } from "../../../app/components/design/MinimalistBackground";

const ChangePassword = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const { token } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const verifyToken = async () => {
        const service = new GeneralService("user/check-token");
        const res = await service.post({ token });
        if (!res.is_ok) {
            await MessageError(res.message, service.status);
            history.push("/auth/login");
        }
    }

    const onSubmit = async (data) => {
        if (data.password !== data.confirm_password) {
            toast(dispatch, "Las contraseñas no coinciden", ALERT_DANGER);
            return;
        }

        const service = new GeneralService("user/change-password");
        const res = await service.post({
            token,
            password: data.password,
        });
        if (res.is_ok) {
            MessageSuccess(res.message, 3000);
            history.push("/auth/login");
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        verifyToken();
    }, [token]);

    return (
        <div className="container-fluid p-0">
            <div className="stack">
                <BackgroundResetPassword />

                <div className="floating-container">
                    <div className="form-style">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col">
                                    <img className="d-block mx-auto" src={RecoverPassword} alt="Cambiar contraseña" height={250} />
                                    <h4 className="text-center mt-1 fw-bold mt-2">Cambiar contraseña</h4>
                                    <p>Para cambiar tu contraseña, digita tu nueva contraseña, confírmala y ya está, el proceso estaría completado. Asegúrate de que sea una contraseña segura y cumpla con los requerimientos.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col'>
                                    <label>Nueva contraseña<b><i className="text-red">*</i></b></label>
                                    <input
                                        type="password"
                                        className="form-control green-input py-2 px-3 mt-1 rounded-5"
                                        {...register('password', { required: 'Contraseña requerida', minLength: 8 })}
                                        placeholder="Ingresa la nueva contraseña"
                                    />
                                    {errors.password && <div className="fs-7 text-red text-end">{errors.password.message}</div>}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className='col'>
                                    <label>Repetir contraseña<b><i className="text-red">*</i></b></label>
                                    <input
                                        type="password"
                                        className="form-control green-input py-2 px-3 mt-1 rounded-5"
                                        {...register('confirm_password', { required: 'Contraseña requerida', minLength: 8 })}
                                        placeholder="Repite la contraseña"
                                    />
                                    {errors.confirm_password && <div className="fs-7 text-red text-end">{errors.confirm_password.message}</div>}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <button type="submit" className="btn btn-success text-white d-block mx-auto rounded-5 py-2 px-3 my-2">
                                        <i className="fa-solid fa-paper-plane me-2"></i>
                                        Enviar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;