import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import CardProcess from "../../../app/components/CardProcess";
import TabDialogs from "./TabDialogs";
import TabText from "./TabText";

import GeneralService from "../../../services/GeneralService";
import { BAD_REQUEST } from "../../../constant/constant";
import { MessageError } from "../../../utils/message";
import { downloadFile } from "../../../firebase/firebaseStorage";
import { setCodes, setSelectedForm } from "../../../reducers/forms/reducerFormSlice";
import { extractFormCodes } from "../utils/functions";
import { setListQuestions } from "../../../reducers/forms/reducerDialogForm";

const TextFormParameterizationView = () => {
    const { selectedForm } = useSelector(state => state.formSlice);
    const { refreshQuestions } = useSelector(state => state.dialogsFormSlice);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { search } = useLocation();
    const { tabIndex = 1 } = queryString.parse(search);
    const [selected, setSelected] = useState(parseInt(tabIndex));
    const history = useHistory();

    const loadForm = async () => {
        const service = new GeneralService("form/" + id);
        const form = await service.getRequest(id);
        if (form.status === BAD_REQUEST) {
            MessageError(form.message);
            setTimeout(() => history.goBack(), 1200);
            return;
        }

        form.url_template = await downloadFile(form.url_template);
        dispatch(setSelectedForm({ value: form }));

        const codes = extractFormCodes(form);
        dispatch(setCodes({ type: "CODES_FORM", value: codes }));
    }

    const loadQuestions = async () => {
        const service = new GeneralService("questions-form");
        const res = await service.getList(1000, { form_id: selectedForm.id });
        const { questions_form = [] } = res;
        dispatch(setListQuestions({ value: questions_form }));
    }

    useEffect(() => {
        loadQuestions();
    }, [selectedForm.id, refreshQuestions]);

    useEffect(() => {
        loadForm();
    }, [id]);

    return (
            <div className="container-fluid max-height-overflow-y">
                <div className="row m-0 p-0">
                    <div className="col-11 p-0">
                        <BreadcrumbCustom
                            title="Parametrizar formulario"
                            routes={[
                                { name: "Formularios", link: "/form-parameterization/list" },
                            ]}
                            activeRoute={selectedForm.name ? selectedForm.name : "Seleccione un formulario"}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-2" />
                    <CardProcess
                        icon="fa-question"
                        iconColor="text-orange"
                        title="Formulaciones y diálogos"
                        subtitle="Puedes agregar diálogos a tu formulario para detallar información del formulario"
                        isActive={selected === 1}
                        order={1}
                        className="rounded-start"
                        icon_style="bg-green text-white"
                        onClick={() => setSelected(1)}
                    />
                    <CardProcess
                        icon="fa-envelope-open-text"
                        iconColor="text-blue"
                        title="Texto"
                        subtitle="Redacción del contenido general del formulario y sus respectivos códigos"
                        isActive={selected === 2}
                        order={2}
                        className="rounded-end"
                        icon_style="bg-blue text-white"
                        onClick={() => setSelected(2)}
                    />
                    <div className="col-2" />
                </div>

                <div className="row">
                    <div className="col-12">

                        {selected === 1 && <TabDialogs />}
                        {selected === 2 && <TabText />}

                    </div>
                </div>

            </div>
    );
}

export default TextFormParameterizationView;