import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFavoriteCodes, setFavoriteCodesTable, setFieldsCaseTable, setFieldsCase } from '../../../reducers/createCase/reducerCreateCase';
import { filterFieldsCase } from '../../DocumentsAuto/utils/functions';
import { ID_NOT_DEFINED, MODEL_CASES, USE_FOR_SEARCH, USE_FOR_TABLE } from '../../../constant/constant';
import { MessageInfo } from '../../../utils/message';
import GeneralService from '../../../services/GeneralService';

export const useLoadFavoriteCase = () => {
    const { list } = useSelector(state => state.legalCaseSlice);
    const { dataLegalCase } = useSelector(state => state.createCaseSlice);
    const dispatch = useDispatch();

    const loadFavorite = async (id) => {
        if (parseInt(id) !== ID_NOT_DEFINED) {
            const service = new GeneralService("favorite-code");
            const { codes = [] } = await service.getList(1000, { parent_id: id, type_model: MODEL_CASES, used_for: USE_FOR_SEARCH });
            if (codes.length === 0) {
                await MessageInfo("No hay campos de búsqueda", "Al tipo de caso seleccionado no se le han configurado aún campos de búsqueda.");
            }
            dispatch(setFavoriteCodes({ value: codes }));

            const legalCase = list.find(e => e.id === parseInt(id));

            const lista = filterFieldsCase(legalCase, codes);
            dispatch(setFieldsCase({ value: lista }));

            // Cargar los favoritos de la tabla
            const { codes: codesTable = [] } = await service.getList(1000, { parent_id: id, type_model: MODEL_CASES, used_for: USE_FOR_TABLE });
            if (codesTable.length === 0) {
                await MessageInfo("No hay columnas para la tabla", "Al tipo de caso seleccionado no se le han configurado aún las columnas favoritas de la tabla.");
            }
            dispatch(setFavoriteCodesTable({ value: codesTable }));

            const listaTable = filterFieldsCase(legalCase, codesTable);
            dispatch(setFieldsCaseTable({ value: listaTable }));
        } else {
            dispatch(setFavoriteCodes({ value: [] }));
            dispatch(setFieldsCase({ value: [] }));
            dispatch(setFavoriteCodesTable({ value: [] }));
            dispatch(setFieldsCaseTable({ value: [] }));
        }
    }

    useEffect(() => {
        loadFavorite(dataLegalCase.legal_case_id);
    }, [dataLegalCase.legal_case_id]);

    return;
}
