import { createSlice } from "@reduxjs/toolkit";

export const expedientSlice = createSlice({
    name: "expedientSlice",
    initialState: {
        selectedExpedient: {
            id: 0,
            name: "",
            observations: "",
            start_date: '',
            finish_date: '',
            type_process_id: 0,
            expedient_group_id: 0,
            colors_id: 0,
            user_id: 0,
        },
        dataFilter: {
            type_process_id: 0,
            date: "",
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, action) => {
            state.selectedExpedient[action.payload.key] = action.payload.value;
        },
        reducerFormSearch: (state, action) => {
            const { value, showFilter } = action.payload;

            if (value === "") {
                if (showFilter) state.listFilter = state.list;
                else state.listFilter = state.listFilter;
                return;
            }

            if (!showFilter) {
                const filtered = state.list.filter(e => e.name.toLowerCase().includes(value.toLowerCase()))
                state.listFilter = filtered;
                return;
            }

            applyFilters(state, action);
            const filtered = state.listFilter.filter(e => e.name.toLowerCase().includes(value.toLowerCase()))
            state.listFilter = filtered;

        },
        deleteFromExpedient: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload);
        },
        updateFromExpedient: (state, action) => {
            const code = action.payload;

            state.list = state.list.map((e) => e.id === code.id ? code : e);
            state.listFilter = state.listFilter.map((e) => e.id === code.id ? code : e);
        },
        insertFromExpedient: (state, action) => {
            state.list.push(action.payload);
            state.listFilter.push(action.payload);
        },
        setSelected: (state, action) => {
            state.selectedExpedient = action.payload;
        },
        cleanData: (state, action) => {
            state.selectedExpedient = {
                id: 0,
                name: "",
                observations: "",
                start_date: '',
                finish_date: '',
                type_process_id: 0,
                expedient_group_id: 0,
                colors_id: 0,
                user_id: 0,
            };
        },
        reducerFormFilter: (state, action) => {
            state.dataFilter[action.payload.key] = action.payload.value;
        },
        applyFilters: (state, action) => {
            const typeProcessFilter = process => parseInt(state.dataFilter.type_process_id) !== 0 ? process.type_process_id === parseInt(state.dataFilter.type_process_id) : true;

            const dateFilter = expedient => state.dataFilter.date !== "" ? new Date(expedient.start_date) >= new Date(state.dataFilter.date) : true;

            state.listFilter = state.list
                .filter(typeProcessFilter)
                .filter(dateFilter);
        },
        clearFilter: (state, action) => {
            state.dataFilter = { type_process_id: 0, date: "" };
        },
        changeColorFolder: (state, action) => {
            // id: ID expedient
            const { id, colors_id } = action.payload;

            state.list = state.list.map(expedient => {
                if (expedient.id === id) {
                    return {
                        ...expedient,
                        colors_id: colors_id,
                    };
                }
                return expedient;
            });

            state.listFilter = state.listFilter.map(expedient => {
                if (expedient.id === id) {
                    return {
                        ...expedient,
                        colors_id: colors_id,
                    };
                }
                return expedient;
            });

            if (state.selectedExpedient.id === id) {
                state.selectedExpedient.colors_id = colors_id;
            }
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromExpedient,
    updateFromExpedient,
    insertFromExpedient,
    setSelected,
    cleanData,
    reducerFormFilter,
    applyFilters,
    clearFilter,
    changeColorFolder,
} = expedientSlice.actions;

export default expedientSlice.reducer;