import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import SearchParameterizationCategory from './SearchParameterizationCategory';
import BreadcrumbCustom from '../../../../../../app/components/BreadcrumCustom';
import ListParameterizationCategory from './ListParameterizationCategory';

import { cleanData, setListParameterization } from '../../../../../../reducers/legalCase/reducerParameterizationCategory';
import { setSelectedCase } from '../../../../../../reducers/legalCase/reducerLegalCase';
import { setSelectedCluster } from '../../../../../../reducers/legalCase/reducerClusterCategory';
import { setListCategory, setSelectedCategory } from '../../../../../../reducers/legalCase/reducerCategoryLegalCase';
import { ID_NOT_DEFINED } from '../../../../../../constant/constant';
import GeneralService from '../../../../../../services/GeneralService';

const ParameterizationCategoryLegalCase = () => {
    const { list: listCases, selectedCase } = useSelector(state => state.legalCaseSlice);
    const { list: listCategory, selectedCategory } = useSelector(state => state.categoryLegalCaseSlice);
    const { selectedCluster } = useSelector(state => state.clusterCategoryLegalCaseSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const { legalCase = 0, category = 0, cluster = 0 } = queryString.parse(search);

    const loadData = async () => {
        if (legalCase === ID_NOT_DEFINED || cluster === ID_NOT_DEFINED) {
            history.push("/case-parameterization/category");
        }
        dispatch(setSelectedCase({ value: listCases.find(i => i.id === parseInt(legalCase)) }));

        if (listCategory.length === 0) {
            const service = new GeneralService("category-legal-case");
            const { categories = [] } = await service.getList(1000, { legal_case_id: legalCase });
            dispatch(setListCategory({ value: categories }));
            dispatch(setSelectedCategory({ value: categories.find(i => i.id === parseInt(category)) }));
        }

        const service = new GeneralService("cluster-category-legal-case/"+cluster);
        const res = await service.getRequest();
        dispatch(setSelectedCluster({ value: res }));
        const { parameterizations = [] } = res;
        dispatch(setListParameterization({ value: parameterizations }));
    }

    useEffect(() => {
        loadData();
    }, [listCases, legalCase, category, cluster]);

    const handleCreate = () => {
        dispatch(cleanData());
        history.push({
            pathname: "/case-parameterization/category/fields/create",
            search: `?legalCase=${legalCase}&category=${category}&cluster=${cluster}`,
        });
    }

    return (
        <>
            <div className="mx-4">
                <BreadcrumbCustom
                    title="Campos"
                    routes={[
                        { name: "Tipos de caso", link: "/case-parameterization/list" },
                        { name: selectedCase.name ? selectedCase.name : "Seleccione tipo de caso", link: "/case-parameterization/list" },
                        { name: selectedCategory.name ? selectedCategory.name : "Seleccione catergoría", link: "/case-parameterization/category" },
                        { name: selectedCluster.name ? selectedCluster.name : "Seleccione grupo", link: `/case-parameterization/category?legalCase=${selectedCase.id}&category=${selectedCategory.id}` },
                    ]}
                    activeRoute="Listado campos"
                />

                <div className='container m-0 mt-2 p-0'>
                    <div className='row'>
                        <div className='col-6 p-0'>
                            <SearchParameterizationCategory />
                        </div>
                        <div className='col-6 p-0'>
                            <button title="Crear nuevo campo" className="btn btn-success" type="button" onClick={handleCreate}>
                                <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                                Crear campo
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table-data" >
                    <ListParameterizationCategory />
                </div>
            </div>
        </>
    );

}

export default ParameterizationCategoryLegalCase;