import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import ListBusiness from "./listBusiness";
import { SearchBusiness } from "./searchBusiness";

import { setListTypeDocument, setListPlans, cleanData } from '../../../reducers/business/reducerBusiness'
import GeneralService from "../../../services/GeneralService";

const Business = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        getTypeDocument();
        getPlans();
    }, []);

    const getTypeDocument = async () => {
        const service = new GeneralService("type_document");
        const data = await service.getList(1000);
        const { type_document = [] } = data;
        dispatch(setListTypeDocument(type_document));
    }

    const getPlans = async () => {
        const service = new GeneralService("plans");
        const data = await service.getList();
        const { plans = [] } = data;
        dispatch(setListPlans({ value: plans }));
    }

    const handleCreate = () => {
        dispatch(cleanData());
        history.push("/business/create")
    }

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Empresas"
                        activeRoute="Listar empresas"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6 p-0 mt-1">
                    <SearchBusiness />
                </div>
                <div className="col-6">
                    <button title="Crear nueva empresa" className="btn btn-success mt-1" type="button" onClick={handleCreate}>
                        <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                        Crear empresa
                    </button>
                </div>
            </div>
            <div className="table-data" >
                <ListBusiness />
            </div>
        </div >
    )
};

export default Business;