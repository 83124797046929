import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import { generateDistinctColors } from '../../../utils';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
    const { listForms } = useSelector(state => state.viewsSlice);
    const { list } = useSelector(state => state.formSlice);
    const [labels, setLabels] = useState([]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: "Formularios más visitados",
            },
        },
    };

    useEffect(() => {
        setLabels(listForms?.map(item => list.find(form => form.id == item.id)?.name));
    }, [listForms, list]);

    const backgroundColor = generateDistinctColors(labels?.length).map(color => {
        const [hue, saturation, lightness] = color.match(/\d+/g);
        return `hsla(${hue}, ${saturation}%, ${lightness}%, 0.2)`;
    });

    const borderColor = generateDistinctColors(labels?.length).map(color => {
        const [hue, saturation, lightness] = color.match(/\d+/g);
        return `hsla(${hue}, ${saturation}%, ${lightness}%, 1)`;
    });

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Formulario: ',
                data: listForms?.map(item => item.count),
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    };

    return (
        labels &&
        <div style={{ width: "350px", height: "350px" }}>
            <Pie options={options} data={data} />
        </div>
    );
};

export default PieChart;
