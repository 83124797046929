import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { Color } from '../../../../app/components/Color';

import GeneralService from '../../../../services/GeneralService';
import { insertFromMovement, reducerForm, updateFromMovement } from '../../../../reducers/expedient/reducerTypeProcessMovement';
import { toast, MessageError } from '../../../../utils';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import { loadOptionColor, loadOptionDocument } from '../utils/utils';
import '../../styles/select.css';

export const ModalMovement = ({ show, setShow, typeProcess }) => {
    const { selectedMovement } = useSelector(state => state.typeProcessMovementSlice);
    const { filtered } = useSelector(state => state.documentsAutoSlice);
    const { list } = useSelector(state => state.colorsSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("type-process-movement");

        const data = {
            ...selectedMovement,
            type_process_id: parseInt(typeProcess),
            wait_time_days: parseInt(selectedMovement.wait_time_days),
        }

        if (selectedMovement.id === ID_NOT_DEFINED) {
            const res = await service.post(data);
            if (res.is_ok) {
                setShow(false);
                toast(dispatch, res.message);
                dispatch(insertFromMovement(res.movement));
                return;
            }
            MessageError(res.message, service.status);
            return;
        }

        const res = await service.update(data);
        if (res.is_ok) {
            setShow(false);
            toast(dispatch, res.message);
            dispatch(updateFromMovement(data));
            return;
        }
        MessageError(res.message, service.status);
        return;
    }

    const handleSelectedColor = (selected, actionMeta) => {
        dispatch(reducerForm({
            key: "colors_id",
            value: selected.value,
        }));
    }

    const handleSelectedDocument = (selected, actionMeta) => {
        dispatch(reducerForm({
            key: "documents_auto_id",
            value: selected.value,
        }));
    }    

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedMovement.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedMovement.id === ID_NOT_DEFINED ?
                                            "Crear Movimiento"
                                            :
                                            "Editar Movimiento"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">

                        <div className="row mb-3">
                            <div className='col'>
                                <label className="font-rubik">Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="name"
                                    value={selectedMovement.name}
                                    maxLength={120}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="font-rubik">Color<b><i className="text-red">*</i></b></label>
                                <Select
                                    options={list.map(itemColor => {
                                        return {
                                            value: itemColor.id,
                                            label: <div className="select-label">
                                                <span>{itemColor.status}</span>
                                                <Color color={itemColor.color}/>
                                            </div>
                                        }
                                    })}
                                    value={loadOptionColor(list, selectedMovement)}
                                    onChange={handleSelectedColor}
                                    isSearchable={false}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label className="font-rubik">Documento o plantilla</label>
                                <Select
                                    options={[
                                        {
                                            value: undefined,
                                            label: "Ninguno",
                                        },
                                        ...filtered.map(itemDocument => ({
                                            value: itemDocument.id,
                                            label: itemDocument.name,
                                        }))
                                    ]}
                                    value={loadOptionDocument(filtered, selectedMovement)}
                                    onChange={handleSelectedDocument}
                                    isSearchable={true}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col'>
                                <label className="font-rubik">Descripción</label>
                                <textarea
                                    className="form-control custom-textarea mt-1"
                                    name="description"
                                    value={selectedMovement.description}
                                    maxLength={300}
                                    placeholder="Ingrese una descripción"
                                    onChange={setData}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col'>
                                <label className="font-rubik">Días de espera<b><i className="text-red">*</i></b></label>
                                <input
                                    type="number"
                                    className="form-control mt-1"
                                    name="wait_time_days"
                                    value={selectedMovement.wait_time_days}
                                    min={0}
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2"></i>
                        {
                            selectedMovement.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}