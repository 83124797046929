import { createSlice } from '@reduxjs/toolkit';

export const legalCaseSlice = createSlice({
  name: 'legalCaseSlice',
  initialState: {
    data: {
      id: 0,
      name: "",
      description: "",
    },
    list: [],
    listFilter: [],
    isLoading: false,
    selectedCase: {
      id: 0,
      clusters: [],
      categories:[],
    },
  },
  reducers: {
    reducerLegalCase: (state, action) => {
      state.selectedCase[action.payload.key] = action.payload.value
    },
    setListLegalCase: (state, action) => {
      state.list = action.payload.value
      state.listFilter = action.payload.value
      state.isLoading = false
    },
    deleteCase: (state, action) => {
      const newList = state.list.filter(e => e.id !== action.payload.value)
      state.list = newList;
      state.listFilter = newList;
    },
    setSelectedCase: (state, action) => {
      state.selectedCase = action.payload.value ?? { id: 0, name: "", description: "", clusters: [], categories: [] };
    },
    setEditSelectedCase: (state, action) => {
      state.selectedCase[action.payload.key] = action.payload.value;
    },
    //Agregar el objeto creado a la lista
    addNewObjectToList: (state, action) => {
      state.list.push(action.payload.value);
      state.listFilter.push(action.payload.value);
    },
    updateNewObjectAfterEdit: (state, action) => {
      const caso = action.payload.value;
      const modifiedList = state.list.map((e) => {
        if (e.id === caso.id) {
          return caso
        }
        return e;
      });
      const modifiedListFilter = state.listFilter.map((e) => {
        if (e.id === caso.id) {
          return caso
        }
        return e;
      });
      state.list = modifiedList;
      state.listFilter = modifiedListFilter;
    },
    searchCase: (state, action) => {
      if (action.payload.value === "") {
        state.listFilter = state.list;
        return;
      }
      const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.value.toLowerCase()))
      state.listFilter = filtered;
    },
    cleanData: (state, action) => {
      state.selectedCase = {
        id: 0,
        name: "",
        description: "",
      };
    },
  }
})

export const {
  reducerLegalCase,
  setListLegalCase,
  deleteCase,
  addNewObjectToList,
  updateNewObjectAfterEdit,
  searchCase,
  setSelectedCase,
  setEditSelectedCase,
  cleanData
} = legalCaseSlice.actions

export default legalCaseSlice.reducer