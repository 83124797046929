import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FieldType from '../../../app/components/fields/FieldType';
import StepNavigationGroups from '../../../app/components/StepNavigationGroups';

import { setDataForm } from '../../../reducers/forms/reducerCreateProcessForm';
import { ALERT_WARNING, FIELD_TABLE } from '../../../constant/constant';
import { toast } from '../../../utils/toast';

const ClusterForm = ({ refForm }) => {
    const { selectedForm } = useSelector((state) => state.formSlice);
    const { dataForm } = useSelector((state) => state.createProcessFormSlice);
    const [currentStep, setCurrentStep] = useState(0);
    const dispatch = useDispatch();

    if (selectedForm.clusters?.length === 0) {
        return (
            <div className="alert alert-warning" role="alert">
                ¡No hay grupos para mostrar, selecciona un tipo de caso para generar el formulario!
            </div>
        );
    }

    return (
        <div className="container-fluid p-0 rounded-4">

            <div className="row p-0 m-0">
                <div className="col-12 pt-4">
                    <StepNavigationGroups
                        items={selectedForm.clusters}
                        currentStep={currentStep}
                        updateStep={setCurrentStep}
                    />
                </div>
            </div>

            <div className="row p-0 m-0">

                <div className="col-12 p-0">
                    {selectedForm.clusters?.map((cluster, index) => (
                        currentStep === index
                        &&
                        <div key={cluster.id}>

                            <h3 className="text-center">{cluster.name}</h3>
                            <small className="d-block text-center">
                                Los campos con asterísco son obligatorios
                                <i className="text-red fw-bold">*</i>
                            </small>

                            <div className="row m-0 p-0 mt-4">
                                {cluster.parameterizations?.length === undefined ? (
                                    <div className="alert alert-warning" role="alert">
                                        ¡No hay parametrizaciones para este grupo, selecciona otro!
                                    </div>
                                ) : (
                                    cluster.parameterizations?.map((param, index) =>
                                        <FieldType
                                            key={index}
                                            param={param}
                                            location={cluster.name}
                                            className="col-12 col-sm-6 col-lg-4 mt-3"
                                            value={param.type_fields_id !== FIELD_TABLE ? (dataForm[param.name]?.value ?? "") : (dataForm[param.name]?.table ?? [{}])}
                                            onChange={(value) => {
                                                dispatch(setDataForm({
                                                    key: param.name,
                                                    value,
                                                }));
                                            }}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="row mt-5 mb-3">
                <div className="col buttons-stepper">
                    <button
                        type="button"
                        className={`btn btn-success rounded-3 mx-2 px-3 py-2 ${currentStep === 0 && 'disabled'}`}
                        disabled={currentStep === 0}
                        onClick={() => setCurrentStep(currentStep - 1)}
                    >
                        <i className="fa-solid fa-caret-left"></i> Atrás
                    </button>

                    <button
                        type="button"
                        className={`btn btn-success rounded-3 mx-2 px-3 py-2 ${(currentStep + 1) === selectedForm.clusters?.length ? "d-none" : "d-block"}`}
                        onClick={() => {
                            if (refForm.current.checkValidity()) {
                                setCurrentStep(currentStep + 1)
                            } else {
                                toast(dispatch, "Tienes algunos errores en el formulario", ALERT_WARNING)
                                refForm.current.reportValidity();
                            }
                        }}
                    >
                        Siguiente <i className="fa-solid fa-caret-right"></i>
                    </button>

                    <button
                        type="submit"
                        className={`btn btn-success rounded-3 mx-2 px-3 py-2 ${(currentStep + 1) === selectedForm.clusters?.length ? "d-block" : "d-none"}`}
                    >
                        Siguiente paso <i className="fa-solid fa-caret-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ClusterForm;
