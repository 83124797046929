import { useDispatch, useSelector } from "react-redux"

import { SearchBar } from "../../../app/components/SearchBar"
import AddNewChat from "./AddNewChat"
import CardRecentChat from "./CardRecentChat";

import { searchChat, setSelectedChat } from "../../../reducers/chat/reducerChatSlice";

export const ListRecentChats = () => {
    const { list: users } = useSelector(state => state.userSlice);
    const { filteredChats, dataFilter, selectedChat } = useSelector(state => state.businessChatSlice);
    const dispatch = useDispatch();

    return (
        <div className="col-12 col-lg-4 sidebar__chats">
            <div className="row">
                <div className="col-8">
                    <h5 className="fw-bold font-lato">Chats</h5>
                </div>
                <div className="col-4 text-end">
                    <AddNewChat />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <SearchBar
                        value={dataFilter.name}
                        onChange={(e) => dispatch(searchChat({ name: e.target.value, users }))}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col ps-0 pe-0">
                    <div className="sidebar-recent__chats">
                        {
                            filteredChats.length === 0
                                ?
                                <div className="alert bg-green text-center mt-2" role="alert">
                                    No tienes chats
                                </div>
                                :
                                filteredChats.map((chat, i) =>
                                    <CardRecentChat
                                        key={i}
                                        onClick={() => dispatch(setSelectedChat(chat))}
                                        isActive={selectedChat.chatId === chat.chatId}
                                        {...chat}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
