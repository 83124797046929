import { createSlice } from '@reduxjs/toolkit';

export const companyMetricsSlice = createSlice({
    name: 'companyMetrics',
    initialState: {
        metrics: [],
    },
    reducers: {
        setCompanyMetrics: (state, action) => {
            state.metrics = action.payload.value;
        },
    }
});

export const {
    setCompanyMetrics,
} = companyMetricsSlice.actions;

export default companyMetricsSlice.reducer;