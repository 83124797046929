import { useState } from 'react';

import BreadcrumbCustom from '../../../app/components/BreadcrumCustom';
import ListTypepeople from './listTypepeople';
import SearchTypepeople from './searchTypepeople';
import ModalTypePeople from './ModalTypePeople';
import { useDispatch } from 'react-redux';
import { cleanData } from '../../../reducers/typePeople/reducerTypepeople';

const Typepeople = () => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    return (
        <>
            <div className="mx-4">
                <BreadcrumbCustom
                    title="Tipos de persona"
                    activeRoute="/ Listar tipos de persona"
                />

                <div className="container m-0 p-0">
                    <div className="row">
                        <div className="col-6">
                            <SearchTypepeople />
                        </div>
                        <div className="col-6">
                            <button title="Crear nuevo tipo de persona" className="btn btn-success mt-1 mx-2" type="button" onClick={() => { setShow(true); dispatch(cleanData()); }}>
                                <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                                Crear persona
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table-data" >
                    <ListTypepeople setShow={setShow} />
                </div>

            </div>
            <ModalTypePeople show={show} setShow={setShow} />
        </>
    )
}
export default Typepeople;
