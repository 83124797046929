import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import BackgroundCode from '../../../../app/components/BackgroundCode';

import GeneralService from '../../../../services/GeneralService';
import { setList } from '../../../../reducers/configuration/reducerGlobalCodes';
import './style/sidebar.css';
import { extractGlobalCodes } from '../../utils/functions';
import { setCodes } from '../../../../reducers/documents/reducerGenerateDocument';

const SidebarCodeInformation = () => {
    const dispatch = useDispatch();

    const loadCodes = async () => {
        const service = new GeneralService("global-codes");
        const res = await service.getList();
        const { global_codes = [] } = res;
        dispatch(setList({ value: global_codes }));
        dispatch(setCodes({ type: "CODES_GLOBAL", value: extractGlobalCodes(global_codes) }));
    }

    useEffect(() => {
        loadCodes();
    }, []);

    return (
        <>
            <div className="offcanvas offcanvas-end overflow-scroll" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="sidebarCodeInformation" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header bg-green">

                    <div className="w-90">
                        <div className="row">
                            <div className="col-1">
                                <i className="fa-solid fa-circle-question fs-4 ms-2"></i>
                            </div>
                            <div className="col">
                                <h5 className="ms-4">Información Códigos</h5>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn-close m-0" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <div className="offcanvas-body">

                    <div className="container-fluid w-100">
                        <p>Para la automatización de los datos en el documento se dispone de la siguiente sintaxis de códigos. Cada forma de código se debe escribir correctamente para impedir que haya errores al momento de hacer la exportación.</p>

                        <ul className="unordered__list-style">
                            <li>
                                <BackgroundCode>@GLOBAL0000</BackgroundCode>: Los códigos globales hacen referencia a datos estáticos que cada empresa puede tener de forma general.
                            </li>
                            <li>
                                <BackgroundCode>@QUESTION0000</BackgroundCode>: Esta es la forma de incluir un código de pregunta en el documento.
                            </li>
                            <li>
                                <BackgroundCode>
                                    @CODE0000<span className="text-purple">#LABEL</span>
                                </BackgroundCode>: Este tipo de código hace referencia a los datos que serán insertados por cada uno de los actores y sus respectivas etiquetas, es decir el segmento <b><span className="text-purple">#LABEL</span></b> identifica a qué actor va relacionado, como se puede ver, un código debe estar seguido por una etiqueta. La palabra <b>@CODE0000</b> es posible encontrarla de dos formas <b>@CAT0000</b> y <b>@CAC0000</b>.
                            </li>
                            <li>
                                <BackgroundCode>
                                    <span className="text-purple">{"{{"}</span>@CODE0000<span className="text-purple">{"}}"}</span>
                                </BackgroundCode>: Este código tiene la función de vincular el dato especificado a todos los actores que hacen parte del mismo tipo de persona.
                            </li>
                            <li>
                                <BackgroundCode>
                                    <span className="text-purple">{"&NC"}</span>@CODE0000<span className="text-purple">#LABEL</span>
                                </BackgroundCode>: Esta sintaxis permite que en el momento de la exportación del documento pueda ser reemplazado el <b>nombre del campo</b> junto con el <b>dato</b> asociado al actor identificado con su etiqueta <b><span className="text-purple">#LABEL</span></b>.
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </>
    );
}

export default SidebarCodeInformation;