import { useDispatch, useSelector } from "react-redux";

import IconButton from "../../../app/components/IconButton";

import { isURL } from "../../../utils/isUrl";
import { setSelectedChat } from "../../../reducers/chat/reducerChatSlice";
import { useDownloadImageAsBase64 } from "../../../app/hooks/useDownloadImageAsBase64";

const ActiveChat = ({ showBackButton }) => {
    const { nick_name } = useSelector(state => state.loginSlice.profile);
    const { selectedChat } = useSelector(state => state.businessChatSlice);
    const { list } = useSelector(state => state.userSlice);
    const dispatch = useDispatch();

    const usernameSender = Object.keys(selectedChat?.participants)?.filter(key => key !== nick_name)?.pop();
    const user = list.find(itemUser => itemUser.nick_name === usernameSender);
    const { file } = useDownloadImageAsBase64(user?.url_avatar);

    return (
        <div className="active__chat">
            {
                showBackButton
                &&
                <IconButton
                    title="Ir atrás"
                    icon="fa-solid fa-chevron-left text-green"
                    onClick={() => dispatch(setSelectedChat(undefined))}
                />
            }
            {
                isURL(user?.url_avatar)
                    ?
                    <img className="avatar" src={file} alt={user?.name} />
                    :
                    <i className="fa-solid fa-user avatar"></i>
            }
            <span className="font-lato fw-bold">{user?.name}</span>
        </div>
    )
}

export default ActiveChat;