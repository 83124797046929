import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import GeneralService from '../../../../services/GeneralService';
import { MessageError, MessageSuccess } from '../../../../utils';
import { cleanSelectedFile, reducerForm } from '../../../../reducers/expedient/reducerStorageExpedient';

export const ModalStorageEdit = ({ show, setShow, onSubmited }) => {
    const { selectedFile } = useSelector(state => state.storageExpedientSlice);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(cleanSelectedFile());
        setShow(false);
    }

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("storage-expedient");

        const data = {
            ...selectedFile,
            name: `${selectedFile.name}.${selectedFile.type}`,
        };

        const res = await service.update(data);
        if (res.is_ok) {
            MessageSuccess(res.message);
            onSubmited();
            handleClose();
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <i className="fa-solid fa-pen-to-square animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                    <h5 className="mt-2 ms-3">
                        Editar Nombre
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">
                        <div className="row m-0 p-0">

                            <div className='col mb-3'>
                                <label>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control mt-1 green-input"
                                    name="name"
                                    value={selectedFile.name}
                                    maxLength={50}
                                    minLength={3}
                                    placeholder="Ingrese el nuevo nombre"
                                    autoComplete="off"
                                    onChange={setData}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        Actualizar
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}