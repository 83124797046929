import { useEffect, useRef, useState } from "react";


export const useContextMenu = (onClick = () => { }) => {
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const cardRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const handleContextMenu = (e) => {
        e.preventDefault();
        setContextMenuPosition({ x: e.clientX, y: e.clientY });
        setContextMenuVisible(true);
    };

    const handleHideContextMenu = () => {
        setContextMenuVisible(false);
    };

    const handleDocumentClick = (e) => {
        if (cardRef.current && !cardRef.current.contains(e.target)) {
            setContextMenuVisible(false);
        }
    };

    const handleClick = (e) => {
        onClick(e);
        if (contextMenuVisible) {
            setContextMenuVisible(c => !c);
        }
    }

    return {
        cardRef,
        contextMenuVisible,
        handleContextMenu,
        handleHideContextMenu,
        handleClick,
        contextMenuPosition,
    }
}
