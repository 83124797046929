import { createSlice } from "@reduxjs/toolkit";

export const typeProcessMovementSlice = createSlice({
    name: "typeProcessMovementSlice",
    initialState: {
        selectedMovement: {
            id: 0,
            name: "",
            description: "",
            wait_time_days: 1,
            documents_auto_id: 0,
            type_process_id: 0,
            colors_id: "",
        },
        list: [],
        listFilter: [],
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        reducerForm: (state, action) => {
            state.selectedMovement[action.payload.key] = action.payload.value;
        },
        reducerFormSearch: (state, action) => {
            if (action.payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(e => e.name.toLowerCase().includes(action.payload.toLowerCase()))
            state.listFilter = filtered;
        },
        deleteFromMovement: (state, action) => {
            state.list = state.list.filter(e => e.id !== action.payload);
            state.listFilter = state.listFilter.filter(e => e.id !== action.payload);
        },
        updateFromMovement: (state, action) => {
            const movement = action.payload;

            state.list = state.list.map((e) => e.id === movement.id ? movement : e);
            state.listFilter = state.listFilter.map((e) => e.id === movement.id ? movement : e);
        },
        insertFromMovement: (state, action) => {
            state.list.push(action.payload);
            state.listFilter.push(action.payload);
        },
        orderMovement: (state, action) => {
            const { id, order } = action.payload.value;
            const currentIndex = state.listFilter.findIndex(e => e.id === id);
            const element = state.listFilter[currentIndex];

            state.listFilter.splice(currentIndex, 1);
            state.listFilter.splice(order, 0, element);
        },
        setSelected: (state, action) => {
            state.selectedMovement = action.payload;
        },
        cleanData: (state, action) => {
            state.selectedMovement = {
                id: 0,
                name: "",
                description: "",
                wait_time_days: 1,
                documents_auto_id: 0,
                type_process_id: 0,
                colors_id: "",
            };
        },
    },
});

export const {
    setList,
    reducerForm,
    reducerFormSearch,
    deleteFromMovement,
    updateFromMovement,
    insertFromMovement,
    orderMovement,
    setSelected,
    cleanData,
} = typeProcessMovementSlice.actions;

export default typeProcessMovementSlice.reducer;