import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { reducerFormFilter, applyFilters } from "../../../../reducers/expedient/reducerExpedient";

import '../styles/inputStyle.css';

export const FilterExpedients = ({ showFilter }) => {
    const { dataFilter } = useSelector(state => state.expedientSlice);
    const { list } = useSelector(state => state.typeProcessSlice);
    const dispatch = useDispatch();
    const history = useHistory()

    const setData = (e) => {
        dispatch(reducerFormFilter({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const onSelect = (e) => {
        history.push("?typeProcess=" + e.target.value);
        setData(e);
    }

    useEffect(() => {
        if (showFilter) dispatch(applyFilters());
    }, [dataFilter]);

    if (!showFilter) return undefined;
    return (
        <>
            <div className="col">
                <select
                    className="form-select green-input"
                    name="type_process_id"
                    value={dataFilter.type_process_id}
                    onChange={onSelect}
                >
                    <option value="0">
                        Proceso (Todos)
                    </option>
                    {
                        list.map(process => <option value={process.id} key={process.id}>
                            {process.name}
                        </option>)
                    }
                </select>
            </div>
            <div className="col">
                <input
                    type="date"
                    className="form-control green-input"
                    name="date"
                    value={dataFilter.date}
                    maxLength="50"
                    onChange={setData}
                />
            </div>
        </>
    )
}
