import { useDispatch, useSelector } from "react-redux";

import { cleanAnswer, setListQuestions, setSelectedAnswer, setSelectedQuestion } from "../../../../reducers/forms/reducerDialogForm";
import { HIDE, QUESTION_CLOSED, SHOW, TYPE_QUESTION } from "../../../../constant/constant";
import confirmDelete from "../../../../utils/confirmDelete";
import { MessageError } from "../../../../utils/message";
import { toast } from "../../../../utils/toast";
import GeneralService from "../../../../services/GeneralService";
import ActionButton from "../../../../app/components/ActionButton";
import { useAnimateBackground } from "../../../../app/hooks/useAnimateBackground";
import { showAnimateOrderItem } from "../../../../utils/showAnimateOrderItem";

const ListQuestions = ({ setShowFormQuestions, setShowFormAnswers, showActions = true, onTapCode = (c, t) => { }, aditionalIDDOM = "create" }) => {
    const { listQuestions } = useSelector(state => state.dialogsFormSlice);
    const { selectedForm } = useSelector(state => state.formSlice);
    const dispatch = useDispatch();
    const [showEffect, setShowEffect] = useAnimateBackground();

    const loadQuestions = async () => {
        const service = new GeneralService("questions-form");
        const res = await service.getList(1000, { form_id: selectedForm.id });
        const { questions_form = [] } = res;
        dispatch(setListQuestions({ value: questions_form }));
    }

    const deleteQuestion = async (id) => {
        const service = new GeneralService("questions-form");
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            loadQuestions();
            return;
        }
        MessageError(res.message, service.status);
    }

    const deleteAnswer = async (id) => {
        const service = new GeneralService("answer-form");
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            loadQuestions();
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleMove = async (id, order) => {
        const service = new GeneralService("questions-form/order");
        const res = await service.post({ id, order });
        if (res.is_ok) {
            await loadQuestions();
            toast(dispatch, res.message);
            setShowEffect({ show: true, id });
            return;
        }
        MessageError(res.message, service.status);
    }

    const iterateQuestion = (question, i) => {
        if (question.type === QUESTION_CLOSED) {
            return (
                <li key={question.id}>
                    <input type="checkbox" id={`question-${question.id}${aditionalIDDOM}`} />
                    <div className="col tree_label">
                        <div className="row">
                            <div className="col-8">
                                <label htmlFor={`question-${question.id}${aditionalIDDOM}`} role="button">
                                    <i className="fa-solid fa-circle-question me-2 text-orange"></i>
                                    <span className={`change-background-animation ${showAnimateOrderItem(question, showEffect)}`}>{question.name}</span>
                                    {
                                        question.code_questions &&
                                        <span
                                            className="fs-7 fw-bold text-purple bg-light-purple p-1 ms-1 rounded-3"
                                            onClick={() => onTapCode(question, TYPE_QUESTION)}
                                            role="button"
                                        >
                                            @{question.code_questions.code}
                                        </span>
                                    }
                                </label>
                            </div>
                            {
                                showActions && <div className="col-4">
                                    <ActionButton
                                        title="Eliminar pregunta"
                                        icon="fa-trash"
                                        onClick={async () => await confirmDelete(() => deleteQuestion(question.id))}
                                    />
                                    <ActionButton
                                        title="Editar pregunta"
                                        icon="fa-edit"
                                        onClick={() => {
                                            setShowFormQuestions(SHOW);
                                            setShowFormAnswers(HIDE);
                                            dispatch(setSelectedQuestion({ value: question }));
                                        }}
                                    />
                                    <ActionButton
                                        title="Añadir una respuesta"
                                        icon="fa-network-wired"
                                        onClick={() => {
                                            setShowFormQuestions(HIDE);
                                            setShowFormAnswers(SHOW);
                                            dispatch(setSelectedQuestion({ value: question }));
                                            dispatch(cleanAnswer());
                                        }}
                                    />
                                    {
                                        question.first_question
                                        &&
                                        <div className="move-parameterization">
                                            <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(question.id, (i - 1))}></i>
                                            <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(question.id, (i + 1))}></i>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <ul>
                        {question.answers?.map(answer => {
                            if (!answer.finish) {
                                return (<li key={`answer-${answer.id}`}>
                                    <input type="checkbox" id={`answer-${answer.id}${aditionalIDDOM}`} />
                                    <div className="col tree_label">
                                        <div className="row">
                                            <div className="col-8">
                                                <label htmlFor={`answer-${answer.id}${aditionalIDDOM}`} className="fw-bold fst-italic" role="button">
                                                    <i className="fa-solid fa-thumbtack me-2 text-green"></i>{answer.name}
                                                </label>
                                            </div>
                                            {
                                                showActions && <div className="col-4">
                                                    <ActionButton
                                                        title="Eliminar respuesta"
                                                        icon="fa-trash"
                                                        onClick={async () => await confirmDelete(() => deleteAnswer(answer.id))}
                                                    />
                                                    <ActionButton
                                                        title="Editar respuesta"
                                                        icon="fa-edit"
                                                        onClick={() => {
                                                            setShowFormQuestions(HIDE);
                                                            setShowFormAnswers(SHOW);
                                                            dispatch(setSelectedQuestion({ value: question }));
                                                            dispatch(setSelectedAnswer({ value: answer }));
                                                        }}
                                                    />
                                                    {!answer.sequence_questions && <ActionButton
                                                        title="Añadir nueva pregunta a la secuencia"
                                                        icon="fa-network-wired"
                                                        onClick={() => {
                                                            setShowFormQuestions(SHOW);
                                                            setShowFormAnswers(HIDE);
                                                            dispatch(setSelectedQuestion({
                                                                value: {
                                                                    id: 0,
                                                                    name: "",
                                                                    answer: "",
                                                                    form_id: 0,
                                                                    type: "QUESTION_CLOSED",
                                                                    first_question: false,
                                                                    finish: false,
                                                                }
                                                            }));
                                                            dispatch(setSelectedAnswer({ value: answer }));
                                                        }}
                                                    />}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        <ul>
                                            {answer.sequence_questions && iterateQuestion(answer.sequence_questions?.questions_form)}
                                        </ul>
                                    }
                                </li>)
                            }
                            return (
                                <li key={`answer-${answer.id}`}>
                                    <span className="col tree_label">
                                        <div className="row">
                                            <div className="col-8">
                                                <i className="fa-solid fa-thumbtack me-2 text-green"></i>
                                                <span className="px-2 py-1 rounded-4 bg-purple text-white fw-bold fst-italic">{answer.name}</span>
                                            </div>
                                            {
                                                showActions && <div className="col-4">
                                                    <ActionButton
                                                        title="Eliminar respuesta"
                                                        icon="fa-trash"
                                                        onClick={async () => await confirmDelete(() => deleteAnswer(answer.id))}
                                                    />
                                                    <ActionButton
                                                        title="Editar respuesta"
                                                        icon="fa-edit"
                                                        onClick={() => {
                                                            setShowFormQuestions(HIDE);
                                                            setShowFormAnswers(SHOW);
                                                            dispatch(setSelectedAnswer({ value: answer }));
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </li>
            )
        }
        return (
            <li key={question.id}>
                <span className="col tree_label">
                    <div className="row">
                        <div className="col-8">
                            <i className="fa-solid fa-circle-question me-2 text-dark"></i>
                            <span className={`px-2 py-1 rounded-4 text-white change-background-animation ${showEffect.show && showEffect.id === question.id ? "show-effect" : "bg-purple"}`}>
                                {question.name}
                            </span>
                            {
                                question.code_questions &&
                                <span
                                    className="fs-7 fw-bold text-purple bg-light-purple p-1 ms-1 rounded-3"
                                    onClick={() => onTapCode(question, TYPE_QUESTION)}
                                    role="button"
                                >
                                    @{question.code_questions.code}
                                </span>
                            }
                        </div>
                        {
                            showActions && <div className="col-4">
                                <ActionButton
                                    title="Eliminar pregunta"
                                    icon="fa-trash"
                                    onClick={async () => await confirmDelete(() => deleteQuestion(question.id))}
                                />
                                <ActionButton
                                    title="Editar pregunta"
                                    icon="fa-edit"
                                    onClick={() => {
                                        setShowFormQuestions(SHOW);
                                        setShowFormAnswers(HIDE);
                                        dispatch(setSelectedQuestion({ value: question }));
                                    }}
                                />
                                {
                                    question.first_question
                                    &&
                                    <div className="move-parameterization">
                                        <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(question.id, (i - 1))}></i>
                                        <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(question.id, (i + 1))}></i>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </span>
            </li>
        )
    }

    return (
        <ul className="tree mt-4">
            {
                listQuestions?.length === 0 ?
                    <div className="alert alert-warning text-center mt-2" role="alert">
                        No hay secuencias por el momento
                    </div>
                    :
                    listQuestions?.map(iterateQuestion)
            }
        </ul>
    )
}

export default ListQuestions;